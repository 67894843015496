import BaseOrderOptionCard, {ActionButton, AddToPouchButton} from "components/cards/order/BaseOrderOptionCard";
import Link from "next/link";
import {getRefundDetailRoute} from "common/const";
import styles from "components/cards/order/baseOrderOptionCard.module.scss";



const RefundDetailButton = ({orderNumber, orderOption}) => {
    const onClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'OrderRefundCardRefundDetailClick',
        );
    };
    return (
        <Link href={getRefundDetailRoute(orderNumber, orderOption.order_refund_id)}>
            <a className={styles.buttonWrapper} onClick={onClick}>
                <ActionButton>반품 상세</ActionButton>
            </a>
        </Link>
    )
}

const CancelRefundButton = ({cancelRefund}) => {
    const onClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'OrderRefundCardCancelRefundClick',
        );
        cancelRefund();
    };
    return (
        <div className={styles.buttonWrapper} onClick={onClick}>
            <ActionButton>반품 철회</ActionButton>
        </div>
    )
}

const PickupCheckButton = ({trackingLink}) => {
    const onClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'OrderRefundCardPickupCheckClick',
        );
    };
    return (
        <Link href={trackingLink}>
            <a className={styles.buttonWrapper} onClick={onClick}>
                <ActionButton>수거 조회</ActionButton>
            </a>
        </Link>
    )
}

const GetButtons = (orderNumber, orderOption) => {
    switch (orderOption.status) {
        case '반품접수':
            return (
                <>
                    <RefundDetailButton orderNumber={orderNumber} orderOption={orderOption} />
                    <AddToPouchButton orderOption={orderOption} />
                </>
            )
        case '반품상품 수거중':
            return (
                <>
                    <RefundDetailButton orderNumber={orderNumber} orderOption={orderOption} />
                    {
                        orderOption.pickup_tracking_link &&
                        <PickupCheckButton trackingLink={orderOption.pickup_tracking_link} />
                    }
                    <AddToPouchButton orderOption={orderOption} />
                </>
            )
        case '반품처리중':
            return (
                <>
                    <RefundDetailButton orderNumber={orderNumber} orderOption={orderOption} />
                    <AddToPouchButton orderOption={orderOption} />
                </>
            )
        case '반품완료':
        case '반품반려':
            return (
                <>
                    <RefundDetailButton orderNumber={orderNumber} orderOption={orderOption} />
                    <AddToPouchButton orderOption={orderOption} />
                </>
            )

    }
}

export default function OrderRefundCard (props) {
    const {orderNumber, orderOption, showCancelOrNoButton, cancelRefund, currency} = props;

    return (
        <BaseOrderOptionCard
            productOverrideLink={orderOption.product_override_link}
            brandName={orderOption.brand_name}
            count={orderOption.count}
            productId={orderOption.product_id}
            productName={orderOption.product_name}
            status={orderOption.status}
            imageUrl={orderOption.product_image_url}
            optionName={orderOption.option_name}
            price={orderOption.unit_sell_price}
            disableLink={props.disableLink}
            currency={currency}
        >
            {
                showCancelOrNoButton
                    ?
                    (
                        orderOption.status === '반품접수' &&
                        <CancelRefundButton cancelRefund={cancelRefund}/>
                    )
                    : GetButtons(orderNumber, orderOption)
            }
        </BaseOrderOptionCard>
    )
}
