import {SERVICE_ENV} from "common/const";


export const trackPixel = (event, data) => {
    if (SERVICE_ENV === 'prod') {
        if (window.pixel) {
            console.log('track pixel', event, data);
            window.pixel.track(event, data);
        } else {
            console.log('push to pending task', event, data);
            const task = [event, data];
            if (window.pendingTasks) {
                window.pendingTasks.push(task);
            } else {
                window.pendingTasks = [task]
            }
            console.log('window.pendingTasks', window.pendingTasks);
        }
    }
}