import classNames from "classnames";
import styles from "components/dropdown/optionSelectionDropDownSingle.module.scss";
import ArrowDownGray from "images/icons/arrow_down_757575.svg";
import ArrowUpIcon from "images/icons/arrow_up.svg";
import {formattedPrice, numberWithComma} from "common/utils";
import {useRef} from "react";
import LinesEllipsis from "react-lines-ellipsis";
import useTimer from "hooks/useTimer";
import {toast} from "react-toastify";
import useTranslation from "next-translate/useTranslation";

export default function OptionSelectionDropDownSingle (props) {
    const {options, small, inactive, disableClick, displayOnSingle, product, hidePrice, allowSoldOut, disabled, hierarchy} = props;
    const onClick = props.onClick || function() {};
    // const [isExpanded, setIsExpanded] = useState(false);
    const {isExpanded, setIsExpanded} = props;
    const wrapperRef = useRef(null);
    const {t} = useTranslation('components-input-OptionSelector');

    const maxTimestamp = Math.max(...options.map(e => e.opens_at_timestamp || 0));
    const {timeString} = useTimer(maxTimestamp);

    const isApplicableNextPromotion = (option) => {
        return !!(option?.discounted_price_start_after && option?.discounted_price_start_after - (Date.now() / 1000) <= 0);
    }

    return (
        <div ref={wrapperRef} style={{position: 'relative'}}>
            <div
                className={
                    classNames(
                        styles.selector, isExpanded && styles.selectActivated,
                        small ? styles.selectorSmall : undefined,
                        (disabled || options.length === 0) && styles.disabled,
                    )
                }
                style={{
                    display: !displayOnSingle && options.length === 1 ? 'none' : null,
                }}
                onClick={
                    (e) => {
                        if (disabled) {
                            return;
                        }
                        if (options.length === 0) {
                            return;
                        }
                        e.stopPropagation();
                        e.preventDefault();
                        if (!inactive) {
                            setIsExpanded(!isExpanded);
                        }
                    }
                }
            >
                {props.title || t('Dropdown.selectProduct')}
                {
                    isExpanded ? <ArrowUpIcon className={styles.arrowUpIcon} viewBox="0 0 16 16" width={16} height={16} /> : <ArrowDownGray viewBox="0 0 20 20"  width={16} height={16} />
                }
            </div>
            {
                isExpanded &&
                <div
                    {...{'body-scroll-lock-ignore': "true"}}
                    className={styles.optionList}
                    onClick={e => {
                        e.stopPropagation();
                    }}>
                    {
                        options.map(e => {
                            const isNotOpened = e.opens_at_timestamp && (e.opens_at_timestamp > Date.now() / 1000);
                            return (
                                <div
                                    key={`option-dropdown-${hierarchy}-${e.id}`}
                                    className={classNames(styles.optionElement, small? styles.optionElementSmall : undefined)}
                                    onClick={() => {
                                        if (isNotOpened && Boolean(timeString)) {
                                            toast.info('아직 오픈 전이에요.');
                                        } else if ((!e.is_sold_out || allowSoldOut) && !disableClick) {
                                            onClick(e);
                                            setIsExpanded(false);
                                        }
                                    }}
                                >
                                    <div className={styles.optionNameWrapper}>
                                        {!hidePrice && product && product.should_show_all_discount_rate && e.discount_rate > 0 &&
                                            <span className={styles.discountRate}>
                                                {e.discount_rate}%
                                            </span>
                                        }
                                        <span className={classNames(styles.optionName, e.is_sold_out && !allowSoldOut ? styles.optionNameSoldOut : undefined)}>
                                            {
                                                e.dropdown_product_option_name ?
                                                    <LinesEllipsis
                                                        text={e.dropdown_product_option_name}
                                                        maxLine={2}
                                                        ellipsis="..."
                                                        trimRight
                                                        basedOn='letters' />
                                                    :
                                                    t('OptionSelector.notSelected')
                                            }
                                        </span>
                                    </div>
                                    {
                                        e.is_sold_out && !allowSoldOut ?
                                            <div className={styles.optionPriceWrapper}>
                                                <span className={styles.soldOutText}>품절</span>
                                            </div>
                                            :
                                            (isNotOpened && timeString) ?
                                                <div className={styles.optionPriceWrapper}>
                                                    <span className={styles.optionOpenTime}>
                                                        {timeString}
                                                    </span>
                                                </div>
                                                :
                                                (
                                                    !hidePrice ?
                                                        !isApplicableNextPromotion(e) ?
                                                            <div className={styles.optionPriceWrapper}>
                                                                {
                                                                    product && product.show_option_original_price && e.price !== e.discounted_price &&
                                                                    <span
                                                                        className={styles.optionPrice}>{formattedPrice(e.price, e.currency)}</span>
                                                                }
                                                                <span
                                                                    className={styles.optionDiscountedPrice}>{formattedPrice(e.discounted_price, e.currency)}{t(`common:currency.${e.currency}`)}</span>
                                                            </div>
                                                            :
                                                            <div className={styles.optionPriceWrapper}>
                                                                {
                                                                    product && product.show_option_original_price && e.price !== e.discounted_price &&
                                                                    <span
                                                                        className={styles.optionPrice}>{formattedPrice(e.next_price, e.currency)}</span>
                                                                }
                                                                <span
                                                                    className={styles.optionDiscountedPrice}>{formattedPrice(e.next_discounted_price, e.currency)}{t(`common:currency.${e.currency}`)}</span>
                                                            </div>
                                                        :
                                                        <></>
                                                )
                                    }
                                </div>
                            );
                        })
                    }

                </div>
            }
        </div>
    );
}
