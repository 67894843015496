import SortableList, {SortableItem} from "react-easy-sort";
import RoundCloseIcon from "images/icons/close_circle.svg";
import CropModal from "components/modal/CropModal";
import {useEffect, useRef, useState} from "react";
import {arrayMoveImmutable, arrayMoveMutable} from "array-move";
import styles from "components/images/imageUploader.module.scss";
import utilStyles from "styles/utils.module.scss";
import {onFilesSelect, readURL} from "common/utils";
import {useRouter} from "next/router";
import CameraIcon from "images/icons/camera.svg";
import Image from "next/image";

class ImageFile {
    constructor(file, data, width, height) {
        this.file = file;
        this.data = data;
        this.width = width;
        this.height = height;
    }
}

export default function ImageUploader(props) {
    const {images, setImages, disabled, uploadUrl, isEditMode, onDelete, size} = props;
    const [cropImages, setCropImages] = useState([]);
    const onSortEnd = async (oldIndex, newIndex) => {
        await setCropImages(arr => arrayMoveImmutable(arr, oldIndex, newIndex));
        setImages(arrayMoveImmutable(images, oldIndex, newIndex));
        arrayMoveMutable(prevImagesRef.current, oldIndex, newIndex);
    }
    const router = useRouter();
    const [isCropModalOpen, setCropModalOpen] = useState(false);
    const successRef = useRef(false);
    const prevImagesRef = useRef([]);
    useEffect(() => {
        if (isCropModalOpen) {
            successRef.current = false;
        } else {
            if (successRef.current) {

            } else {
                setCropImages(prevImagesRef.current);
            }
        }
    }, [isCropModalOpen]);
    return (
        <>
            {
                !isEditMode && cropImages && cropImages.length > 0 &&
                <CropModal
                    successRef={successRef}
                    prevImagesRef={prevImagesRef}
                    setResultImages={(newImages) => {
                        setImages(newImages.slice(0, size));
                    }}
                    isOpen={isCropModalOpen}
                    images={cropImages}
                    setImages={setCropImages}
                    close={() => setCropModalOpen(false)}
                    uploadUrl={uploadUrl}
                />
            }
            <SortableList onSortEnd={onSortEnd} className={styles.imagesContainer}>
                {
                    !isEditMode &&
                    <div className={styles.photoUploadButtonContainer}>
                        <input
                            disabled={disabled}
                            type="file" accept="image/*" multiple className={styles.fileInput}
                            onChange={onFilesSelect(async (files) => {
                                if (cropImages.length + files.length > size) {
                                    alert(`사진은 최대 ${size}장까지 등록 가능합니다.`);
                                    return;
                                }
                                const fileObjects = [];
                                for (const file of files) {
                                    const [url, width, height] = await readURL(file);
                                    fileObjects.push(new ImageFile(file, url, width, height));
                                }
                                setCropImages(val => {
                                    return [...val, ...fileObjects].map(e => {
                                        if (!e.id) {
                                            e.id = Object.id(e);
                                        }
                                        return e;
                                    })
                                });
                                setCropModalOpen(true)
                            })}
                            onClick={e => {
                                e.target.value = null;
                            }}
                        />
                        <CameraIcon/>
                        <span className={styles.photoCounter}><span
                            className={images.length > 0 ? utilStyles.brandColorText : undefined}>{images.length}</span>/{size}</span>
                    </div>
                }

                {images.map((image, idx) => {
                    return (
                        <SortableItem key={image.id}>
                            <div className={styles.photoContainer}>
                                <div className={styles.photoWrapper}>
                                    {
                                        !(props.post && images.length < 2) &&
                                        // 추후 onDelete 사용 시 적용
                                        // onDelete?
                                        //     <RoundCloseIcon
                                        //         onClick={async () => {
                                        //             await onDelete(image.id)
                                        //         }}
                                        //         className={styles.imagePickerCloseButton}/>
                                        //     :
                                            <RoundCloseIcon
                                                onClick={async () => {
                                                    await setCropImages(val => {
                                                        const newVal = [...val];
                                                        newVal.splice(idx, 1);
                                                        return newVal
                                                    })
                                                    prevImagesRef.current.splice(idx, 1);
                                                    const newImages = [...images];
                                                    newImages.splice(idx, 1);
                                                    setImages(newImages);
                                                }}
                                                className={styles.imagePickerCloseButton}/>
                                    }
                                    <Image draggable={false} unoptimized src={image.thumbnail_url} layout="fill"
                                           objectFit="cover" objectPosition="center"/>
                                </div>
                            </div>
                        </SortableItem>
                    )
                })}
            </SortableList>
        </>
    )
}