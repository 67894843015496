import {createContext, useCallback, useContext, useEffect, useState} from "react";
import {throttle} from "common/utils";

const ScrollContext = createContext(undefined);
export default ScrollContext;

export let scrollContextRef = {};

const ScrollContextWrapper = ({children}) => {
    const context = useContext(ScrollContext);

    useEffect(() => {
        scrollContextRef.current = context;
    }, []);

    useEffect(() => {
        scrollContextRef.current = context;
    }, [context]);

    return(
        <>
            {children}
        </>
    )
}

let scrollPos = 0;
let accDown = 0;
let accUp = 0;

export const ScrollContextProvider = ({children}) => {
    const [shouldHideNavBar, setShouldHideNavBar] = useState(false);
    const [callbacks, setCallbacks] = useState([]);

    useEffect(() => {
        scrollPos = window.scrollY;
        const handleScroll = throttle((e) => {
            const oldPos = scrollPos;
            const newPos = window.scrollY;
            scrollPos = newPos;
            if (oldPos > newPos) {
                accDown += oldPos - newPos;
                accUp = 0;
            } else {
                accUp += newPos - oldPos;
                accDown = 0;
            }
            if (scrollContextRef.current) {
                for (let i = 0; i < scrollContextRef.current.callbacks.length; i++) {
                    scrollContextRef.current.callbacks[i](accUp, accDown);
                }
            }
        }, 100);
        window.addEventListener('scroll', handleScroll, true);
        return () => {
            window.removeEventListener('scroll', handleScroll, true);
        }
    }, []);

    // const addCallback = useCallback((callback) => {
    //     setCallbacks(x => [...x, callback]);
    // }, [setCallbacks]);

    const reset = () => {
        accDown = 0;
        accUp = 0;
        scrollPos = 0;
    };

    const addCallback = (callback) => {
        setCallbacks(x => [...x, callback]);
    };

    const contextValue = {
        callbacks, addCallback, shouldHideNavBar, reset
    };

    return (
        <ScrollContext.Provider value={contextValue}>
            <ScrollContextWrapper>
                {children}
            </ScrollContextWrapper>
        </ScrollContext.Provider>
    )
};
