import {Axios} from "api/index";
import {captureException} from "@sentry/nextjs";
import {toastContextRef} from "context/ToastContext";

const USER_INFO_KEY = 'user';

export const fetchUserInfo = async () => {
    try {
        const res = await Axios.get('v1/auth/my-info');
        if (res.status < 400) {
            const user = res.data;
            saveUserInfo(user);
            mixpanel.identify(user.email);
            mixpanel.people.set({
                "name": user.nickname,
                "email": user.email,
                "nickname": user.nickname,
                "auth_type": user.auth_type,
                "is_creator": user.is_creator,
                "is_seller": user.is_seller,
                "is_verified": user.is_verified,
            });
            return user;
        } else if (res.status === 401) {
            invalidateUserInfo();
            return null;
        } else {
            if (toastContextRef.current) {
                toastContextRef.current.closeAndOpenToast('유저 정보를 받아오는 데 실패했어요.');
            }
        }
    } catch (e) {
        captureException(e);
        if (toastContextRef.current) {
            toastContextRef.current.closeAndOpenToast('유저 정보를 받아오는 데 실패했어요.');
        }
    }
    return undefined;
};

export const saveUserInfo = (userInfo) => {
    window.localStorage.setItem(USER_INFO_KEY, JSON.stringify(userInfo));

};

export const getLocalStorageUserInfo = () => {
    const userInfoJson = window.localStorage.getItem(USER_INFO_KEY);
    if (userInfoJson && userInfoJson !== 'undefined') {
        return JSON.parse(userInfoJson);
    }
    return null;
};

export const invalidateUserInfo = () => {
    window.localStorage.removeItem(USER_INFO_KEY);
};
