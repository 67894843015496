import {Axios} from "api/index";
import {getHmkCidInfo} from "common/hmkcid";


export const order = async (data, extra_data = {}) => {
    const hmkCidInfo = getHmkCidInfo();
    const extendedData = {
        ...data,
        metadata: hmkCidInfo,
        extra_data: extra_data,
    };

    return Axios.post('v1/store/orders/', extendedData);
};
