import Image from "next/image";
import {useEffect, useState} from "react";
import {getRandomArbitrary} from "common/utils";


const RollingImage = (props) => {
    const {images, prefix} = props;
    const [imageIndex, setImageIndex] = useState(0);

    const sleep = delay => new Promise(resolve => setTimeout(resolve, delay));

    useEffect(async () => {
        if (images.length === 1) return;
        await sleep(getRandomArbitrary(0, 500));
        const intervalId = setInterval(() => {
            setImageIndex(val => (val + 1) % images.length);
        }, getRandomArbitrary(1200, 2200));
        return () => clearInterval(intervalId);
    }, []);

    const keyPrefix = `${prefix}_` || '';

    return (
        <>
            {
                images.map((e, idx) => {
                    return <Image key={`${keyPrefix}${idx}`} unoptimized src={e}
                                  alt="name" layout="fill"
                                  objectFit="cover" style={{lineHeight: 0, zIndex: idx === imageIndex ? 2 : -1}}/>
                })
            }
        </>
    )

}

export default RollingImage;
