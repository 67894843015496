import FullButton from "components/buttons/FullButton";
import useTranslation from "next-translate/useTranslation";
import {AUTH_TYPE_EMAIL, AUTH_TYPE_NAVER} from "common/const";
import authStyles from "pages/auth/index.module.scss";
import SpeechBubble from "components/SpeechBubble";
import utilStyles from "styles/utils.module.scss";
import {useRouter} from "next/router";

const EmailButton = (props) => {
    const {disabled, signUp, lastLoginType, text} = props;
    const {t} = useTranslation('auth');
    const router = useRouter();

    const onClick = () => {
        if (disabled) {
            return;
        }

        router.push(
            {query: Object.assign({}, router.query, {withForm: true})},
            undefined,
            {shallow: true}
        );
    };

    return (
        <div className={authStyles.wideSNSLoginButton}>
            {
                (text || lastLoginType === AUTH_TYPE_EMAIL )&&
                <div className={authStyles.lastLoginBubble} style={{top: -20}}>
                    <SpeechBubble
                        sidePadding={12} verticalPadding={6}
                        pointLength={5} borderRadius={9999}
                        bottom
                        backgroundColor={utilStyles.brand}
                    >
                        <span className={authStyles.lastLoginBubbleText}>
                            {text ? text : t('sign-in.lastLogin')}
                        </span>
                    </SpeechBubble>
                </div>
            }
            <FullButton nonBold borderRadius={9999} onClick={onClick} height={48} fontSize={14} white>
                {t('sign-in.continueWithEmail')}
            </FullButton>
        </div>
    )
};

export default EmailButton;
