import styles from "components/buttons/purchaseButton.module.scss";
import utilStyles from "styles/utils.module.scss";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";

import ScrapIcon from "images/icons/heart_line.svg";
import ScrapIconActive from "images/icons/heart_fill.svg";
import SaleIcon from "images/icons/sale.svg";
import FullButton from "components/buttons/FullButton";
import classNames from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Modal from "components/Modal";
import OptionSelector, {OptionSelectorModeGift, OptionSelectorModePurchase,} from "components/input/OptionSelector";
import {useContext, useMemo, useState} from "react";
import ScrollContext from "context/ScrollContext";
import ProductScrapButton from "components/buttons/ProductScrapButton";
import UserContext from "context/AuthContext";
import {useRouter} from "next/router";
import {toast} from "react-toastify";
import {customAlert, isApp} from "common/utils";
import GiftIcon from "images/icons/gift.svg";
import ReservationModal, {ReservationModalMode} from "components/modal/ReservationModal";
import useTimer from "hooks/useTimer";
import ProductShareButton from "components/buttons/ProductShareButton";
import {Axios} from "api";
import ModalContext from "context/ModalContext";
import {redirectToLogin} from "common/redirect";
import IntegratedReservationModal from "components/modal/IntegratedReservationModal";


export default function PurchaseButton (props) {
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const isReservation = props.isReservation;
    const [modalMode, setModalMode] = useState(null);
    const {shouldHideNavBar} = useContext(ScrollContext);
    const user = useContext(UserContext).user;
    const product = props.product;
    const router = useRouter();
    const shouldUseTimer = isReservation || !product.is_add_to_pouch_before_open_allowed;
    const {timeString} = useTimer(product.opens_at_timestamp);
    const {openAppInstallModal} = useContext(ModalContext);
    const locale = props.locale || 'ko';
    const { t } = useTranslation('components-buttons-PurchaseButton');


    const isSoldOut = useMemo(() => {
        for (const option of product.options) {
            if (!option.is_sold_out) {
                return false;
            }
        }
        if (product.options.length === 0) {
            return false;
        }
        return true;
    }, [product]);

    const isMembersOnlyButNotLogined = useMemo(() => {
        return (product.is_members_only || (product.options.length > 0 && product.options.every(e => e.is_members_only))) && user === null;
    }, [product, user]);

    const isAppOnlyButNotInApp = (product.is_app_only || (product.options.length > 0 && product.options.every(e => e.is_app_only))) && !isApp();


    const hasReachedMaxProductBuyCount = useMemo(() => {
        return product.max_buy_count > 0 && product.max_buy_count <= product.current_buy_count;
    }, [product]);

    const hasReachedMaxOptionBuyCount = useMemo(() => {
        return product.options.length > 0 && product.options.every(e => e.max_buy_count > 0 && e.max_buy_count <= e.current_buy_count);
    }, [product]);

    const purchaseButtonText = useMemo(() => {
        if (isSoldOut) {
            return t('PurchaseButton.soldOut');
        }
        if (isAppOnlyButNotInApp) {
            if (isReservation) {
                return t('PurchaseButton.reserveInApp');
            } else {
                return t('PurchaseButton.purchaseInApp');
            }
        }
        if (isMembersOnlyButNotLogined) {
            return t('PurchaseButton.membersOnly');
        }
        if (hasReachedMaxProductBuyCount || hasReachedMaxOptionBuyCount) {
            return t('PurchaseButton.maxPurchaseReached');
        }
        if (shouldUseTimer && Boolean(timeString)) {
            return timeString;
        }
        return isReservation ? t('PurchaseButton.reserve') : t('PurchaseButton.purchase');
    }, [product, isSoldOut, isMembersOnlyButNotLogined, timeString, hasReachedMaxProductBuyCount, hasReachedMaxOptionBuyCount, isAppOnlyButNotInApp]);

    const onScrapClick = (isScrap) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailScrapButtonClick',
            {
                productId: product.id,
                productName: product.name,
                isScrap,
            }
        );
    };

    const onPurchaseButtonClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailPurchaseButtonClick',
            {
                productId: product.id,
                productName: product.name,
            }
        );
    };

    const onPouchSuccess = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailOptionSelectorAddToPouch',
            {
                productId: product.id,
                productName: product.name,
            }
        );
        setModalMode(null);
    };

    const onOptionSelect = (option) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailOptionSelectorOptionSelect',
            {
                productId: product.id,
                productName: product.name,
                optionId: option.id,
                optionName: option.name,
            }
        );
    };

    const onOptionDelete = (option) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailOptionSelectorOptionDelete',
            {
                productId: product.id,
                productName: product.name,
                optionId: option.id,
                optionName: option.name,
            }
        );
    };

    const onOptionCountChange = (option, count) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailOptionSelectorOptionCountChange',
            {
                productId: product.id,
                productName: product.name,
                optionId: option.id,
                optionName: option.name,
                count,
            }
        );
    };

    const onPurchase = (isGift) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailOptionSelectorPurchaseButtonClick',
            {
                productId: product.id,
                productName: product.name,
                isGift: isGift,
            }
        );
    };

    const handleModalClose = () => {
        setModalMode(null);
        if (props.resetSelectedOptions) {
            props.resetSelectedOptions();
        }
    };

    const downloadGiftCoupons = async () => {
        const data = {
            mold_keys: [
                '20231208_present_1000_won',
                '20231208_present_2000_won',
                '20231208_present_8_percent',
                '20231208_present_10_percent',
                '20231208_present_12_percent',
            ],
        }
        try {
            const res = await Axios.post('v1/coupons/download-bulk', data);
            if (res.status < 400) {
                if (res.data.success_count > 0) {
                    customAlert(
                        t('PurchaseButton.giftCouponAlert'),
                        t('PurchaseButton.confirm'),
                        () => {}
                    )
                }
            }
        } catch (err) {

        }
    };

    const handleGiftButtonClick = async () => {
        downloadGiftCoupons();
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailOptionSelectorSendGiftButtonClick',
            {
                productId: product.id,
                productName: product.name,
            }
        );
        setModalMode(val => val === null ? OptionSelectorModeGift : null);
    };

    const isIntegratedReservationProduct = product.is_integrated_reservation_product;
    const ReservationModalComponent = isIntegratedReservationProduct ? IntegratedReservationModal : ReservationModal;

    return (
        <>
            {
                modalMode !== ReservationModalMode ?
                    <Modal showOverflow={!isMobile} isOpen={modalMode !== null} bottom={isMobile}
                           width={isMobile ? "100%" : 416}
                           maxHeight={"85%"}
                           close={handleModalClose}
                    >
                        <div className={styles.selectorContainer}>
                            <OptionSelector
                                onPouchSuccess={onPouchSuccess}
                                resetSelectedOptions={props.resetSelectedOptions}
                                small type={3}
                                basePrice={props.basePrice}
                                product={product}
                                selectedOptions={props.selectedOptions}
                                addOptionCount={props.addOptionCount}
                                setOptionCount={props.setOptionCount}
                                deleteOptionCount={props.deleteOptionCount}
                                referralInfo={props.referralInfo}
                                onOptionSelect={onOptionSelect}
                                onOptionDelete={onOptionDelete}
                                onOptionCountChange={onOptionCountChange}
                                onPurchase={onPurchase}
                                mode={modalMode}
                            />
                        </div>
                    </Modal> :
                    <ReservationModalComponent close={handleModalClose} product={product}/>
            }
            <div className={styles.container}>
                {
                    ((!shouldUseTimer && Boolean(timeString)) || product.purchase_info_announcement) &&
                    <div className={styles.purchaseInfoContainer}>
                        {!(!shouldUseTimer && Boolean(timeString)) && <SaleIcon/>}
                        <span>
                            {(!shouldUseTimer && Boolean(timeString)) ? 
                                <Trans i18nKey="components-buttons-PurchaseButton:PurchaseButton.timeUntilOpen" components={[<span className={styles.openTimePrefix} />]} values={{ timeString }} />
                                : product.purchase_info_announcement
                            }
                        </span>
                    </div>
                }
                <div className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter, styles.buttonRowContainer, !shouldHideNavBar && isMobile ? styles.containerElevated : undefined)}>
                    {
                        !isReservation && locale === 'ko' &&
                        <div className={styles.shortcutsContainer}>
                            <ProductScrapButton
                                productId={product.id}
                                renderScrapped={() => <ScrapIconActive viewBox="0 0 24 24" className={styles.likeIcon} />}
                                renderUnscrapped={() => <ScrapIcon viewBox="0 0 24 24" className={classNames(styles.likeIcon, styles.unscrapLikeIcon)} />}
                                onScrapClick={onScrapClick}
                            />
                            <ProductShareButton product={product} />
                            <div className={styles.giftIcon} onClick={handleGiftButtonClick}>
                                <GiftIcon />
                            </div>
                        </div>
                    }
                    <div style={{flex: 1}}>
                        <FullButton
                            height={48} fontSize={isMobile ? 15 : 18}
                            lightWhite={isSoldOut || isMembersOnlyButNotLogined || hasReachedMaxProductBuyCount || hasReachedMaxOptionBuyCount || isAppOnlyButNotInApp}
                            title={purchaseButtonText}
                            onClick={() => {
                                onPurchaseButtonClick();
                                if (isAppOnlyButNotInApp) {
                                    openAppInstallModal();
                                } else if (isMembersOnlyButNotLogined) {
                                    redirectToLogin(router, true);
                                } else if (shouldUseTimer && Boolean(timeString)) {
                                    toast.info(t('PurchaseButton.notYetOpen'));
                                } else {
                                    if (isSoldOut) {
                                        toast.info(t('PurchaseButton.soldOutProduct'));
                                        return;
                                    }
                                    if (hasReachedMaxProductBuyCount) {
                                        toast.info(t('PurchaseButton.maxProductBuyCount', { maxBuyCount: product.max_buy_count }));
                                        return;
                                    }
                                    if (hasReachedMaxOptionBuyCount) {
                                        toast.info(t('PurchaseButton.maxOptionBuyCount'));
                                        return;
                                    }
                                    setModalMode(val =>
                                        val === null ?
                                            isReservation ? ReservationModalMode : OptionSelectorModePurchase
                                            : null
                                    );
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
