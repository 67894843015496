import styles from "components/toggle/SubscriptionToggle.module.scss";
import Image from "next/image";
import PointIcon from "images/static/point_icon.png";
import Toggle from "components/toggle";


const SubscriptionToggle = (props) => {
    const {isSubscribed, handleSubscriptionToggle, title, description, iconImageSource} = props;

    return (
        <div className={styles.subscriptionContainer}>
            <div className={styles.bottomPointIconWrapper}>
                <Image className={styles.pointIcon} unoptimized src={iconImageSource} layout="fill"
                       objectFit="cover"/>
            </div>
            <div className={styles.subscriptionDescriptionContainer}>
                        <span className={styles.subscriptionText}>
                            {
                                title
                            }
                        </span>
                <span className={styles.subscriptionSubtext}>
                            {
                                description
                            }
                        </span>
            </div>
            <Toggle on={isSubscribed} onClick={handleSubscriptionToggle}/>
        </div>
    )
};

export default SubscriptionToggle;
