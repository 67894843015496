import styles from "components/input/input.module.scss";
import CheckIcon from "images/icons/check_blue.svg";
import CrossIcon from "images/icons/cross_red.svg";
import classNames from "classnames";


export default function CheckedInputError (props) {
    const {isValid, message} = props;
    return (
        <div className={styles.checkedInputErrorContainer}>
            {
                isValid ?
                <CheckIcon className={styles.checkedInputErrorIcon} />
                :
                <CrossIcon className={styles.checkedInputErrorIcon} />
            }
            <span className={classNames(styles.checkedInputErrorText, isValid ? styles.validText : undefined)}>{message || ''}</span>
        </div>
    );
}
