import StarUnfilled from "images/icons/star_unfilled.svg";
import utilStyles from "styles/utils.module.scss";
import StarFilled from "images/icons/star_filled.svg";
import BrandStarFilled from "images/icons/star_brand_filled.svg";


export default function StarRatingLong (props) {
    const {margin, size, brandStar} = props;
    const rate = props.rate || 0;

    const rateInt = Math.round(rate);

    const fullStars = new Array(rateInt).fill(0);
    const emptyStars = new Array(5 - rateInt).fill(0);


    return (
        <div style={{display: 'inline-block'}} className={utilStyles.noLineHeight}>
            {
                fullStars.map((e, idx) => {
                    return <>
                        {
                            !!brandStar ?
                                <BrandStarFilled viewBox="0 0 24 24" key={`filled${idx}`} style={{
                                    marginLeft: idx === 0 ? 0 : margin,
                                    width: size,
                                    height: size
                                }}/>
                                :
                                <StarFilled viewBox="0 0 24 24" key={`filled${idx}`}
                                            style={{marginLeft: idx === 0 ? 0 : margin, width: size, height: size}}/>
                        }
                    </>
                })
            }
            {
                emptyStars.map((e, idx) => {
                    return <StarUnfilled viewBox="0 0 24 24" key={`empty${idx}`} style={{marginLeft: idx === 0 ? 0 : margin, width: size, height: size}} />
                })
            }
        </div>
    )
};
