import styles from "components/modal/reviewModal.module.scss";
import utilStyles from 'styles/utils.module.scss';
import TitleBar from "components/layout/TitleBar";
import Image from "next/image";
import PipeIcon from "images/icons/pipe.svg";
import {useFormik} from "formik";
import * as yup from "yup";
import classNames from "classnames";

import StarIcon from 'images/icons/star_filled.svg';
import UnfilledStarIcon from 'images/icons/star_unfilled.svg';
import BrandStarIcon from 'images/icons/star_brand_filled.svg';
import TextInput from "components/input/TextInput";
import BulletText from "components/BulletText";
import InputError from "components/input/InputError";
import ImageInput from "components/input/ImageInput";
import {useContext, useEffect, useRef, useState} from "react";
import {Axios} from "api";
import ImageUploadPreview from "components/input/ImageUploadPreview";
import FullButton from "components/buttons/FullButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Modal from "components/Modal";
import ImageUploader from "components/images/ImageUploader";
import {captureException} from "@sentry/nextjs";
import UserContext from "context/AuthContext";
import TruncateMarkup from "react-truncate-markup";
import CheckIcon from "images/icons/check_circle.svg";
import {customConfirm, numberWithComma} from "common/utils";
import {getHomeRoute} from "common/const";
import {useRouter} from "next/router";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";

const KEY_SCORE = 'score';
const KEY_CONTENT = 'content';
const KEY_IMAGES = 'images';
const KEY_ORDER_OPTION_ID = 'order_option_id';
const KEY_GIVEAWAY_ID = 'giveaway_id';
const KEY_PERMISSION_ID = 'permission_id';
const KEY_OPTION_ID = 'option_id';




export const StarSelector = (props) => {
    const {value, error, withoutText, brandStar} = props;
    const onChange = props.onChange || function () {};
    const size = props.size || 36;
    const margin = props.margin ?? 8;

    const starCount = value || 0;
    const unfilledStarCount = 5 - value;
    const {t} = useTranslation('component-review-modal')
    const scoreToExpression = {
        1: t('review.score.one'),
        2: t('review.score.two'),
        3: t('review.score.three'),
        4: t('review.score.four'),
        5: t('review.score.five'),
    }

    const stars = [];
    for (let i = 0; i < starCount; i++) {
        stars.push(1);
    }

    for (let i = 0; i < unfilledStarCount; i++) {
        stars.push(0);
    }

    return (
        <div>
            <div>
                {
                    stars.map((e, idx) => {
                        return (
                            e ?
                                <>
                                    {
                                        !!brandStar ?
                                            <BrandStarIcon
                                                style={{marginRight: margin}}
                                                key={idx} width={size} height={size} className={styles.selectorStarIcon}
                                                viewBox="0 0 24 24"
                                                onClick={() => onChange(idx + 1)}
                                            />
                                            :
                                            <StarIcon
                                                style={{marginRight: margin}}
                                                key={idx} width={size} height={size} className={styles.selectorStarIcon}
                                                viewBox="0 0 24 24"
                                                onClick={() => onChange(idx + 1)}
                                            />

                                    }
                                </>
                                :
                                <UnfilledStarIcon
                                    style={{marginRight: margin}}
                                    key={idx} width={size} height={size} className={styles.selectorStarIcon}
                                    viewBox="0 0 24 24"
                                    onClick={() => onChange(idx + 1)}
                                />
                        )
                    })
                }
            </div>
            {
                !withoutText &&
                <span className={classNames(styles.expression, error ? styles.scoreError : undefined)}>
                    {error || scoreToExpression[value] || ' '}&nbsp;
                </span>
            }
        </div>
    )
}


export default function ReviewModal (props) {
    const close = props.close || function () {};
    const {
        optionId, productName, review, brandName, isOpen, orderOptionId,
        giveawayId, permissionId, imageUrl, optionName, resetVariable, hideWarning, isPhotoRequired,
        merchantUid
    } = props;

    useEffect(() => {
        if (isOpen) {
            console.log('props', props);
        }
    }, [isOpen]);

    const onSuccess = props.onSuccess || function () {};

    const [isLoading, setIsLoading] = useState(false);
    const [isImageLoading, setIsImageLoading] = useState(false);

    const modalRef = useRef(null);
    const userContext = useContext(UserContext);

    const router = useRouter();
    const {t} = useTranslation('component-review-modal')
    const formik = useFormik({
        enableReinitialize: false,
        initialValues: {
            [KEY_SCORE]: review ? review.score || 0 : 0,
            [KEY_CONTENT]: review ? review.content || '' : '',
            [KEY_IMAGES]: review ? review.images || [] : [],
            [KEY_ORDER_OPTION_ID]: orderOptionId,
            [KEY_GIVEAWAY_ID]: giveawayId,
            [KEY_PERMISSION_ID]: permissionId,
            [KEY_OPTION_ID]: optionId,
        },
        validationSchema: yup.object({
            [KEY_SCORE]: yup.number().integer().required(t('validate.required')).min(1, t('validate.required')).max(5),
            [KEY_CONTENT]: yup.string().required(t('validate.required')).min(20, t('validate.getMinText', {length: 20})).max(1000, t('validate.getMaxText', {length: 1000})),
            [KEY_IMAGES]: isPhotoRequired ? yup.array().min(1, t('validate.getMinImage', {length: 1})) : yup.array(),
        }),
        onSubmit: async values => {
            const data = JSON.parse(JSON.stringify(values));
            data['image_ids'] = data[KEY_IMAGES].map(e => e.id);
            if (merchantUid) {
                data['merchant_uid'] = merchantUid;
            }
            delete data[KEY_IMAGES];

            try {
                setIsLoading(true);
                const isCreate = !review;
                const res = isCreate ? await Axios.post(`v2/store/reviews/`, data) : await Axios.patch(`v1/store/reviews/${review.id}/`, data);
                if (res.status < 400) {
                    console.log('res.data', res.data);
                    if (isCreate && ('nickname' in res.data.user)) {
                        userContext.setUser(res.data.user);
                    }
                    setIsLoading(false);
                    if (merchantUid) {
                        if (isCreate) {
                            onCreateSuccess(res.data.point);
                        } else {
                            customConfirm(t('message.completeUpdateReview'), t('message.moveReviewBox'), t('message.moveHome'), () => {
                                onSuccess();
                                close();
                            }, () => {
                                router.push(getHomeRoute());
                            });
                        }
                    } else {
                        if (isCreate) {
                            onCreateSuccess(res.data.point);
                        } else {
                            onSuccess();
                        }
                    }
                } else {
                    alert(res.data.display_message || t('message.failedCreateReview'));
                }
            } catch (e) {
                alert(t('message.failedCreateReview'));
                captureException(e);
            } finally {
                setIsLoading(false);
            }
        }
    });
    const {values, setFieldValue, initialValues, errors, setValues, touched, setFieldTouched, handleSubmit, resetForm, setFieldError, setErrors} = formik;

    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);

    useEffect(() => {
        if (resetVariable) {
            if (modalRef.current) {
                modalRef.current.scrollTop = 0;
            }
            resetForm();
        }
    }, [resetVariable])

    /*
    * 2024.07.31 ImageUploader 사용으로 Deprecated
    * */
    const uploadReviewImage = async (file) => {
        if (values[KEY_IMAGES].length >= 9) {
            alert('리뷰 이미지는 최대 9장까지 등록이 가능합니다.');
            return;
        }
        const formData = new FormData();
        formData.append('image', file);

        try {
            setIsImageLoading(true);
            const res = await Axios.post('v1/product-review-images/', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                params: {
                    merchant_uid: merchantUid,
                }
            });
            if (res.status < 400) {
                await setValues(oldValues => {
                    const newValue = JSON.parse(JSON.stringify(oldValues));
                    newValue[KEY_IMAGES].push(res.data);
                    return newValue;
                })
            } else {
                alert('사진 업로드에 실패했습니다. 잠시 후 다시 시도해주세요.');
            }
        } catch(e) {
            alert('사진 업로드에 실패했습니다. 잠시 후 다시 시도해주세요.');
            captureException(e);
        } finally {
            setIsImageLoading(false);
        }
    }

    /*
    * 2024.07.31 ImageUploader 사용으로 Deprecated
    * */
    const onDeleteReviewImage = async (imageId) => {
        try {
            setIsImageLoading(true);
            await Axios.delete(`v1/product-review-images/${imageId}/`);
        } catch (e) {
            captureException(e);
        } finally {
            await setValues(oldValues => {
                const newValue = JSON.parse(JSON.stringify(oldValues));
                newValue[KEY_IMAGES] = newValue[KEY_IMAGES].filter(e => e.id !== imageId);
                return newValue;
            })
            setIsImageLoading(false);
        }
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    const userAgent = typeof navigator !== 'undefined' && navigator.userAgent ? navigator.userAgent : '';
    const hideImageInput = userAgent.includes("Instagram");

    const onCloseClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReviewModalCloseClick',
            {
                isEdit: Boolean(review),
            },
        );
        setIsModalOpen(false);
        close();
    };

    const onContinueClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReviewModalContinueClick',
            {
                isEdit: Boolean(review),
            },
        );
        setIsModalOpen(false);
    };

    const onCloseButtonClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReviewModalCloseButtonClick',
            {
                isEdit: Boolean(review),
            },
        );
        setIsModalOpen(true);
    };

    const onCancelClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReviewModalCancelClick',
            {
                isEdit: Boolean(review),
            },
        );
        setIsModalOpen(true);
    };

    const onSubmitClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReviewModalSubmitClick',
            {
                isEdit: Boolean(review),
            },
        );
        handleSubmit();
    };
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [successResult, setSuccessResult] = useState({point: 0});

    const handleSuccessModalClose = props.handleSuccessModalClose || (() => {});

    const onCreateSuccess = (point) => {
        onSuccess();
        setSuccessResult({point});
        setSuccessModalOpen(true);
    };

    const onSuccessModalClose = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReviewModalSuccessModalClose',
            {
                isEdit: Boolean(review),
            },
        );
        setSuccessModalOpen(false);
        handleSuccessModalClose();
        close();
    };

    return (
        <>
            {
                successModalOpen &&
                <Modal isOpen={successModalOpen} unclosable={true} width={310} round close={onSuccessModalClose} zIndex={100000001}>
                    <div className={styles.successModalRoot}>
                        <CheckIcon viewBox="0 0 32 32" width={28} height={28} />
                        <span className={styles.modalGrantPoint}>+{numberWithComma(successResult.point)}p</span>
                        <span className={styles.reviewDoneText}>{t('message.completeCreateReview')}</span>
                        <div className={styles.successModalButtonWrapper}>
                            <FullButton fontSize={16} title="확인" height={44} onClick={onSuccessModalClose} />
                        </div>
                    </div>
                </Modal>
            }
            <Modal bodyRef={modalRef} isOpen={isOpen} width={isMobile ? '100%' : undefined} close={() => close()} height={isMobile ? '100%' : undefined}>
                <div className={styles.container}>
                    <Modal isOpen={isModalOpen} unclosable={true} width={isMobile ? 300 : 416} round close={() => setIsModalOpen(false)}>
                        <div className={styles.confirmModalContainer}>
                            <span className={styles.confirmModalText}><Trans i18nKey={"component-review-modal:message.checkWriting"} components={{br: <br />}}/></span>
                            <div className={utilStyles.flexRow}>
                                <div className={utilStyles.fullFlex}>
                                    <FullButton fontSize={16} title={t('stopWrite')} white height={isMobile ? 44 :48}
                                                onClick={onCloseClick} />
                                </div>
                                <div style={{width: 12}} />
                                <div className={utilStyles.fullFlex}>
                                    <FullButton fontSize={16} title={t('continueWrite')} onClick={onContinueClick} height={isMobile ? 44 : 48} />
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <div className={classNames(utilStyles.topSticky, utilStyles.whiteBackground)}>
                        <TitleBar fontSize={isMobile ? 18 : 20} title={review ? t('update') : t('write2')} isClose close={onCloseButtonClick} />
                    </div>
                    {
                        !review && !hideWarning &&
                        <div className={styles.adContainer}>
                            {
                                !isMobile &&
                                <span className={styles.adText}>{t('ad.title')}</span>
                            }
                            <div className={styles.reviewPointContainer}>
                                <span className={styles.reviewPointText}>&nbsp;&nbsp;•&nbsp;&nbsp;<Trans i18nKey={"component-review-modal:ad.text1"} components={{sp: <span className={styles.reviewPoint} />}}/></span>
                                <span className={styles.reviewPointText}>&nbsp;&nbsp;•&nbsp;&nbsp;<Trans i18nKey={"component-review-modal:ad.text2"} components={{sp: <span className={styles.reviewPoint} />}}/></span>
                                <span className={styles.reviewPointText}>
                                    &nbsp;&nbsp;•&nbsp;&nbsp;<Trans i18nKey={"component-review-modal:ad.text3"} components={{sp: <span className={styles.reviewPoint} />}}/>
                                </span>
                            </div>
                        </div>
                    }
                    <div className={styles.optionSection}>
                        <div className={styles.imageWrapper}>
                            <Image unoptimized src={imageUrl} objectFit="cover" layout="fill" />
                        </div>
                        <div>
                            <TruncateMarkup lines={1} ellipsis={<span>...</span>}>
                                <span className={styles.brand}>
                                    <span>{brandName}</span>
                                </span>
                            </TruncateMarkup>
                            <TruncateMarkup lines={1} ellipsis={<span>...</span>}>
                                <span className={styles.productName}>
                                    <span>{productName}</span>
                                </span>
                            </TruncateMarkup>
                            <div className={styles.optionNameWrapper}>
                                <span className={styles.optionText}>{t('option')}</span>
                                <PipeIcon className={styles.pipe} viewBox="0 0 16 16" />
                                <TruncateMarkup lines={1} ellipsis={<span>...</span>}>
                                    <span className={styles.optionText}>{optionName}</span>
                                </TruncateMarkup>
                            </div>

                        </div>
                    </div>
                    <div className={styles.scoreSection}>
                        <span className={styles.title}>{t('review.rating')}</span>
                        <span className={styles.scoreText}>{t('review.survey')}</span>
                        <StarSelector value={values[KEY_SCORE]} error={touched[KEY_SCORE] && errors[KEY_SCORE]}
                                      size={32} onChange={e => setFieldValue(KEY_SCORE, e)} brandStar/>
                    </div>
                    <div className={styles.contentSection}>
                        <span className={styles.title}>{t('review.requestWrite')}</span>
                        <TextInput
                            readonly={isLoading} onBlur={() => setFieldTouched(KEY_CONTENT, true)} value={values[KEY_CONTENT]}
                            onChange={e => setFieldValue(KEY_CONTENT, e)}
                            error={errors[KEY_CONTENT] && touched[KEY_CONTENT]}
                            multiLine height={240} maxLength={1000} redCounter={errors[KEY_CONTENT]}
                            placeholder={t('message.requestReview')}
                            trimOnCount
                        />
                        {
                            errors[KEY_CONTENT] && touched[KEY_CONTENT] && <InputError message={errors[KEY_CONTENT]} />
                        }
                        <div style={{height: 12}} />
                        <BulletText>{t('message.caution')}</BulletText>
                        {
                            (!review || (!!review && values[KEY_IMAGES].length !== 0))?
                            <div className={styles.photoSection}>
                                <div className={classNames(styles.title, utilStyles.noLineHeight)}>
                                    <span className={utilStyles.verticalMiddle}>{t('attachImage')}</span>
                                    <span className={styles.subTitle}>
                                    {isPhotoRequired ? <span className={styles.subTitleRed}>{t('required')}</span> : t('choice')}
                                </span>
                                    {
                                        errors[KEY_IMAGES] && touched[KEY_IMAGES] &&
                                        <InputError message={errors[KEY_IMAGES]}/>
                                    }
                                </div>
                                <div className={styles.imageRow}>
                                    <ImageUploader
                                        post={props.post}
                                        disabled={isLoading}
                                        images={values[KEY_IMAGES]}
                                        setImages={(val) => setFieldValue(KEY_IMAGES, val)}
                                        uploadUrl='v1/product-review-images/'
                                        isEditMode={!!review} //리뷰 수정인 경우 이미지 업로드 불가능
                                        size={9}
                                        //onDelete={(imageId) => onDeleteReviewImage(imageId)}
                                    />
                                </div>
                                <div className={styles.photoSectionDescriptionText}>
                                    {
                                        hideImageInput ?
                                            <BulletText>{t('message.unSupportedBrowserImageAttachments')}</BulletText>
                                            :
                                            <BulletText>{t('message.getMaxAttachImage', {length: 9})}</BulletText>
                                    }
                                </div>
                            </div>
                                :
                                <></>
                        }
                    </div>

                    {/*<div className={classNames(styles.title, utilStyles.noLineHeight)}>
                            <span className={utilStyles.verticalMiddle}>사진 첨부</span>
                            <span className={styles.subTitle}>
                                {isPhotoRequired ? <span className={styles.subTitleRed}>(필수)</span> : '(선택)'}
                            </span>
                            {
                                errors[KEY_IMAGES] && touched[KEY_IMAGES] && <InputError message={errors[KEY_IMAGES]} />
                            }
                        </div>
                        <div className={styles.imageRow}>
                            <div className={styles.imageCol}>
                                <div className={styles.colWrapper}>
                                    <div className={styles.colContent}>
                                        <ImageInput disabled={hideImageInput} isLoading={isImageLoading} onSelect={e => uploadReviewImage(e)} />
                                    </div>
                                </div>
                            </div>
                            {
                                values[KEY_IMAGES].map(e => {
                                    return (
                                        <div key={e.id} className={styles.imageCol}>
                                            <div className={styles.colWrapper}>
                                                <div className={styles.colContent}>
                                                    <ImageUploadPreview src={e.thumbnail_url} onDelete={() => {
                                                        onDeleteReviewImage(e.id);
                                                    }}/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>*/}
                    <div className={styles.flexRow}>
                        {
                            isMobile &&
                            <>
                                <div className={utilStyles.fullFlex}>
                                    <FullButton light title={t('cancel')}height={48} fontSize={isMobile ? 16 : 18}
                                                onClick={onCancelClick}>
                                        취소
                                    </FullButton>
                                </div>
                                <div style={{width: 9}} />
                            </>
                        }
                        <div className={utilStyles.fullFlex}>
                            <FullButton disabled={isLoading || isImageLoading} title={review ? t('update'): t('write1')} height={48} white={!isMobile}  fontSize={isMobile ? 16 : 18} onClick={onSubmitClick} />
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
