import styles from "components/toggle.module.scss";
import classNames from "classnames";


const Toggle = ({on, onClick}) => {
    return (
        <div className={classNames(styles.toggleContainer, on ? styles.toggleContainerOn : undefined)} onClick={onClick}>
            <div className={styles.toggleButton} />
        </div>
    )
}

export default Toggle;
