import Modal from "components/Modal";
import styles from "components/sections/checkout/ShareGiftLinkModal.module.scss";
import ShareMessageIcon from "images/icons/share_message.svg";
import ShareKakaoIcon from "images/icons/share_kakao.svg";
import ShareLinkIcon from "images/icons/share_link.svg";
import CloseIcon from "images/icons/close.svg";
import * as Sentry from "@sentry/nextjs";
import {fullUrl, getGiftRoute} from "common/const";
import Clipboard from "react-clipboard.js";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import {isApp, isIOS} from "common/utils";
import KakaoLoader from "components/KakaoLoader";


const ShareGiftLinkModal = (props) => {
    const {order_number, orderer_name, imageUrl, isOpen, close, expiresAt} = props;

    const [smsLink, setSmsLink] = useState('');

    const shareLink = fullUrl(getGiftRoute(order_number));
    const expiresAtDate = new Date(expiresAt * 1000);
    const expiresAtString = `${expiresAtDate.getMonth() + 1}월 ${expiresAtDate.getDate()}일`;
    const content = `[헤메코랩] 선물 안내\n\n${orderer_name}님이 선물을 보냈어요!\n${expiresAtString}까지 배송지를 입력해주세요.\n\n선물받기: ${shareLink}`;
    const kakaoContent = `[헤메코랩] 선물 안내\n\n${orderer_name}님이 선물을 보냈어요!\n${expiresAtString}까지 배송지를 입력해주세요.`;

    useEffect(() => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ShareGiftLinkModalOpen');
    }, []);

    const onKakaoShareButtonClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ShareGiftLinkModalKakaoShareButtonClick');

        try {
            Kakao.Share.sendDefault({
                objectType: 'feed',
                content: {
                    title: kakaoContent,
                    imageUrl: imageUrl,
                    link: {
                        mobileWebUrl: shareLink,
                        webUrl: shareLink,
                    },
                },
                buttons: [
                    {
                        title: '선물 받기',
                        link: {
                            mobileWebUrl: shareLink,
                            webUrl: shareLink,
                        },
                    },
                ],
            });
        } catch (e) {
            console.error('ShareGiftLinkModal onKakaoShareButtonClick error', e);
            Sentry.captureException(e);
        }
    };

    const onClipboardCopyButtonClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ShareGiftLinkModalClipboardCopyButtonClick');
        toast.info('링크를 복사했어요');
        close();
    };

    const handleLinkShareClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ShareGiftLinkModalLinkShareButtonClick');
        if (navigator.share) {
            navigator.share({
                // title: content,
                text: content,
                // url: shareLink,
            }).then(() => {
                console.log('Thanks for sharing!');
            })
                .catch(console.error);
        } else {
        }
    };

    const handleSmsLinkClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ShareGiftLinkModalSmsButtonClick');
    };

    useEffect(() => {
        setTimeout(() => {
            if (navigator) {
                const ua = navigator.userAgent.toLowerCase();
                const isAndroid = ua.indexOf("android") > -1;
                if (isAndroid) {
                    setSmsLink(`sms:?&body=${encodeURIComponent(content)}`);
                    return;
                }
            }
            setSmsLink(`sms://&body=${encodeURIComponent(content)}`);
        }, 1000);
    }, []);

    return (
        <>
            <KakaoLoader />
            <Modal showOverflow={false} isOpen={isOpen} bottom={true} width={"100%"}
                   close={close}>
                <div className={styles.root}>
                    <a href={smsLink} className={styles.iconContainer} onClick={handleSmsLinkClick}>
                        <ShareMessageIcon />
                        <span>메시지</span>
                    </a>
                    <div className={styles.iconContainer} onClick={onKakaoShareButtonClick}>
                        <ShareKakaoIcon />
                        <span>카카오톡</span>
                    </div>
                    {
                        typeof navigator !== 'undefined' && navigator.share ?
                            <div className={styles.iconContainer} onClick={handleLinkShareClick}>
                                <ShareLinkIcon />
                                <span>링크 공유</span>
                            </div> :
                            <Clipboard
                                option-text={() => content}
                                onSuccess={onClipboardCopyButtonClick}
                                className={styles.clipboard}
                            >
                                <div className={styles.iconContainer}>
                                    <ShareLinkIcon />
                                    <span>링크 공유</span>
                                </div>
                            </Clipboard>
                    }
                    <CloseIcon viewBox="0 0 20 20" className={styles.closeIcon} onClick={close} />
                </div>
            </Modal>
        </>
    )
}

export default ShareGiftLinkModal;
