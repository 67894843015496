import StarFilled from "images/icons/star_filled.svg";
import BrandStarFilled from "images/icons/star_brand_filled.svg";

import styles from "components/review/starRating.module.scss";
import utilStyles from "styles/utils.module.scss";
import classNames from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";


export default function StarRating (props) {
    const rate = (props.rate || 0).toFixed(1);
    const brandStar = props.brandStar
    return (
        <div className={styles.wrapper}>
            {
                !!brandStar ?
                    <BrandStarFilled className={utilStyles.verticalMiddle} width={24} height={24} viewBox="0 0 24 24"/>
                    :
                    <StarFilled className={utilStyles.verticalMiddle} width={24} height={24} viewBox="0 0 24 24"/>
            }
            <span className={styles.rate}>{rate}</span>
            <span className={styles.five}>/</span>
            <span className={classNames(styles.five, styles.leftMargin)}>5</span>
        </div>
    )
};
