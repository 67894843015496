import {formattedDate} from "common/utils";

export const hideUntilTomorrow = (key) => {
    const tomorrowStart = new Date();
    tomorrowStart.setDate(tomorrowStart.getDate() + 1);
    tomorrowStart.setHours(0, 0, 0, 0);
    window.localStorage.setItem(key, formattedDate(tomorrowStart));
};

export const checkIfOpenAllowed = (key) => {
    const closeInfo = window.localStorage.getItem(key);
    if (closeInfo) {
        const now = new Date();
        if (formattedDate(now) < closeInfo) {
            return false;
        }
    }
    return true;
};

export const hideForXMinutes = (key, minutes) => {
    const oneHourLater = new Date();
    oneHourLater.setMinutes(oneHourLater.getMinutes() + minutes);
    const existingExpiration = window.localStorage.getItem(key);
    if (!(existingExpiration && existingExpiration > formattedDate(oneHourLater))) {
        window.localStorage.setItem(key, formattedDate(oneHourLater));
    }
}
