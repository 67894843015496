import {createContext, useContext, useEffect, useState} from "react";
import {Axios} from "api";
import {captureException} from "@sentry/nextjs";
import UserContext from "context/AuthContext";


const ABTestContext = createContext(undefined);


export default ABTestContext;

export const ABTEST_KEY_PALETTE_NAME = 'palette_name';
export const ABTEST_KEY_CHECKOUT_SUCCESS_SHOW_PALETTE = 'checkout_success_show_palette';
export const ABTEST_KEY_FORCE_POUCH = 'force_pouch';

export const ABTestContextProvider = ({children}) => {
    const [keyToGroup, setKeyToGroup] = useState({});

    const user = useContext(UserContext).user;

    useEffect(async () => {
        if (user === undefined) {
            return;
        }

        try {
            const res = await Axios.get('v1/auth/ab-test-info');
            if (res.status < 400) {
                setKeyToGroup(res.data.abtest_key_to_group);
            }
            mixpanel.people.set(res.data.abtest_key_to_group);
        } catch (e) {
            captureException(e);
        }
    }, [user]);

    const getGroupFromKey = (key) => {
        return keyToGroup[key];
    }

    const contextValue = {
        getGroupFromKey,
    };

    return (
        <ABTestContext.Provider value={contextValue}>
            {children}
        </ABTestContext.Provider>
    )
}
