import styles from "components/ToastContent.module.scss";


const ToastContent = ({title, description, closeButton, onClose}) => {
    const onCloseHandler = onClose || (() => {});
    return (
        <div className={styles.toastContentContainer}>
            <div className={styles.toastContentContent}>
                <span className={styles.toastContentTitle}>{title}
                    {
                        description &&
                        <>
                            <br/>
                            <span className={styles.toastContentDescription}>{description}</span>
                        </>
                    }
                </span>
            </div>
            {
                closeButton &&
                <div onClick={onCloseHandler} className={styles.toastContentCloseButton}>
                    {closeButton}
                </div>
            }
        </div>
    )
}

export default ToastContent;
