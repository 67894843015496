import Modal from "components/Modal";
import styles from 'components/modal/MainCouponModal.module.scss';
import CouponDownloadIcon from "images/icons/coupon_download.svg";
import {customAlert, customConfirm, formatDate, isApp, numberWithComma} from "common/utils";
import {Axios} from "api";
import {redirectToLogin} from "common/redirect";
import {captureException, captureMessage} from "@sentry/nextjs";
import {toast} from "react-toastify";
import {useEffect, useRef, useState} from "react";
import CloseIcon from "images/icons/close.svg";
import {newCustomConfirm} from "common/newCustomConfirm";
import {hideUntilTomorrow} from "common/modalHelper";
import {useRouter} from "next/router";

import parse from 'html-react-parser';
import classNames from "classnames";
import FullButton from "components/buttons/FullButton";
import {useContext} from "react";
import PushContext from "context/PushContext";
import {couponsRoute, isHomeRoute} from "common/const";
import {ChuseokCouponModalContent} from "components/modal/CouponModals";
import ModalContext from "context/ModalContext";
import utilStyles from 'styles/utils.module.scss';
import PipeIcon from "images/icons/pipe.svg";
import AppPointModal from "components/modal/CouponModal/AppPointModal";
import {BottomSheet} from "react-spring-bottom-sheet";
import BottomModal from "components/BottomModal";
import {SelfVerificationButton} from "pages/mypage/my-info";
import {requestAppStoreReview} from "common/app_store_review";
import UserContext from "context/AuthContext";
import useTranslation from 'next-translate/useTranslation';


export const LOCAL_STORAGE_KEY_MAIN_COUPON_MODAL_CLOSE_UNTIL = 'main_coupon_modal_close_until';

export const mainCouponModalKey = (modalId) => `${LOCAL_STORAGE_KEY_MAIN_COUPON_MODAL_CLOSE_UNTIL}_${modalId}`;

export const handleCouponDownload = async (router, coupon, hasReceived, isLoading, setIsLoading, onSuccess, t) => {
    typeof mixpanel !== 'undefined' && mixpanel.track('MainCouponModalCouponDownload', {
        mold_key: coupon.mold_key,
    });

    if (hasReceived && !coupon.is_duplication_allowed) {
        return;
    }

    if (isLoading) {
        return;
    }

    setIsLoading(true);
    try {
        const data = {
            mold_key: coupon.mold_key,
        }
        const res = await Axios.post('v1/coupons/download', data);
        if (res.status < 400) {
            onSuccess([coupon.id]);
        } else if (res.status === 401) {
            // close();
            redirectToLogin(router, true);
        } else {
            captureMessage(JSON.stringify(res.data));
            toast.info(res.data.display_message || (
                coupon.is_point_only ?
                    t('MainCouponModal.pointReceiveError')
                    :
                    t('MainCouponModal.downloadError')
            ),
                {autoClose: 1000}
            );
        }
    } catch (e) {
        captureException(e);
        if (coupon.is_point_only) {
            toast.info(t('MainCouponModal.pointReceiveError'), {autoClose: 1000});
        } else {
            toast.info(t('MainCouponModal.downloadError'), {autoClose: 1000});
        }
    } finally {
        setIsLoading(false);
    }
};

export const handleCouponDownloadAll = async (router, couponMolds, hasReceivedAll, isLoading, setIsLoading, onSuccess, close, t) => {
    console.log(router, couponMolds, hasReceivedAll, isLoading, setIsLoading, onSuccess);
    typeof mixpanel !== 'undefined' && mixpanel.track('MainCouponModalCouponDownloadAll', {
        mold_keys: couponMolds.map(couponMold => couponMold.mold_key),
    });

    if (hasReceivedAll && !couponMolds[0].is_duplication_allowed) {
        toast.info(t('MainCouponModal.alreadyDownloaded'));
        return;
    }

    if (isLoading) {
        return;
    }

    setIsLoading(true);
    try {
        const data = {
            mold_keys: couponMolds.map(couponMold => couponMold.mold_key),
        }
        const res = await Axios.post('v1/coupons/download-bulk', data);
        if (res.status < 400) {
            if (res.data.success_count) {
                onSuccess(couponMolds.map(couponMold => couponMold.id));
            } else {
                toast.info(t('MainCouponModal.alreadyDownloaded'));
            }
        } else if (res.status === 401) {
            close();
            redirectToLogin(router, true);
        } else {
            captureMessage(JSON.stringify(res.data));
            toast.info(res.data.display_message || t('MainCouponModal.downloadError'));
        }
    } catch (e) {
        captureException(e);
        toast.info(t('MainCouponModal.downloadError'));
    } finally {
        setIsLoading(false);
    }
};

const MainCouponModalCoupon = (props) => {
    const {coupon, onSuccess, hasReceived, close} = props;
    const {t} = useTranslation('components-modal-MainCouponModal');

    const [isLoading, setIsLoading] = useState(false);

    const router = useRouter();

    const discountAmount = coupon.type === 'FIX' ? `${numberWithComma(coupon.amount)}원` : `${coupon.amount}%`;

    const couponExpiresAt = coupon.expiration_type === 'fixed_date' ? `~${formatDate(coupon.expires_at)}` : `발급일로부터 ${coupon.valid_day}일`;

    return (
        <div className={styles.coupon} onClick={() => {
            handleCouponDownload(router, coupon, hasReceived, isLoading, setIsLoading, onSuccess, t);
        }}>
            <div className={styles.couponInfo}>
                <span className={styles.couponName}>{coupon.name}</span>
                <span className={styles.couponTitle}>{discountAmount} {t('MainCouponModal.discount')}</span>
                <div className={styles.couponDescriptionContainer}>
                    {
                        !!coupon.min_amount &&
                        <span className={styles.couponDescription}>{t('MainCouponModal.minOrderAmount')}: {numberWithComma(coupon.min_amount)}{t('MainCouponModal.won')}</span>
                    }
                    <span className={styles.couponDescription}>{t('MainCouponModal.couponPeriod')}: {couponExpiresAt}</span>
                </div>
            </div>
            <div className={styles.couponDownload}>
                <CouponDownloadIcon/>
            </div>
        </div>
    )
};

const MainCouponModalCouponTypeContent = (props) => {
    const {modalInfo, handleCloseClick, receivedCouponMoldIdsRef, onSuccess} = props;
    const {t} = useTranslation('components-modal-MainCouponModal');

    return (
        <>
            <div className={styles.closeIconWrapper} onClick={handleCloseClick}>
                <CloseIcon viewBox="0 0 20 20" className={styles.closeIcon}/>
            </div>
            <div className={styles.title}>
                {modalInfo.title}
            </div>
            <div className={styles.description}>
                {parse(modalInfo.description)}
            </div>
            <div className={styles.couponList}>
                {
                    modalInfo.coupon_molds.map((coupon, index) => {
                        return (
                            <MainCouponModalCoupon
                                key={index} coupon={coupon} onSuccess={onSuccess}
                                hasReceived={receivedCouponMoldIdsRef.current.includes(coupon.id)}
                                close={close}
                            />
                        )
                    })
                }
            </div>
            <div className={styles.footer}>
                {t('MainCouponModal.checkCouponsInMypage')}
            </div>
        </>
    )
};


const MainCouponModalCouponTypeImage = (props) => {
    const {modalInfo, receivedCouponMoldIdsRef, onSuccess, isLoading, setIsLoading, close} = props;
    const {t} = useTranslation('components-modal-MainCouponModal');
    const {openAppInstallModal} = useContext(ModalContext);

    const router = useRouter();
    const userContext = useContext(UserContext);
    const user = userContext.user;

    const onLinkClick = (link, eventId) => {
        if (!link) return;
        typeof mixpanel !== 'undefined' && mixpanel.track('EventDetailLinkClick', {
            link: link, eventId: eventId
        })
        router.push(link);
    };

    const downloadCouponByButton = (button) => {
        const filtered_coupon_molds = modalInfo.coupon_molds.filter(
            coupon => button.coupon_mold_keys.includes(coupon.mold_key)
        );
        if (filtered_coupon_molds.length === 0) {
            close();
            return;
        }
        handleCouponDownloadAll(
            router, filtered_coupon_molds,
            filtered_coupon_molds.every(coupon => receivedCouponMoldIdsRef.current.includes(coupon.id)),
            isLoading,
            setIsLoading,
            onSuccess,
            close,
            t
        );
    };

    const handleButtonClick = (button) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'MainCouponModalCouponTypeImageButtonClick',
            {
                modalIdentifier: modalInfo.identifier,
                buttonType: button.type,
                buttonCouponMoldKeys: button.coupon_mold_keys,
                buttonLink: button.link,
            }
        );
        if (button.type === '쿠폰') {
            if (button.coupon_mold_keys.length === 0) {
                return;
            }
            if (button.coupon_mold_keys[0] === 'app_store_review') {
                if (!isApp()) {
                    customConfirm(
                        t('MainCouponModal.appOnlyCoupon'), t('MainCouponModal.confirm'), t('MainCouponModal.cancel'),
                        () => {
                            openAppInstallModal();
                        },
                        () => {},
                    );
                    return;
                }
                if (!user) {
                    customConfirm(
                        t('MainCouponModal.joinMembershipPrompt'), t('MainCouponModal.confirm'), t('MainCouponModal.cancel'),
                        () => {
                            redirectToLogin(router);
                        },
                        () => {},
                    );
                    return;
                }
                requestAppStoreReview();
                setTimeout(() => {
                    downloadCouponByButton(button);
                }, 3000);
                return;
            }
            downloadCouponByButton(button);
        } else if (button.type === '링크') {
            onLinkClick(button.link, event.id);
        }
    };

    return (
        <>
            <div style={{width: 343, position: 'relative'}}>
                {
                    modalInfo.image_urls.map((imageUrl, index) => {
                        return (
                            <img key={`image-${index}`} src={imageUrl}/>
                        )
                    })
                }
                {
                    modalInfo.button_list.length > 0 &&
                    modalInfo.button_list.map(button => {
                        const imageWidth = button.image_width;
                        const imageHeight = button.image_height;
                        const x1 = button.x1;
                        const x2 = button.x2;
                        const y1 = button.y1;
                        const y2 = button.y2;

                        const buttonWidth = `${(Math.abs(x2 - x1) / imageWidth * 100).toFixed(2)}%`;
                        const buttonHeight = `${(Math.abs(y2 - y1) / imageHeight * 100).toFixed(2)}%`;
                        const top = `${(Math.abs(y1) / imageHeight * 100).toFixed(2)}%`;
                        const left = `${(Math.abs(x1) / imageWidth * 100).toFixed(2)}%`;
                        return (
                            <div
                                onClick={() => {
                                    handleButtonClick(button);
                                }}
                                key={button.id}
                                className={classNames(utilStyles.absolute, utilStyles.cursorPointer)}
                                style={{
                                    zIndex: 5,
                                    top: top,
                                    left: left,
                                    width: buttonWidth,
                                    height: buttonHeight,
                                    cursor: 'pointer'
                                }}
                            >
                            </div>
                        );
                    })
                }
            </div>
        </>
    )
};

const MainPageModalHtmlType = (props) => {
    const {modalInfo, handleButtonClick} = props;
    const {t} = useTranslation('components-modal-MainCouponModal');

    return (
        <>
            <div className={classNames(utilStyles.relative)}>
                {
                    modalInfo.button_text &&
                    <div className={styles.modalButtonWrapper}
                         style={{position: "absolute", bottom: 0, left: 0, right: 0, zIndex: 1,}}>
                        <FullButton onClick={handleButtonClick} height={44}
                                    fontSize={16}>{modalInfo.button_text}</FullButton>
                    </div>
                }
                <div style={{width: '100%', position: "relative"}}
                     dangerouslySetInnerHTML={{__html: modalInfo.inner_html}}></div>
            </div>


        </>
    )
}


const MainCouponModal = (props) => {
    const {modalInfo, close} = props;
    const {t} = useTranslation('components-modal-MainCouponModal');

    const pushContext = useContext(PushContext);
    const router = useRouter();

    const {receivedCouponMoldIdsRef} = useContext(ModalContext);

    const [isLoading, setIsLoading] = useState(false);
    const [pseudoState, setPseudoState] = useState(0);

    const hideRef = useRef();

    const openCouponDownloadConfirm = () => {
        newCustomConfirm(
            t('MainCouponModal.couponDownloaded'),
            t('MainCouponModal.checkInCouponWallet'),
            t('MainCouponModal.confirm'), null,
            () => {
            },
            () => {
            }
        );
    }

    const onSuccess = (moldIds) => {
        receivedCouponMoldIdsRef.current = receivedCouponMoldIdsRef.current.concat(moldIds);
        if (modalInfo.coupon_molds.length > 1) {
            console.log('onSuccess', moldIds);
            if (modalInfo.coupon_molds.filter(e => moldIds.includes(e.id)).filter(e => e.is_point_only).length === moldIds.length) {
                toast.info(t('MainCouponModal.pointReceived'));
            } else {
                const isAllDownloaded = modalInfo.coupon_molds.every(coupon => receivedCouponMoldIdsRef.current.includes(coupon.id));
                if (isAllDownloaded) {
                    openCouponDownloadConfirm();
                    close();
                } else {
                    toast.info(t('MainCouponModal.couponDownloadSuccess'));
                }
            }
            return;
        }
        openCouponDownloadConfirm();
        close();
    };

    useEffect(() => {
        const coupon = modalInfo.coupon_molds[0];
        if (modalInfo.identifier === '20231119_suneung_modal') {
            document.getElementById('20231119_suneung_modal_input').addEventListener('change', function (e) {
                if (e.target.files[0]) {
                    handleCouponDownload(router, coupon, receivedCouponMoldIdsRef.current.includes(coupon.id), isLoading, setIsLoading, onSuccess, t);
                }
            });
        }
    }, [hideRef]);

    const handleHideForDay = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MainCouponModalHideForDay',
            {
                identifier: modalInfo.identifier,
            }
        );
        hideUntilTomorrow(mainCouponModalKey(modalInfo.id));
        close();
    };

    const handleCloseClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'MainCouponModalClose',
            {
                identifier: modalInfo.identifier,
            }
        );
        close();
    };
    const [isPushButtonPressed, setIsPushButtonPressed] = useState(false);

    const handleButtonClick = () => {
        if (modalInfo.identifier.includes('push_coupon_modal')) {
            typeof mixpanel !== 'undefined' && mixpanel.track('MainCouponModalCouponDownload', {
                mold_key: !!modalInfo.coupon_molds && modalInfo.coupon_molds.length > 0 ? modalInfo.coupon_molds[0].mold_key : null,
                identifier: modalInfo.identifier,
            });
            setIsPushButtonPressed(true);
            pushContext.sendPushAgreementRequest(true);
            typeof mixpanel !== 'undefined' && mixpanel.track(
                'PushAgreementModalButtonClicked',
                {
                    identifier: modalInfo.identifier,
                }
            );
        } else if (modalInfo.identifier === '20231119_suneung_modal') {
        } else if (modalInfo.identifier === '20231010_heveblue') {
            typeof mixpanel !== 'undefined' && mixpanel.track('MainCouponModalCouponDownload', {
                mold_key: !!modalInfo.coupon_molds && modalInfo.coupon_molds.length > 0 ? modalInfo.coupon_molds[0].mold_key : null,
            });
            const coupon = modalInfo.coupon_molds[0];
            handleCouponDownload(router, coupon, receivedCouponMoldIdsRef.current.includes(coupon.id), isLoading, setIsLoading, onSuccess, t);
        } else if (modalInfo.identifier === '20231018_coralhaze') {
            typeof mixpanel !== 'undefined' && mixpanel.track('MainCouponModalCouponDownload', {
                mold_key: !!modalInfo.coupon_molds && modalInfo.coupon_molds.length > 0 ? modalInfo.coupon_molds[0].mold_key : null,
                identifier: modalInfo.identifier,
            });
            const coupon = modalInfo.coupon_molds[0];
            handleCouponDownload(router, coupon, receivedCouponMoldIdsRef.current.includes(coupon.id), isLoading, setIsLoading, onSuccess, t);
        } else if (modalInfo.identifier === '20231208_present') {
            typeof mixpanel !== 'undefined' && mixpanel.track('MainCouponModalCouponDownload', {
                mold_key: !!modalInfo.coupon_molds && modalInfo.coupon_molds.length > 0 ? modalInfo.coupon_molds[0].mold_key : null,
                identifier: modalInfo.identifier,
            });
            const hasDownloadedAll = modalInfo.coupon_molds.every(coupon => receivedCouponMoldIdsRef.current.includes(coupon.id));
            handleCouponDownloadAll(router, modalInfo.coupon_molds, hasDownloadedAll, isLoading, setIsLoading, (ids) => {
                close();
                onSuccess(ids);
            }, close, t);
        } else if (modalInfo.button_text && modalInfo.coupon_molds) {
            typeof mixpanel !== 'undefined' && mixpanel.track('MainCouponModalCouponDownload', {
                mold_key: !!modalInfo.coupon_molds && modalInfo.coupon_molds.length > 0 ? modalInfo.coupon_molds[0].mold_key : null,
                identifier: modalInfo.identifier,
            });
            const coupon = modalInfo.coupon_molds[0];
            handleCouponDownload(router, coupon, receivedCouponMoldIdsRef.current.includes(coupon.id), isLoading, setIsLoading, onSuccess, t);
        }
    }

    const isPushOn = pushContext.isDevicePushOn && pushContext.isMarketingAgreed;

    const downloadCoupon = async (coupon) => {
        handleCouponDownload(
            router, coupon, receivedCouponMoldIdsRef.current.includes(coupon.id),
            isLoading, setIsLoading, onSuccess, t
        );
    };

    useEffect(() => {
        const downloadCoupon = async () => {
            if (modalInfo.coupon_molds.length === 0) return;
            try {
                const mold = modalInfo.coupon_molds.filter(mold => mold.mold_key.includes('push_allow'))[0];
                const res = await Axios.post('v1/coupons/download', {mold_key: mold.mold_key});
                if (res.status < 400) {
                    receivedCouponMoldIdsRef.current.push(mold.id);
                    toast.info(t('MainCouponModal.couponDownloadSuccess'));
                    setPseudoState(val => val + 1)
                    // onSuccess([mold.id]);
                    // customConfirm(
                    //     '쿠폰이 발급되었습니다! 쿠폰함으로 가시곘어요?', '확인', '취소', () => {
                    //         router.push(couponsRoute)
                    //     }, () => {
                    //     },
                    // );
                    // handleHideForDay();
                } else if (res.status === 401) {
                    customConfirm(t('MainCouponModal.memberOnlyCoupon'), t('MainCouponModal.confirm'), t('MainCouponModal.cancel'), () => {
                        redirectToLogin(router, true);
                    }, () => {
                    })
                } else {
                    captureMessage(JSON.stringify(res.data));
                    customAlert(res.data.display_message || t('MainCouponModal.couponIssueFailed'), t('MainCouponModal.confirm'), () => {
                    });
                }
            } catch (e) {
                captureException(e);
                customAlert(t('MainCouponModal.couponIssueFailed'), t('MainCouponModal.confirm'), () => {
                });
            }
        }

        // 푸시를 동의했으면 쿠폰을 준다
        if (isPushOn && isPushButtonPressed) {
            typeof mixpanel !== 'undefined' && mixpanel.track(
                'PushAgreementModalPushAgreed',
                {
                    identifier: modalInfo.identifier,
                }
            );
            downloadCoupon();
        }
    }, [pushContext.isDevicePushOn, pushContext.isMarketingAgreed, isPushButtonPressed]);

    useEffect(() => {
        setIsPushButtonPressed(false);
    }, [router.asPath]);

    const customModalIdentifiers = ['20231026_app_install_modal_point'];

    const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);

    useEffect(() => {
        setIsCustomModalOpen(true);
    }, []);

    if (customModalIdentifiers.includes(modalInfo.identifier)) {
        return (
            <BottomModal preventBackgroundClose isOpen={isCustomModalOpen} close={() => {
                setIsCustomModalOpen(false)
            }}>
                <AppPointModal
                    close={() => {
                        typeof mixpanel !== 'undefined' && mixpanel.track(
                            'MainCouponModalClose',
                            {
                                identifier: modalInfo.identifier,
                            }
                        );
                        setIsCustomModalOpen(false);
                    }}
                    pseudoState={pseudoState}
                    modalInfo={modalInfo}
                    setIsPushButtonPressed={setIsPushButtonPressed}
                    downloadCoupon={downloadCoupon}/>
            </BottomModal>
        );
    }

    console.log('modalInfo', modalInfo);

    const getModalContent = () => {
        if (modalInfo.modal_type === 'HTML') {
            return <MainPageModalHtmlType modalInfo={modalInfo} handleButtonClick={handleButtonClick}/>;
        }
        if (modalInfo.modal_type === 'COUPON') {
            return <MainCouponModalCouponTypeContent
                modalInfo={modalInfo} handleCloseClick={handleCloseClick}
                receivedCouponMoldIdsRef={receivedCouponMoldIdsRef} onSuccess={onSuccess}
            />;
        }
        if (['IMAGE', 'BRAND_ONE_DAY_COUPON'].includes(modalInfo.modal_type)) {
            return <MainCouponModalCouponTypeImage
                modalInfo={modalInfo}
                handleCloseClick={handleCloseClick}
                receivedCouponMoldIdsRef={receivedCouponMoldIdsRef}
                onSuccess={onSuccess}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                close={close}
            />;
        }
    }


    return (
        <Modal width={'calc(100% - 32px)'} isOpen={true} backgroundColor={'rgba(0,0,0,0)'}
               close={() => {
               }}
               modalBackgroundColor={'rgba(0, 0, 0, 0.5)'}
        >
            <div
                className={classNames(styles.root, modalInfo.modal_type !== 'COUPON' ? styles.rootHtml : undefined)}>
                {getModalContent()}
            </div>
            <div className={styles.hideContainer} ref={hideRef}>
                <div className={utilStyles.cursorPointer} onClick={handleHideForDay}>
                    {t('MainCouponModal.hideForToday')}
                </div>
                {
                    modalInfo.should_show_hide_button &&
                    <>
                        <PipeIcon viewBox="0 0 16 16" className={styles.pipeIcon}/>
                        <div className={utilStyles.cursorPointer} onClick={handleCloseClick}>
                            {t('MainCouponModal.close')}
                        </div>
                    </>
                }
            </div>

        </Modal>
    )
};

export default MainCouponModal;
