import {confirmAlert} from "react-confirm-alert";
import styles from "common/newCustomConfirm.module.scss";


export const newCustomConfirm = (title, description, confirmText, cancelText, onConfirm, onCancel) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            const runAndClose = (fn) => {
                return () => {
                    onClose();
                    fn();
                };
            }
            return <div className={styles.body}>
                <div className={styles.title}><span>{title}</span></div>
                {
                    description &&
                    <div className={styles.description}><span>{description}</span></div>
                }
                <div className={styles.buttonWrapper}>
                    {
                        cancelText &&
                        <div className={styles.cancelButton} onClick={runAndClose(onCancel)}><span>{cancelText}</span></div>
                    }
                    <div className={styles.confirmButton} onClick={runAndClose(onConfirm)}><span>{confirmText}</span></div>
                </div>
            </div>
        }
    });
};

export const newCustomAlert = (message, description, confirmText, onConfirm) => {
    newCustomConfirm(message, description, confirmText, null, onConfirm, () => {});
};
