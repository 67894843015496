import styles from 'components/modal.module.scss';
import classNames from "classnames";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import {isIOS} from "common/utils";
import {v4} from "uuid";
import {BottomSheet} from "react-spring-bottom-sheet";
import 'react-spring-bottom-sheet/dist/style.css'

export default function Modal (props) {
    const {
        children, isSheet, scrollRef, width, maxWidth, maxHeight, isOpen, header, backgroundColor, footer, unclosable,
        close, bottom, left, height, round, bodyRef, showOverflow, toParent, zIndex, noBackgroundBlur, extraClassName,
        modalBackgroundColor, borderRadius
    } = props;
    const [scrollId, setScrollId] = useState(v4());
    const snapPoints = props.snapPoints || function ({maxHeight}) {return [maxHeight * 0.8]};

    return (
        <>
            {
                isSheet ?
                    <BottomSheet
                        ref={scrollRef}
                        blocking={true}
                        initialFocusRef={false}
                        scrollLocking={false}
                        snapPoints={snapPoints}
                        header={header}
                        footer={footer}
                        className={classNames(styles.bottomSheetContainer, extraClassName)}
                        onDismiss={close}
                        open={isOpen} expandOnContentDrag={true}>
                        <div onClick={e => e.stopPropagation()}>
                            {children}
                        </div>
                    </BottomSheet>
                    :
                    <div
                        className={classNames(
                            styles.container, !!bottom ? styles.bottom: undefined, !!left ? styles.left: undefined,
                            noBackgroundBlur && styles.noBackgroundBlur,
                        )}
                        onClick={e =>{
                            if (!unclosable) {
                                close();
                            }
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        style={{
                            visibility: !isOpen ? 'hidden' : 'visible', zIndex: zIndex,
                            backgroundColor: modalBackgroundColor ? modalBackgroundColor : undefined
                        }}
                    >
                        <div
                            id={scrollId}
                            ref={bodyRef}
                            style={{
                                width, height, marginLeft: isOpen || !left ? 0 : (-width || 0),
                                transition: 'margin-left 0.2s ease', maxWidth, maxHeight, backgroundColor: backgroundColor ? backgroundColor : undefined,
                                borderRadius: borderRadius,
                        }}
                            className={
                                classNames(
                                    styles.contentContainer, showOverflow ? styles.showOverflow : undefined,
                                    !!bottom ? styles.topRound : undefined, !!left ? styles.noRound : undefined,
                                    round ? styles.round : undefined,
                                )
                            }
                            onClick={e => e.stopPropagation()}>
                            {children}
                        </div>
                    </div>
            }
            {
                // isOpen && <ScrollPreventer allowScrollId={scrollId} />
            }
        </>
    )
}
