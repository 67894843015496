import classNames from "classnames";
import utilStyles from "styles/utils.module.scss";
import styles from "components/bulletText.module.scss";


const BulletText = ({children, fontSize, fontColor, lineHeight, fontWeight, bulletMargin, bottomMargin}) => {
  return (
      <div className={classNames(utilStyles.flexRow)} style={{marginBottom: bottomMargin}}>
          <span className={styles.bulletText} style={{padding: bulletMargin !== undefined ? `0 ${bulletMargin}px` : undefined, color: fontColor}}>•</span>
          <span className={styles.text} style={{fontSize: fontSize, color: fontColor, lineHeight: lineHeight, fontWeight}}>
              {children}
          </span>
      </div>
  )
}

export default BulletText;
