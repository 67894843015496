import {createContext, useContext, useEffect, useMemo, useRef, useState} from "react";
import {isApp, isIOS, postRNMessage} from "common/utils";
import {
    MESSAGE_TYPE_DEVICE_UID_RESULT,
    MESSAGE_TYPE_PUSH_PERMISSION_RESULT,
    MESSAGE_TYPE_REQUEST_PUSH_PERMISSION_RESULT,
    MESSAGE_TYPE_FOREGROUND,
    redirectToLogin,
    REVERSE_MESSAGE_TYPE_GET_DEVICE_UID,
    REVERSE_MESSAGE_TYPE_HAS_PUSH_PERMISSION,
    REVERSE_MESSAGE_TYPE_OPEN_SETTINGS,
    REVERSE_MESSAGE_TYPE_REQUEST_PUSH_PERMISSION,
    REVERSE_MESSAGE_TYPE_GET_USER_AGENT,
    MESSAGE_TYPE_USER_AGENT_RESULT,
    REVERSE_MESSAGE_TYPE_REQUEST_PUSH_PERMISSION_ANDROID,
    MESSAGE_TYPE_REQUEST_PUSH_PERMISSION_ANDROID_RESULT,
} from "pages/_app";
import {v4} from "uuid";
import styles from "pages/_app.module.scss";
import FullButton from "components/buttons/FullButton";
import Modal from "components/Modal";
import React from "react";
import {Axios} from "api";
import {captureException, captureMessage} from "@sentry/nextjs";
import {toast} from "react-toastify";
import UserContext from "context/AuthContext";
import {useRouter} from "next/router";
import {getHomeRoute} from "common/const";


const PushContext = createContext(undefined);

export default PushContext;

const LOCAL_STORAGE_KEY_PUSH_AGREEMENT_SET = 'push_agreement_set';


export const PushContextProvider = ({children}) => {
    const callbackDictRef = useRef({});
    const user = useContext(UserContext).user;

    const [isMarketingAgreed, setIsMarketingAgreed] = useState(false);
    const [isDevicePushOn, setIsDevicePushOn] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [isPushModalOpen, setIsPushModalOpen] = useState(false);

    const messageInitializedRef = useRef(false);
    const hasPushPermission = (callback) => {
        const callbackId = v4();
        callbackDictRef.current[callbackId] = callback;
        postRNMessage({
            type: REVERSE_MESSAGE_TYPE_HAS_PUSH_PERMISSION,
            key: callbackId,
        })
    }

    const requestPushPermission = (callback) => {
        const callbackId = v4();
        callbackDictRef.current[callbackId] = callback;
        postRNMessage({
            type: REVERSE_MESSAGE_TYPE_REQUEST_PUSH_PERMISSION,
            key: callbackId,
        })
    }

    const requestPushPermissionAndroid = (callback) => {
        const callbackId = v4();
        callbackDictRef.current[callbackId] = callback;
        postRNMessage({
            type: REVERSE_MESSAGE_TYPE_REQUEST_PUSH_PERMISSION_ANDROID,
            key: callbackId,
        })
    }

    const getDeviceUid = (callback) => {
        const callbackId = v4();
        callbackDictRef.current[callbackId] = callback;
        postRNMessage({
            type: REVERSE_MESSAGE_TYPE_GET_DEVICE_UID,
            key: callbackId,
        })
    }

    const getUserAgent = (callback) => {
        const callbackId = v4();
        callbackDictRef.current[callbackId] = callback;
        postRNMessage({
            type: REVERSE_MESSAGE_TYPE_GET_USER_AGENT,
            key: callbackId,
        })
    }

    const registerPushToken = (token) => {
        getDeviceUid(device_uid => {
            getUserAgent(async user_agent => {
                try {
                    const res = await Axios.post('v1/push/tokens', {
                        token: token,
                        device_uid: device_uid,
                        user_agent: user_agent,
                        platform: window.RNPlatform,
                    });
                    if (res.status >= 400) {
                        captureMessage(JSON.stringify(res.data));
                    }
                } catch (e) {
                    captureException(e);
                }
            });
        });
    }

    useEffect(() => {
        if (user === undefined) return;
        hasPushPermission(enabled => {
            if (enabled > 0) {
                setIsDevicePushOn(true);
                requestPushPermission((enabled, token) => {
                    if (enabled > 0) {
                        setIsDevicePushOn(true);
                        registerPushToken(token);
                    }
                });
            } else {
                setIsDevicePushOn(false);
            }
        });
    }, [user])

    const sendPushAgreementRequest = (bool) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'PushAgreementRequested',
            {
                hasAgreed: bool,
            }
        )
        getDeviceUid(async device_uid => {
            try {
                if (isLoading) return;
                setIsLoading(true);
                const res = await Axios.post('v1/push/agreement', {
                    device_uid: device_uid,
                    agreed: bool,
                });
                if (res.status < 400) {
                    if (typeof window === 'undefined') return;
                    if (bool) {
                        setIsMarketingAgreed(true);
                        const now = new Date().toJSON().slice(0, 10);
                        typeof mixpanel !== 'undefined' && mixpanel.track(
                            'PushAgreementAgreed',
                        );
                        toast.info(`푸시 알림 수신 동의가 정상적으로 처리되었습니다. (처리일시: ${now})`);
                        hasPushPermission(enabled => {
                            if (enabled === -1) {
                                setIsDevicePushOn(false);
                                setIsPushModalOpen(false);
                                requestPushPermission((enabled, token) => {
                                    if (enabled > 0) {
                                        setIsDevicePushOn(true);
                                        registerPushToken(token);
                                    }
                                });
                            } else if (enabled === 0) {
                                setIsDevicePushOn(false);
                                setIsPushModalOpen(false);
                                // TODO: Android / IOS 분기
                                if (isIOS()) {
                                    const result = confirm('기기의 알림이 꺼져있어요. 알림 설정을 위해 휴대폰 설정창을 여시겠어요?');
                                    if (result) {
                                        postRNMessage({type: REVERSE_MESSAGE_TYPE_OPEN_SETTINGS});
                                    }
                                } else {
                                    requestPushPermissionAndroid((enabled, token) => {
                                        if (enabled) {
                                            setIsDevicePushOn(true);
                                            registerPushToken(token);
                                        }
                                    });
                                }
                            } else {
                                setIsDevicePushOn(true);
                                setIsPushModalOpen(false);
                            }
                        });
                    } else {
                        setIsMarketingAgreed(false);
                        setIsPushModalOpen(false);
                        const now = new Date().toJSON().slice(0, 10);
                        typeof mixpanel !== 'undefined' && mixpanel.track(
                            'PushAgreementDisagreed',
                        );
                        toast.info(`푸시 알림 수신 거부가 성공적으로 처리되었습니다. (처리일시: ${now})`);
                    }
                    window.localStorage.setItem(LOCAL_STORAGE_KEY_PUSH_AGREEMENT_SET, 'true');
                } else {
                    setIsPushModalOpen(false);
                    toast.info('푸시 수신 여부 처리에 오류가 발생했습니다.');
                    captureMessage(JSON.stringify(res.data));
                }
            } catch (e) {
                setIsPushModalOpen(false);
                toast.info('푸시 수신 여부 처리에 오류가 발생했습니다.');
                captureException(e);
            } finally {
                setIsLoading(false);
            }
        })
    }

    const onRNMessage = (messageEvent) => {
        let message = null;
        try {
            message = JSON.parse(messageEvent.data);
        } catch (e) {
            return;
        }

        if (message.type === MESSAGE_TYPE_PUSH_PERMISSION_RESULT) {
            const data = message.data;
            const callback = callbackDictRef.current[data.key] || function () {};
            const result = data.enabled;
            callback(result);
            if (callbackDictRef.current[data.key]) {
                delete callbackDictRef.current[data.key];
            }
        } else if (message.type === MESSAGE_TYPE_REQUEST_PUSH_PERMISSION_RESULT) {
            const data = message.data;
            const callback = callbackDictRef.current[data.key] || function () {};
            const enabled = data.enabled;
            const token = data.token;
            callback(enabled, token);
            if (callbackDictRef.current[data.key]) {
                delete callbackDictRef.current[data.key];
            }
        } else if (message.type === MESSAGE_TYPE_REQUEST_PUSH_PERMISSION_ANDROID_RESULT) {
            const data = message.data;
            const callback = callbackDictRef.current[data.key] || function () {};
            const enabled = data.enabled;
            const token = data.token;
            callback(enabled, token);
            if (callbackDictRef.current[data.key]) {
                delete callbackDictRef.current[data.key];
            }
        } else if (message.type === MESSAGE_TYPE_DEVICE_UID_RESULT) {
            const data = message.data;
            const callback = callbackDictRef.current[data.key] || function () {};
            const result = data.device_uid;
            callback(result);
            if (callbackDictRef.current[data.key]) {
                delete callbackDictRef.current[data.key];
            }
        } else if (message.type === MESSAGE_TYPE_USER_AGENT_RESULT) {
            const data = message.data;
            const callback = callbackDictRef.current[data.key] || function () {};
            const result = data.user_agent;
            callback(result);
            if (callbackDictRef.current[data.key]) {
                delete callbackDictRef.current[data.key];
            }
        } else if (message.type === MESSAGE_TYPE_FOREGROUND) {
            hasPushPermission((enabled) => {
                if (enabled > 0) {
                    setIsDevicePushOn(true);
                    requestPushPermission((enabled, token) => {
                        if (enabled > 0) {
                            registerPushToken(token);
                        }
                    });
                } else {
                    setIsDevicePushOn(false);
                }
            });
        }
    }

    useEffect(() => {
        if (isApp()) {
            if (isIOS()) {
                window.addEventListener('message', onRNMessage);
                messageInitializedRef.current = true;
                return () => {
                    window.removeEventListener('message', onRNMessage);
                }
            } else {
                document.addEventListener('message', onRNMessage);
                messageInitializedRef.current = true;
                return () => {
                    document.removeEventListener('message', onRNMessage);
                }
            }
        }
    }, []);

    useEffect(() => {
        if (!messageInitializedRef.current) return
        getDeviceUid(device_uid => {
           hasPushPermission(async (enabled) => {
               if (enabled > 0) {
                   setIsDevicePushOn(true);
               } else {
                   setIsDevicePushOn(false);
               }
               if (isLoading) return;
               let pushAgreed = false;
               try {
                   setIsLoading(true);
                   const res = await Axios.get('v1/push/agreement', {params: {
                       device_uid: device_uid,
                   }});
                   if (res.status < 400) {
                       pushAgreed = true;
                       setIsMarketingAgreed(true);
                   } else {
                       setIsMarketingAgreed(false);
                   }
               } catch (e) {
                   captureException(e);
                   setIsMarketingAgreed(false);
               } finally {
                   setIsLoading(false);
               }

               if ((enabled < 1 || !pushAgreed) && !window.localStorage.getItem(LOCAL_STORAGE_KEY_PUSH_AGREEMENT_SET)) {
                   setIsPushModalOpen(true);
               }
           });
        });
    }, [messageInitializedRef.current]);

    const contextValue = {
        isLoading,
        sendPushAgreementRequest,
        hasPushPermission,
        isDevicePushOn,
        isMarketingAgreed,
    };
    const router = useRouter();

    return (
        <>
            {/*<Modal isOpen={isPushModalOpen && router.pathname === getHomeRoute()} close={() => {}} bottom width={'100%'}>*/}
            {/*    <div className={styles.pushModalContainer}>*/}
            {/*        <span className={styles.pushModalTitle}>푸시 알림 설정</span>*/}
            {/*        <span className={styles.pushModalContent}>알림 설정하고 특가정보, 이벤트, 신규 소식 등<br/>다양한 혜택을 빠르게 받아보세요</span>*/}
            {/*        <img src={'https://d1cnx04b8cgzcv.cloudfront.net/present_image_3d.png'} alt="할인 이미지" className={styles.pushModalImage} />*/}
            {/*        <div className={styles.pushModalButton}>*/}
            {/*            <FullButton onClick={() => sendPushAgreementRequest(true)} fontSize={16} height={50}>알림 받을래요</FullButton>*/}
            {/*        </div>*/}
            {/*        <span className={styles.pushModalSkip} onClick={() => sendPushAgreementRequest(false)}>나중에 할래요</span>*/}
            {/*    </div>*/}
            {/*</Modal>*/}
            <PushContext.Provider value={contextValue}>
                {children}
            </PushContext.Provider>
        </>
    )
}
