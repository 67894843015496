

export const getReferralInfo = (productId) => {
    if (typeof window === 'undefined') {
        return [null, null];
    }
    let referralId;
    let referralPostId = null;
    if (productId) {
        const localUserReferralId = window.sessionStorage.getItem(`ref_user_${productId}`);
        const localPostReferralId = window.sessionStorage.getItem(`ref_post_${productId}`);
        referralId = localUserReferralId ? localUserReferralId : window.sessionStorage.getItem('global_ref_user');
        referralPostId = localPostReferralId ? localPostReferralId : null;
    }
    else {
        referralId = window.sessionStorage.getItem('global_ref_user');
    }
    referralId = referralId ? referralId : null;
    referralPostId = referralPostId ? parseInt(referralPostId) : null;
    return [referralId, referralPostId];
}

export const setLocalReferralInfo = (productId, referralId, postId) => {
    if (typeof window !== 'undefined') {
        window.sessionStorage.setItem(`ref_user_${productId}`, referralId);
        window.sessionStorage.setItem(`ref_post_${productId}`, postId);
    }
}

export const setGlobalReferralInfo = (referralId) => {
    if (typeof window !== 'undefined') {
        window.sessionStorage.setItem(`global_ref_user`, referralId);
    }
}

export const removeLocalReferralInfo = (productId) => {
    if (typeof window !== 'undefined') {
        window.sessionStorage.removeItem(`ref_user_${productId}`);
        window.sessionStorage.removeItem(`ref_post_${productId}`);
    }
}
