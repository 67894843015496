import commonStyles from "components/sections/product/index.module.scss";
import utilStyles from "styles/utils.module.scss";
import styles from "components/sections/product/productAdditionalInfoSection.module.scss";
import ProductInfoTitle from "components/sections/product/ProductInfoTitle";
import {forwardRef, useEffect, useState} from "react";
import {numberWithComma} from "common/utils";
import useMediaQuery from "@material-ui/core/useMediaQuery";


const KeyValueCard = (props) => {
    return (
        <div className={styles.keyValueContainer}>
            <div className={styles.key}>{props.title}</div>
            <div className={styles.value}>{props.content}</div>
        </div>
    );
}


const ProductAdditionalInfoKeyValueSubSection = (props) => {
    const {keyValueStore, title} = props;
    return (
        <div className={styles.subSection}>
            <span className={styles.subSectionTitle}>{title}</span>
            <div className={utilStyles.borderUnderLine} />
            {
                Object.entries(keyValueStore).map(([key, value]) => {
                    return <KeyValueCard title={key} content={value} key={key} />
                })
            }
        </div>
    );
}


const ProductAdditionalInfoIndexSubSection = (props) => {
    const {list, title} = props;
    return (
        <div className={styles.subSection}>
            <span className={styles.subSectionTitle}>{title}</span>
            <div className={utilStyles.borderUnderLine} />
            <div className={styles.indexContainer}>
                {
                    list.map((e, i) => {
                        return <span key={i} className={styles.listElement}>{i + 1}. {e}</span>
                    })
                }
            </div>
        </div>
    );
}


const ProductAdditionalInfoSection = forwardRef((props, ref) => {
    const {product} = props;
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const [isFold, setIsFold] = useState(false);

    const onFoldClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailAdditionalInfoSectionFoldClick',
            {
                productId: product.id,
                productName: product.name,
                fold: !isFold,
            }
        );
    };

    return (
        <div ref={ref} className={commonStyles.infoSection}>
            <ProductInfoTitle title="구매정보" foldable={isMobile} isFold={isFold} setIsFold={setIsFold} onFoldClick={onFoldClick} />
            {
                (!isFold || !isMobile) &&
                <>
                    <ProductAdditionalInfoKeyValueSubSection
                        title="제품정보 제공고시"
                        keyValueStore={Object.assign({}, {"제품명": product.name}, product.detail_info)} />
                    <ProductAdditionalInfoKeyValueSubSection
                    title="배송"
                    keyValueStore={{
                    "배송": "업체 직접배송 (일반택배)",
                    "배송비": product.delivery_charge === 0 ? '무료배송' : numberWithComma(product.delivery_charge) + '원',
                    "도서산간 추가 배송비": '무료배송',
                     }} />
                    <ProductAdditionalInfoKeyValueSubSection
                    title="교환/환불"
                    keyValueStore={{
                    "반품배송비": `${numberWithComma(product.return_fee)}원`,
                    "교환배송비": `${numberWithComma(product.change_fee)}원`,
                    "보내실 곳": product.return_address,
                     }} />
                    <ProductAdditionalInfoIndexSubSection
                    title="반품/교환 사유에 따른 요청 가능 기간"
                    list={product.return_period}
                    />
                    <ProductAdditionalInfoIndexSubSection
                    title="반품/교환 불가능 사유"
                    list={product.return_refuse_reason}
                    />
                    <ProductAdditionalInfoKeyValueSubSection
                    title="판매자 정보"
                    keyValueStore={product.brand.brand_info} />
                </>
            }
        </div>
    );
});

export default ProductAdditionalInfoSection;
