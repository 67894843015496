import styles from "components/modal/productInquiryModal.module.scss";
import TitleBar from "components/layout/TitleBar";
import classNames from "classnames";
import utilStyles from "styles/utils.module.scss";
import {TabSelector} from "pages/mypage/faq";
import BulletText from "components/BulletText";
import TextInput from "components/input/TextInput";
import InputError from "components/input/InputError";
import CheckboxText from "components/input/CheckboxText";
import ImageInput from "components/input/ImageInput";
import ImageUploadPreview from "components/input/ImageUploadPreview";
import FullButton from "components/buttons/FullButton";
import Modal from "components/Modal";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useEffect, useRef, useState} from "react";
import {useFormik} from "formik";
import * as yup from "yup";
import {Axios} from "api";
import {captureException, captureMessage} from "@sentry/nextjs";

const INQUIRY_TYPE_DETAIL= 'detail';
const INQUIRY_TYPE_ORDER = 'order';
const INQUIRY_TYPE_DELIVERY = 'delivery';

const INQUIRY_TYPES = [
    {[INQUIRY_TYPE_DETAIL]: "상품상세"},
    {[INQUIRY_TYPE_ORDER]: "주문/결제"},
    {[INQUIRY_TYPE_DELIVERY]: "배송"}
];

const INQUIRY_CATEGORY = 'category';
const INQUIRY_TEXT = 'question';
const INQUIRY_IS_SECRET = 'is_secret';
const INQUIRY_IMAGES = 'images';
const INQUIRY_IMAGE_IDS = 'image_ids';


const ProductInquiryModal = (props) => {
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);

    const {pid, isModalOpen, setModalOpen, isReservation} = props;
    const onSuccess = props.onSuccess || function () {};

    const closeModal = () => {
        setModalOpen(false);
        resetForm();
    }

    useEffect(() => {
        console.log(modalRef);
        if (modalRef.current) {
            modalRef.current.scrollTop = 0;
        }
    }, [isModalOpen]);

    const [isLoading, setIsLoading] = useState(false);
    const [isImageLoading, setIsImageLoading] = useState(false);


    const modalRef = useRef(null);

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: {
            [INQUIRY_CATEGORY]: INQUIRY_TYPE_DETAIL,
            [INQUIRY_IS_SECRET]: false,
            [INQUIRY_TEXT]: '',
            [INQUIRY_IMAGES]: [],
        },
        validationSchema: yup.object({
            [INQUIRY_CATEGORY]: yup.string(),
            [INQUIRY_IS_SECRET]: yup.boolean(),
            [INQUIRY_TEXT]: yup.string().required('필수 입력 항목입니다.'),
        }),
        onSubmit: async values => {
            const imageIds = values[INQUIRY_IMAGES].map(e => e.id);
            const data = JSON.parse(JSON.stringify(values));
            data[INQUIRY_IMAGE_IDS] = imageIds;
            delete data[INQUIRY_IMAGES];
            try {
                setIsLoading(true);
                const res = await Axios.post(`v1/store/products/${pid}/inquiries/`, data);
                console.log(res)
                if (res.status < 400) {
                    closeModal();
                    if (modalRef.current) {
                        modalRef.current.scrollTop = 0;
                    }
                    onSuccess();
                } else {
                    alert(res.data.display_message || 'Q&A 등록에 실패했습니다. 잠시 후 다시 시도해주세요.');
                    if (! res.data.display_message) {
                        captureMessage(JSON.stringify(res.data));
                    }
                }
            } catch (e) {
                alert('Q&A 등록에 실패했습니다. 잠시 후 다시 시도해주세요.');
                captureException(e);
            } finally {
                setIsLoading(false);
            }
        }
    })

    const {values, setFieldValue, errors, touched, handleSubmit, setFieldTouched, resetForm} = formik;

    const uploadInquiryImage = async (file) => {
        const formData = new FormData();
        formData.append('image', file);

        try {
            setIsImageLoading(true);
            const res = await Axios.post('v1/product-inquiry-images/', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (res.status < 400) {
                setFieldValue(INQUIRY_IMAGES, [res.data]);
            } else {
                alert('사진 업로드에 실패했습니다. 잠시 후 다시 시도해주세요.');
            }
        } catch(e) {
            alert('사진 업로드에 실패했습니다. 잠시 후 다시 시도해주세요.');
            captureException(e);
        } finally {
            setIsImageLoading(false);
        }
    }

    const onDeleteInquiryImage = async (imageId) => {
        try {
            setIsImageLoading(true);
            await Axios.delete(`v1/product-inquiry-images/${imageId}/`);
        } catch (e) {
            captureException(e);
        } finally {
            setFieldValue(INQUIRY_IMAGES, []);
            setIsImageLoading(false);
        }
    }

    const userAgent = typeof navigator !== 'undefined' && navigator.userAgent ? navigator.userAgent : '';
    const hideImageInput = userAgent.includes("Instagram");

    const inquiryTypes = isReservation ? INQUIRY_TYPES.filter(e => !(INQUIRY_TYPE_DELIVERY in e)) : INQUIRY_TYPES;

    return (
        <Modal bodyRef={modalRef} width={isMobile ? '100%' : 644} height={isMobile ? '100%' : 800} isOpen={isModalOpen} close={closeModal}>
            <div className={styles.modalContainer}>
                <TitleBar title="제품 문의하기" isBack={false} isClose close={closeModal} />
                <div className={styles.modalSection}  style={{marginTop: 24}}>
                    <span className={styles.modalSectionTitle}>문의 유형</span>
                    <TabSelector colSize={inquiryTypes.length} options={inquiryTypes} value={values[INQUIRY_CATEGORY]} isActiveTabBold
                                 onChange={(e) => setFieldValue(INQUIRY_CATEGORY, e)}></TabSelector>
                    <div style={{height: 12}}/>
                    <div className={styles.bulletText}>
                        <BulletText>제품 구매 후의 교환/환불/취소/반품 관련 문의는{isMobile ? <br/> : " "}<span className={styles.bulletTextHighlight}>마이페이지 > 1:1 문의</span>를 통해 문의 바랍니다.</BulletText>
                    </div>
                    <div className={styles.bulletText}>
                        <BulletText>작성하신 문의는 <span className={styles.bulletTextHighlight}>마이페이지 > Q&A</span>에서 확인할 수 있습니다.</BulletText>
                    </div>
                    <BulletText>개인 정보 관련 내용은 비공개로 문의해 주시기 바랍니다.</BulletText>
                </div>
                <div className={styles.modalSection}>
                    <span className={styles.modalSectionTitle}>문의 내용</span>
                    <TextInput multiLine maxLength={1000} value={values[INQUIRY_TEXT]}
                               readonly={isLoading}
                               placeholder="문의 내용을 입력해주세요."
                               error={errors[INQUIRY_TEXT] && touched[INQUIRY_TEXT]}
                               onChange={e => setFieldValue(INQUIRY_TEXT, e)}
                               onBlur={() => setFieldTouched(INQUIRY_TEXT, true)} />
                    { errors[INQUIRY_TEXT] && touched[INQUIRY_TEXT] && <InputError message={errors[INQUIRY_TEXT] || errors[INQUIRY_TEXT]} /> }

                    <div className={styles.modalCheckboxTextWrapper}>
                        <CheckboxText isLoading={isLoading} checked={values[INQUIRY_IS_SECRET]}
                                      onClick={() => setFieldValue(INQUIRY_IS_SECRET, !values[INQUIRY_IS_SECRET])}>
                            비공개
                        </CheckboxText>
                    </div>
                </div>
                <div className={styles.modalSection} style={{marginBottom: 0}}>
                    <div className={classNames(styles.modalSectionTitle, utilStyles.noLineHeight)}>
                        <span className={classNames(utilStyles.verticalMiddle, utilStyles.fullLineHeight, utilStyles.inlineBlock)}>사진 첨부</span>
                        <span className={styles.modalSectionSubtitle}>(선택)</span>
                    </div>
                    <div>
                        <div className={styles.modalImageSelector}>
                            <ImageInput disabled={hideImageInput} isLoading={isImageLoading} onSelect={e => uploadInquiryImage(e)} />
                        </div>
                        {
                            values[INQUIRY_IMAGES].map(e => {
                                return (
                                    <div key={e.id} className={styles.modalImagePreview}>
                                        <ImageUploadPreview src={e.thumbnail_url} onDelete={() => {
                                            onDeleteInquiryImage(e.id);
                                        }}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={styles.modalImageSelectorBullet}>
                        {
                            hideImageInput ?
                                <BulletText></BulletText>
                                :
                                <BulletText>최대 1장, 5mb 이하의 이미지만 등록 가능합니다.</BulletText>
                        }

                    </div>
                </div>
                <div className={styles.modalSubmitButtonContainer}>
                    {
                        isMobile &&
                        <div className={styles.modalSubmitButtonWrapper}>
                            <FullButton disabled={isLoading} light height={48} fontSize={isMobile ? 16 : 18} title="취소" onClick={closeModal}/>
                        </div>
                    }
                    <div className={styles.modalSubmitButtonWrapper}>
                        <FullButton disabled={isLoading}  height={48} fontSize={isMobile ? 16 : 18} title="등록" onClick={handleSubmit}/>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ProductInquiryModal
