import {useEffect, useRef, useContext, useState} from "react";
import {getPixelValueFromString, getYoutubeIdFromUrl} from "common/utils";
import utilStyles from "styles/utils.module.scss";
import styles from "components/media/youtube.module.scss";
import YouTube from "react-youtube";
import Image from "next/image";
import {useInView} from "react-intersection-observer";
import ReactPlayer from "react-player";
import MediaContext from "context/MediaContext";
import YoutubeIcon from "images/icons/youtube.svg";


export function getYoutubeThumbnailLink (ylink) {
    const videoId = getYoutubeIdFromUrl(ylink);
    return `https://img.youtube.com/vi/${videoId}/0.jpg`;
}

export function YoutubeThumbnail(props) {
    const {item, sharpBorder, cover} = props;
    const height = props.height || `100%`;
    const heightNum = parseInt(height.split('%')[0]);
    const imageUrl = getYoutubeThumbnailLink(item.url) ;
    const overflow = heightNum > 100 ? (heightNum - 100) / 2 : 0;
    return (
        <>
            <div className={styles.wrapper} style={{paddingBottom: height, backgroundColor: "black"}}>
                <div className={styles.player}>
                    <Image unoptimized src={imageUrl} alt={item.text} layout="fill"
                           objectFit={cover ? "cover" : "contain"} style={{lineHeight: 0, borderRadius: sharpBorder ? 0 : 5}}/>
                </div>
            </div>
        </>
    )
}

export default function YoutubePlayer(props) {
    const {item, autoplay, isActive} = props;
    // const videoId = getYoutubeIdFromUrl(item.url);
    const height = props.height || 56.25;
    // let wait = false;

    const mediaContext = useContext(MediaContext);
    const videoRef = useRef(null);
    const onContentView = props.onContentView || function (id) {};

    const { ref, inView, entry } = useInView({
        threshold: 0,
        triggerOnce: false,
        rootMargin: '-45% 0px -45% 0px',
        // rootMargin: '0px 0px 0px 0px',
        fallbackInView: true,
    });


    const togglePlay = (play) => {
        if (play) {
            mediaContext.play(item.id);
        } else {
            mediaContext.pause(item.id);
        }
    }


    useEffect(() => {
        if (!inView || !isActive) {
            togglePlay(false);
        }
        if (inView && isActive) {
            onContentView(item);
        }
    }, [inView, isActive]);


    // useEffect(() => {
    //     const checkAndPause = () => {
    //         if (!youtubeRef.current) {
    //             return;
    //         }
    //         const rect = youtubeRef.current.getBoundingClientRect();
    //         if (rect.y + rect.height < getPixelValueFromString(utilStyles.topBarHeight) || rect.y > window.innerHeight) {
    //             if (playerRef.current) {
    //                 playerRef.current.pauseVideo();
    //             }
    //         }
    //     }
    //     const handleScroll = (e) => {
    //         if (!wait) {
    //             wait = true;
    //             checkAndPause();
    //             setTimeout(() => {
    //                 wait = false
    //             }, 100);
    //         }
    //     };
    //
    //     window.addEventListener('scroll', handleScroll, true);
    //
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll, true);
    //     }
    // }, []);

    // const youtubeRef = useRef(null);
    // const playerRef = useRef(null);

    const onClickPreview = () => {
        togglePlay(true);
    }

    return (
        <div className={styles.wrapper} style={{paddingBottom: `${height}%`}}>
            <div className={styles.player} ref={ref}>
                {/*<YouTube*/}
                {/*    videoId={videoId}*/}
                {/*    opts={{*/}
                {/*        height: '100%',*/}
                {/*        width: '100%',*/}
                {/*        playerVars: {*/}
                {/*            autoPlay: 0,*/}
                {/*        }*/}
                {/*    }}*/}
                {/*    onStateChange={(e) => {*/}
                {/*        if (e.data === 1 && playingVideos) { // PLAYING*/}
                {/*            playingVideos.push(e.target);*/}
                {/*            for (const vid of playingVideos) {*/}
                {/*                try {*/}
                {/*                    if (!vid.getVideoUrl().includes(videoId)) {*/}
                {/*                        vid.pauseVideo();*/}
                {/*                    }*/}
                {/*                } catch (e) {*/}
                {/*                }*/}
                {/*            }*/}
                {/*        }*/}
                {/*    }}*/}
                {/*    onReady={e => {*/}
                {/*        playerRef.current = e.target;*/}
                {/*        if (autoplay) {*/}
                {/*            e.target.playVideo();*/}
                {/*        }*/}
                {/*    }}*/}
                {/*/>*/}

                <ReactPlayer
                    ref={videoRef}
                    url={item.url}
                    light={!mediaContext.isPlaying(item.id)}
                    width={'100%'} height={`100%`} loop
                    onClickPreview={onClickPreview}
                    controls
                    // playsinline
                    playing={mediaContext.isPlaying(item.id)}
                    playIcon={<YoutubeIcon width="72" height="72" viewBox="0 0 24 24"/>}
                    volume={1}
                />
            </div>
        </div>
    )
}
