import Modal from "components/Modal";
import styles from "components/modal/SelfVerificationModal.module.scss";
import {useState} from "react";
import FullButton from "components/buttons/FullButton";
import {SelfVerificationButton} from "pages/mypage/my-info";


const SelfVerificationModal = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const {handleClose} = props;

    const handleModalClose = () => {
        handleClose();
    };

    return (
        <Modal showOverflow={false} isOpen={true} maxHeight={600} borderRadius={10}
               close={handleModalClose}
        >
            <div className={styles.root}>
                <span className={styles.title}>본인인증하고<br/>내 정산 내역 알림 받아보세요</span>
                <span className={styles.subtitle}>공유한 링크로 구매가 일어났을 때,<br/>본인인증한 휴대폰 번호로 알려드릴게요</span>
                <div className={styles.buttonContainer}>
                    <SelfVerificationButton isLoading={isLoading} setIsLoading={setIsLoading}>
                        <FullButton height={44} fontSize={15} title="본인인증 하기"/>
                    </SelfVerificationButton>
                    <FullButton title={'다음에 하기'} height={44} fontSize={15} onClick={handleModalClose} white/>
                </div>
            </div>
        </Modal>
    )
};

export default SelfVerificationModal;
