import {useEffect, useState} from "react";
import Script from "next/script";
import {useRouter} from "next/router";


const ScriptRunner = (props) => {
    const {script, conditionalProperty, scriptKey} = props;

    const [isRendered, setIsRendered] = useState(false);

    const router = useRouter();

    useEffect(() => {
        setTimeout(() => {
            setIsRendered(true);
        }, 1000);
    }, [router.pathname]);

    return (
        <>
            {
                isRendered && typeof window != 'undefined' && (!Boolean(conditionalProperty) || window[conditionalProperty]) &&
                <Script
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                        __html: `
                        var doRun = false;
                        var serializedRecentKeys = window.localStorage.getItem('recent_script_runner_keys');
                        var recentKeys = [];
                        try {
                            recentKeys = serializedRecentKeys ? JSON.parse(serializedRecentKeys) : [];
                        } catch (e) {
                            window.localStorage.removeItem('recent_script_runner_keys');
                        }
                        var scriptKey = ${scriptKey ? `'${scriptKey}'` : 'null'};
                        if (scriptKey) {
                            if (!recentKeys.includes(scriptKey)) {
                                recentKeys.push(scriptKey);
                                if (recentKeys.length > 20) {
                                    recentKeys.shift();
                                }
                                window.localStorage.setItem('recent_script_runner_keys', JSON.stringify(recentKeys));
                                doRun = true;
                            }
                        } else {
                            doRun = true;
                        }
                        if (doRun) {
                            ${script}
                        }
                        `
                    }}
                />
            }
        </>
    )

}

export default ScriptRunner;
