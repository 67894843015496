import styles from "components/buttons/newQuantitySelector.module.scss";
import InactiveCircleMinusIcon from "images/icons/circle_minus_inactive.svg";
import CircleMinusIcon from "images/icons/circle_minus.svg";
import CirclePlusIcon from "images/icons/circle_plus.svg";
import PlusIcon from "images/icons/plus.svg";
import MinusIcon from "images/icons/minus.svg";
import classNames from "classnames";
import {useEffect, useRef, useState} from "react";
import {parseNumInputString} from "common/utils";
import utilStyles from "styles/utils.module.scss";


export default function NewQuantitySelector(props) {
    const {count, setCount, onMinus, onPlus, fontSize, disabled, syncCountTrigger, newStyle} = props;

    const min = props.min || 1;
    const max = props.max || 999;

    const [inputValue, setInputValue] = useState(count);

    const inputRef = useRef(null);

    const onFocusOut = () => {
        if (inputValue < min) {
            setCount(min);
            setInputValue(min);
        }
        else {
            setCount(inputValue);
        }
    };

    useEffect(() => {
        setInputValue(count);
    }, [count, syncCountTrigger]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (inputRef.current) {
                inputRef.current.blur();
            }
        }
    };

    return (
        <div className={classNames(styles.buttonContainer, newStyle ? styles.newButtonContainer : undefined)}>
            <button
                disabled={disabled}
                className={classNames(styles.counterButton, newStyle ? styles.newCounterButton : undefined)}
                style={{marginRight: !newStyle ? 10 : undefined}}
                onClick={() => {
                    if (count > 1) {
                        onMinus();
                    }
                }}
            >
                {
                    newStyle ?
                        (
                            <MinusIcon viewBox="0 0 20 20" width={16} height={16} className={classNames(styles.svgNew, count <= 1 ? styles.svgNewInactive : undefined)}/>
                        )
                        :
                        (
                            count > 1
                                ?
                                <CircleMinusIcon viewBox="0 0 24 24" width={24} height={24} className={styles.svg}/>
                                :
                                <InactiveCircleMinusIcon viewBox="0 0 24 24" width={24} height={24} className={styles.svg}/>
                        )
                }
            </button>
            <input
                ref={inputRef}
                readOnly={disabled} className={classNames(styles.selectionCount, newStyle ? styles.newInput : undefined)}
                style={{fontSize}} value={inputValue}
                onChange={e => setInputValue(parseNumInputString(inputValue, e.target.value, min, max))}
                max={999}
                onBlur={onFocusOut} onKeyDown={handleKeyPress}
            />
            <button
                disabled={disabled}
                className={classNames(styles.counterButton, newStyle ? styles.newCounterButton : undefined)}
                style={{marginLeft: !newStyle ? 10 : undefined}}
                onClick={() => {
                    onPlus();
                }}
            >
                {
                    newStyle ?
                        <PlusIcon viewBox="0 0 20 20" width={16} height={16} className={styles.svgNew}/>
                        :
                        <CirclePlusIcon viewBox="0 0 24 24" width={24} height={24} className={styles.svg}/>
                }

            </button>
        </div>
    )
}
