import styles from "components/dropdown/optionSelectionDropDown.module.scss";
import {Fragment, useEffect, useMemo, useRef, useState} from "react";
import OptionSelectionDropDownSingle from "components/dropdown/OptionSelectionDropDownSingle";
import {v4} from "uuid";

export default function OptionSelectionDropDown(props) {
    const {
        basePrice, small, inactive, disableClick, displayOnSingle, product,
        isExpanded, allowSoldOut, isMultipleSelectNotAllowed, hidePrice, selectedOptions,
        isExtraOptionNotAllowed,
    } = props;
    const wrapperRef = useRef(null);
    const onClick = props.onClick || function() {};

    const [expandedGroupAndHierarchyInfo, setExpandedGroupAndHierarchyInfo] = useState({
        groupId: null,
        hierarchy: null,
    });

    const _options = props.options;
    const options = useMemo(() => {
        const groupLevelNameToPseudoOption = {};

        const getUniqueKey = (groupId, idx, optionSelectionHierarchy) => {
            const accName = optionSelectionHierarchy.slice(0, idx + 1).join(',');
            return [groupId, accName].join('_');
        }

        for (const option of _options) {
            const optionSelectionHierarchy = (
                option.option_selection_hierarchy && option.option_selection_hierarchy.length > 0 ?
                option.option_selection_hierarchy : [option.dropdown_product_option_name]
            );
            for (const idx in optionSelectionHierarchy) {
                const hierarchyElement = optionSelectionHierarchy[idx];
                const uniqueKey = getUniqueKey(option.option_group_id || 0, parseInt(idx), optionSelectionHierarchy);
                if (Object.keys(groupLevelNameToPseudoOption).includes(uniqueKey)) continue;
                const pseudoOption = JSON.parse(JSON.stringify(option));
                const prevIndex = parseInt(idx) - 1;
                let prevElement = null;
                if (prevIndex >= 0) {
                    prevElement = optionSelectionHierarchy[prevIndex];
                }
                pseudoOption.psuedo_level = idx;
                if (parseInt(idx) !== optionSelectionHierarchy.length - 1) {
                    pseudoOption.id = v4();
                    pseudoOption.is_sold_out = false;
                    pseudoOption.opens_at_timestamp = null;
                }
                pseudoOption.dropdown_product_option_name = hierarchyElement;
                groupLevelNameToPseudoOption[uniqueKey] = pseudoOption;
                if (prevElement) {
                    const prevKey = getUniqueKey(option.option_group_id || 0, prevIndex, optionSelectionHierarchy);
                    const prevPseudoOption = groupLevelNameToPseudoOption[prevKey];
                    pseudoOption.parent_product_option_id = prevPseudoOption.id;
                } else {
                    pseudoOption.parent_product_option_id = null;
                }
            }
        }
        return Object.values(groupLevelNameToPseudoOption).sort((a, b) => {
            return a.display_rank - b.display_rank
        });
    }, [_options]);

    const handleSetIsExpanded = (groupId, hierarchy, isExpanded) => {
        setExpandedGroupAndHierarchyInfo(
            isExpanded ? {
                groupId,
                hierarchy,
            } : {
                groupId: null,
                hierarchy: null,
            }
        );
    };

    useEffect(() => {
        setExpandedGroupAndHierarchyInfo({
            groupId: null,
            hierarchy: null,
        });
    }, [isExpanded]);

    const groupIdToOptionHierarchy = useMemo(() => {
        const groupIdToOptionDict = {};
        for (const option of options) {
            const groupId = option.option_group_id || 0;
            const optionDict = groupIdToOptionDict[groupId] || {};
            if (!optionDict[option.parent_product_option_id]) {
                optionDict[option.parent_product_option_id] = [];
            }
            optionDict[option.parent_product_option_id].push(option);
            groupIdToOptionDict[groupId] = optionDict;
        }
        for (const option of [...options].sort((a, b) => {
            return b.psuedo_level - a.psuedo_level;
        })) {
            const optionDict = groupIdToOptionDict[option.option_group_id || 0] || {};
            if (!!optionDict[option.id] && optionDict[option.id].filter(child => !child.is_sold_out).length === 0) {
                option.is_sold_out = true;
            }
            if (!!optionDict[option.id] && optionDict[option.id].filter(child => !child.opens_at_timestamp).length === 0) {
                option.opens_at_timestamp = Math.min(...optionDict[option.id].map(child => child.opens_at_timestamp));
            }
        }
        return groupIdToOptionDict;
    }, [options]);

    const [groupIdToLevelToSelectedOption, setGroupIdToLevelToSelectedOption] = useState({});

    const getOptions = (groupId, level, parentOption) => {
        if (level === 1) {
            return options.filter(e => e.parent_product_option_id === null && (groupId === 0 || e.option_group_id === groupId));
        }
        if (!Boolean(parentOption)) {
            return [];
        }
        if (!groupIdToOptionHierarchy[groupId]) {
            return [];
        }
        return groupIdToOptionHierarchy[groupId][parentOption.id] || [];
    };

    const optionIdToOption = useMemo(() => {
        const optionIdToOption = {};
        for (const option of options) {
            optionIdToOption[option.id] = option;
        }
        return optionIdToOption;
    }, [options]);

    const handleOptionSelect = (group, level, option) => {
        if (level === group.hierarchy_level) {
            if (!isMultipleSelectNotAllowed) {
                setGroupIdToLevelToSelectedOption({});
            }
            onClick(option);
        }
        if (level < group.hierarchy_level || isMultipleSelectNotAllowed) {
            setGroupIdToLevelToSelectedOption(prev => {
                const newLevelToSelectedOption = JSON.parse(JSON.stringify(prev));
                const groupLevelToSelectedOption = newLevelToSelectedOption[group.group_id] || {};
                groupLevelToSelectedOption[level] = option;
                for (let i = level + 1; i <= group.hierarchy_level; i++) {
                    groupLevelToSelectedOption[i] = null;
                }
                newLevelToSelectedOption[group.group_id] = groupLevelToSelectedOption;
                return newLevelToSelectedOption;
            });
        }
    };

    const mainOptionGroupList = useMemo(() => {
        return (product?.product_option_group_list && product?.product_option_group_list.length > 0)
            ? (
                product?.product_option_group_list.filter(e => !e.is_extra_group).sort((a, b) => (a.rank - b.rank)).concat({
                    group_id: 0,
                    name: '메인옵션',
                    is_extra_group: false,
                    rank: 0,
                    parent_group_id: null,
                    hierarchy_level: product?.product_option_hierarchy_level,
                    product_option_selector_title_list: product?.product_option_selector_title_list,
                })
            )
            : [
                {
                    group_id: 0,
                    name: '메인옵션',
                    is_extra_group: false,
                    rank: 0,
                    parent_group_id: null,
                    hierarchy_level: product?.product_option_hierarchy_level || 1,
                    product_option_selector_title_list: product?.product_option_selector_title_list || [],
                }
            ];
    }, [product]);

    const extraOptionGroupList = useMemo(() => {
        return isExtraOptionNotAllowed ? [] : (product?.product_option_group_list
            ? product?.product_option_group_list.filter(e => e.is_extra_group).sort((a, b) => (a.rank - b.rank))
            : []);
    }, [product]);

    const groupIdToDependencyResolved = useMemo(() => {
        const groupIdToDependencyResolved = {};
        if (!product) {
            return {};
        }
        for (const group of product?.product_option_group_list) {
            groupIdToDependencyResolved[group.group_id] = !Boolean(group.parent_group_id) || Object.keys(selectedOptions).reduce((prev, key) => {
                return prev || optionIdToOption[key].option_group_id === group.parent_group_id;
            }, false);
        }
        return groupIdToDependencyResolved;
    }, [selectedOptions, product]);

    return (
        <div ref={wrapperRef} className={styles.root}>
            {
                mainOptionGroupList.map((group, index) => {
                    if (options.filter(e => (e.option_group_id && e.option_group_id === group.group_id) || (!e.option_group_id && !group.group_id)).length === 0) {
                        return <Fragment key={`main-option-group-${group.id}`} />;
                    }
                    return (
                        <Fragment key={`main-option-group-${group.id}`}>
                            {
                                Array.from({length: group.hierarchy_level}, (_, i) => i + 1).map(
                                    (hierarchy) => {
                                        const levelToSelectedOption = groupIdToLevelToSelectedOption[group.group_id] || {};
                                        return <OptionSelectionDropDownSingle
                                            {...props}
                                            key={`main-option-group-${group.id}-${hierarchy}`}
                                            title={levelToSelectedOption[hierarchy] ? levelToSelectedOption[hierarchy].dropdown_product_option_name : group.product_option_selector_title_list[hierarchy - 1]}
                                            displayOnSingle={displayOnSingle || group.hierarchy_level > 1}
                                            options={getOptions(group.group_id, hierarchy, levelToSelectedOption[hierarchy - 1])}
                                            onClick={(option) => handleOptionSelect(group, hierarchy, option)}
                                            hierarchy={hierarchy}
                                            isExpanded={expandedGroupAndHierarchyInfo.groupId === group.group_id && expandedGroupAndHierarchyInfo.hierarchy === hierarchy}
                                            setIsExpanded={(prev) => handleSetIsExpanded(group.group_id, hierarchy, prev)}
                                            hidePrice={hidePrice || (hierarchy !== group.hierarchy_level)}
                                        />
                                    }
                                )
                            }
                        </Fragment>
                    );
                })
            }
            {
                !isExtraOptionNotAllowed && extraOptionGroupList.length > 0 &&
                <div className={styles.extraOptionSection}>
                    <span className={styles.extraOptionText}>추가 제품 선택</span>
                    {
                        extraOptionGroupList.map((group, index) => {
                            return (
                                <Fragment key={`option-sub-group-${group.id}`}>
                                    {
                                        Array.from({length: group.hierarchy_level}, (_, i) => i + 1).map(
                                            (hierarchy) => {
                                                const levelToSelectedOption = groupIdToLevelToSelectedOption[group.group_id] || {};
                                                console.log('levelToSelectedOption', levelToSelectedOption, group.group_id, getOptions(group.group_id, hierarchy, levelToSelectedOption[hierarchy - 1]));
                                                return <OptionSelectionDropDownSingle
                                                    {...props}
                                                    title={levelToSelectedOption[hierarchy] ? levelToSelectedOption[hierarchy].dropdown_product_option_name : group.product_option_selector_title_list[hierarchy - 1]}
                                                    displayOnSingle={true}
                                                    options={getOptions(group.group_id, hierarchy, levelToSelectedOption[hierarchy - 1])}
                                                    onClick={(option) => handleOptionSelect(group, hierarchy, option)}
                                                    hierarchy={hierarchy}
                                                    isExpanded={expandedGroupAndHierarchyInfo.groupId === group.group_id && expandedGroupAndHierarchyInfo.hierarchy === hierarchy}
                                                    setIsExpanded={(prev) => handleSetIsExpanded(group.group_id, hierarchy, prev)}
                                                    hidePrice={hidePrice || (hierarchy !== group.hierarchy_level)}
                                                    disabled={!groupIdToDependencyResolved[group.group_id]}
                                                />
                                            }
                                        )
                                    }
                                </Fragment>
                            );
                        })
                    }
                </div>
            }
        </div>
    );
}
