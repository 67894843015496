import utilStyles from "styles/utils.module.scss";
import styles from "components/input/directPurchaseModal.module.scss";
import classNames from "classnames";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Axios} from "api";
import {captureException, captureMessage} from "@sentry/nextjs";
import {numberWithComma} from "common/utils";
import FullButton from "components/buttons/FullButton";
import {useRouter} from "next/router";
import NewQuantitySelector from "components/buttons/NewQuantitySelector";
import UserContext from "context/AuthContext";
import {redirectToLogin} from "common/redirect";
import {toast} from "react-toastify";
import moment from "moment";
import SaleIcon from "images/icons/sale.svg";
import LoadingDot from "components/LoadingDot";
import addOptionCountsToPouch from "api/addToPouch";
import PouchContext from "context/PouchContext";
import {order} from "api/store/orders";


const OptionText = (props) => {
    return <span className={styles.optionText}>옵션 : {props.name}</span>
}


export default function DirectPurchaseModal(props) {
    const {option, referralInfo, optionShortDetail} = props;
    const isSoldOut = option.is_sold_out;
    const openPouchAddedModal = props.openPouchAddedModal || function () {
    };
    const close = props.close || function () {
    };
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(1);
    const router = useRouter();
    const user = useContext(UserContext).user;
    const [seconds, setSeconds] = useState(0);
    const timerRef = useRef();

    useEffect(() => {
        initialize();
    }, []);

    const initialize = async () => {
        const seconds = optionShortDetail.opens_at_timestamp ? optionShortDetail.opens_at_timestamp - (Date.now() / 1000) : 0;
        if (seconds > 0) {
            setSeconds(seconds);
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
            timerRef.current = setInterval(() => {
                setSeconds(seconds => seconds > 0 ? seconds - 1 : 0);
            }, 1000);
        }
    };

    const [isPriceLoading, setIsPriceLoading] = useState(false);

    const [priceInfo, setPriceInfo] = useState({
        total_price: 0,
        total_discounted_price: 0,
        option_id_to_discount_info: {},
    });

    const isCountModifiedRef = useRef(false);

    useEffect(() => {
        if (isCountModifiedRef.current) {
            fetchPriceInfo();
        }
        isCountModifiedRef.current = true;
    }, [count]);

    const fetchPriceInfo = async () => {
        try {
            setIsPriceLoading(true);
            const [referralId, referralPostId] = referralInfo;
            const option_count_list = [{
                option_id: option.id,
                count: count,
                referral_id: referralId,
            }];
            const res = await Axios.post('v1/store/price/', {
                light_option_count_with_seller_list: option_count_list
            });
            if (res.status < 400) {
                setPriceInfo(res.data);
            } else {
                captureMessage(JSON.stringify(res.data));
            }
        } catch (e) {
            captureException(e);
            return null;
        } finally {
            setIsPriceLoading(false);
        }
    };

    const isMembersOnlyButNotLogined = useMemo(() => {
        if (optionShortDetail) {
            return optionShortDetail.is_members_only && user === null;
        }
        return false;
    }, [optionShortDetail, user]);

    const hasReachedMaxBuyCount = useMemo(() => {
        if (optionShortDetail) {
            return optionShortDetail.max_product_buy_count > 0 && optionShortDetail.max_product_buy_count <= optionShortDetail.current_product_buy_count;
        }
        return false;
    }, [optionShortDetail]);

    const purchaseButtonText = useMemo(() => {
        if (hasReachedMaxBuyCount) {
            return '구매불가';
        }
        if (isSoldOut) {
            return '품절';
        }
        if (isMembersOnlyButNotLogined) {
            return '회원 전용 제품';
        }
        if (seconds > 0) {
            return moment.utc(seconds * 1000).format('HH:mm:ss');
        }
        return '구매하기';
    }, [hasReachedMaxBuyCount, isSoldOut, isMembersOnlyButNotLogined, seconds]);

    const rejectCheckout = () => {
        if (hasReachedMaxBuyCount) {
            toast.error(`${optionShortDetail.max_product_buy_count}개까지만 구매 가능한 제품이에요.`);
        }
        if (isSoldOut) {
            toast.error('품절된 제품이에요.');
        }
        if (isMembersOnlyButNotLogined) {
            redirectToLogin(router, true);
        }
    };

    const checkOut = async () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'DirectPurchaseModalPurchaseButtonClick',
        );
        const [referralId, referralPostId] = referralInfo;
        const data = {
            checkout_items: [{
                option_id: option.id,
                count: count,
                referral_id: referralId,
                referral_post_id: referralPostId,
                reservation_time: null,
            }]
        };

        setIsLoading(true);
        try {
            const res = await order(data);
            if (res.status < 400) {
                const ordNum = res.data.order_number;
                close();
                router.push(`/store/orders/${ordNum}/checkout`);
            } else {
                captureMessage(JSON.stringify(res.data));
                alert('일시적인 오류로 주문 생성에 실패했습니다. 잠시 후 다시 시도해주세요.');
            }
            console.log(res);
        } catch (e) {
            captureException(e);
            alert('일시적인 오류로 주문 생성에 실패했습니다. 잠시 후 다시 시도해주세요.');
        } finally {
            setIsLoading(false);
        }
    };

    const addOptionCount = (count) => {
        setCount(oldValue => {
            if (!optionShortDetail.max_product_buy_count || oldValue + count + optionShortDetail.current_product_buy_count <= optionShortDetail.max_product_buy_count) {
                return oldValue + count;
            }
            toast.info(`최대 ${optionShortDetail.max_product_buy_count}개까지 구매 가능합니다.`);
            return oldValue;
        });
    };

    const price = numberWithComma(priceInfo.total_discounted_price || option.discounted_price * count);

    return (
        <>
            <div className={styles.resultContainer}>
                {
                    optionShortDetail && optionShortDetail.purchase_info_announcement &&
                    <div className={styles.purchaseInfoContainer}>
                        <SaleIcon/>
                        <span>{optionShortDetail.purchase_info_announcement}</span>
                    </div>
                }
                <div className={styles.bodyContainer}>
                    <div className={styles.result} key={option.id}>
                        <OptionText name={option.name}/>
                        <div
                            className={styles.selectorContainer}
                            style={{
                                alignItems: 'center', marginTop: 8
                            }}
                        >
                            <div className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter)}>
                                <NewQuantitySelector
                                    count={count}
                                    fontSize={14}
                                    onPlus={() => addOptionCount(1)}
                                    onMinus={() => addOptionCount(-1)}
                                    setCount={setCount}
                                />
                                <div style={{width: 12}}/>
                            </div>
                            <div>
                                {
                                    isSoldOut ?
                                        <span className={styles.soldOutText}>품절</span>
                                        :
                                        isPriceLoading
                                            ? <LoadingDot/>
                                            :
                                            <>
                                                <span className={styles.price}>{price}</span>
                                                <span className={styles.won}>원</span>
                                            </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={classNames(styles.buyButtonWrapper)}>
                        <FullButton
                            light={isSoldOut || isMembersOnlyButNotLogined || hasReachedMaxBuyCount || seconds > 0}
                            disabled={isPriceLoading}
                            onClick={
                                isLoading || isSoldOut || isMembersOnlyButNotLogined || hasReachedMaxBuyCount || seconds > 0
                                    ? rejectCheckout : checkOut
                            }
                            fontSize={18}
                            title={purchaseButtonText}
                            height={48}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
