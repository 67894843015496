import styles from "components/paginator.module.scss";
import utilStyles from "styles/utils.module.scss";
import classNames from "classnames";

import ArrowLeftIcon from "images/icons/arrow_left_757575.svg";
import ArrowRightIcon from "images/icons/arrow_right_757575.svg";

export default function Paginator(props) {
    const {totalCount, countPerPage, isLoading} = props;
    const size = props.size || 10;
    const currentPage = props.currentPage || 1;

    const onChangeCurrentPage = props.onChangeCurrentPage || function () {};

    const startIdx = size * Math.floor((currentPage - 1) / size) + 1;

    const lastIdx = Math.ceil(totalCount / countPerPage);
    const endIdx = size * (Math.floor((currentPage - 1) / size) + 1);

    const realLastIdx = Math.min(lastIdx, endIdx);

    const renderPages = [];
    for (let i=startIdx; i <= realLastIdx; i++) {
        renderPages.push(i);
    }

    return (
        <div className={styles.container} style={props.style}>
            <ArrowLeftIcon
                viewBox="0 0 16 16" width="16" height="16" style={{marginRight: 18}} className={startIdx === 1 ? utilStyles.invisible : undefined}
                onClick={() => {!isLoading && onChangeCurrentPage(startIdx -1)}}
            />
            {
                renderPages.map(e => {
                   return <a
                       onClick={() => !isLoading && onChangeCurrentPage(e)}
                       className={classNames(styles.index, e === currentPage ? styles.active : undefined)} key={e}>{e}</a>
                })
            }
            <ArrowRightIcon
                viewBox="0 0 12 12" width="16" height="16" style={{marginLeft: 18}} className={endIdx < lastIdx ? undefined : utilStyles.invisible}
                onClick={() => {!isLoading && onChangeCurrentPage(endIdx + 1)}}
            />
        </div>
    )
}