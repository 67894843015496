import styles from "components/cards/productDetailCard.module.scss";
import DeliveryIcon from "images/icons/delivery_colored.svg";
import {useContext} from "react";
import ModalContext from "context/ModalContext";
import {isApp} from "common/utils";
import classNames from "classnames";


const ProductDetailNotice = (props) => {
    const {showAppInstallNotice, showMyPrice} = props;

    const {openAppInstallModal, openCouponModalByIdentifier} = useContext(ModalContext);
    const isAppUser = isApp();


    const handleAppInstallClick = () => {
        if (!showAppInstallNotice) {
            return;
        }
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailNoticeClick', {notice: showAppInstallNotice}
        );

        if (isAppUser) {
            openCouponModalByIdentifier('app_install_modal_point');
        } else {
            openAppInstallModal();
        }
    };

    const appInstallNoticeText = isAppUser ?
        <>바로 쓸 수 있는 보너스 포인트 받기</> :
        <>앱에서 바로 쓸 수 있는 <span className={styles.freeDeliveryInfoTextEmph}>1,000P</span> 받기</>;

    return (
        <div
            className={classNames(styles.freeDeliveryInfo, showMyPrice && styles.freeDeliveryInfoMyPrice)}
            onClick={handleAppInstallClick}
        >
            {
                showAppInstallNotice ?
                    <>
                    <span className={styles.freeDeliveryInfoText}>
                        {appInstallNoticeText}
                    </span>
                    </> :
                    <>
                        <DeliveryIcon viewBox="0 0 16 16"/>
                        <span className={styles.freeDeliveryInfoText} style={{marginLeft: 10}}>
                            헤메코랩은 <span className={styles.freeDeliveryInfoTextEmph}>하나만 사도 무료배송!</span>
                    </span>
                    </>
            }
        </div>
    )
};

export default ProductDetailNotice;
