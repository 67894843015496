import styles from "components/modal/IntegratedReservationModal.module.scss";
import {useMemo} from "react";
import classnames from "classnames";


const TextButton = (props) => {
    const {text, onSelect, isSelected, isSoldOut} = props;

    return (
        <button
            className={classnames(styles.locationButton, styles.button, isSelected && styles.selectedButton, isSoldOut && styles.soldOutButton)}
            onClick={onSelect}>
            <span className={styles.textWrapper}>{text}</span>
        </button>
    )
};

export const IntegratedReservationModalLocationSelector = (props) => {
    const {product, selection, handleProductSelect} = props;

    const locationToProducts = useMemo(() => {
        const locationToProducts = {};
        product.shop_locations.forEach(location => {
            if (location.parent_id) {
                return;
            }
            locationToProducts[location.id] = {location: location, products: []};
        });
        product.child_reservation_products.forEach(childProduct => {
            locationToProducts[childProduct.location_id].products.push(childProduct);
        });
        return locationToProducts;
    }, [product]);

    return (
        <>
            <h2 className={styles.sectionTitleContainer}>지점을 선택해주세요</h2>
            <div className={styles.locationSelectorContainer}>
                {
                    Object.values(locationToProducts).map((locationProduct, index) => {
                        return (
                            <div key={`reservation-location-${locationProduct.location.id}`} className={styles.locationSelectorSection}>
                                <div className={styles.locationName}>
                                    {locationProduct.location.name}
                                </div>
                                <div className={styles.locationSelectorGrid}>
                                    {
                                        locationProduct.products.map((product, index) => {
                                            return (
                                                <TextButton
                                                    key={`reservation-location-product-${product.id}`}
                                                    text={product.alias_name}
                                                    onSelect={() => handleProductSelect(product)}
                                                    isSelected={selection.product && selection.product.id === product.id}
                                                    isSoldOut={product.is_sold_out}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
};
