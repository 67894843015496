import classNames from "classnames";
import utilStyles from "styles/utils.module.scss";
import styles from "components/input/checkboxText.module.scss";
import CheckedIcon from "images/icons/checkbox_checked.svg";
import UncheckedIcon from "images/icons/checkbox_unchecked.svg";


const CheckboxText = ({children, checked, onClick, isLoading}) => {
    return (
      <div className={styles.container} onClick={() => {
          if (isLoading) {
              return;
          }
          onClick()
      }}>
          {
              checked ? <CheckedIcon width={24} height={24} viewBox="0 0 24 24" className={styles.icon} />
                  : <UncheckedIcon width={24} height={24} viewBox="0 0 24 24" className={styles.icon} />
          }
          <div className={styles.text}>
              {children}
          </div>
      </div>
    )
}

export default CheckboxText;