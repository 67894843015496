import {createContext, useContext, useEffect, useState} from "react";


const PostScrapContext = createContext(undefined);

export let postScrapContextRef = {};

export default PostScrapContext;

const PostScrapContextWrapper = ({children}) => {
    const context = useContext(PostScrapContext);

    useEffect(() => {
        postScrapContextRef.current = context;
    }, []);

    useEffect(() => {
        postScrapContextRef.current = context;
    }, [context]);

    return(
        <>
            {children}
        </>
    )
}

// TODO: Inspect why render is called twice on context change
export const PostScrapContextProvider = ({children}) => {
    const [scrapPostIds, setScrapPostIds] = useState(new Set());
    const [loadingPostIds, setLoadingPostIds] = useState(new Set());

    const isScrapped = (postId) => {
        return scrapPostIds.has(postId);
    }

    const addPostIds = (postIds) => {
        if (!postIds) {
            return
        }
        setScrapPostIds(oldSet => {
            return new Set([...oldSet, ...postIds]);
        });
    }

    const removePostId = (postId) => {
        setScrapPostIds(oldSet => {
            const newSet = new Set(oldSet);
            newSet.delete(postId);
            return newSet;
        });
    }

    const updatePostIds = (postIdStatusMap) => {
        setScrapPostIds(oldSet => {
            const newSet = new Set(oldSet);
            Object.entries(postIdStatusMap).map(([postId, status]) => {
                if (status) {
                    newSet.add(parseInt(postId));
                } else {
                    newSet.delete(parseInt(postId));
                }
            });
            return newSet;
        });
    }

    const updateByPostIdsAndScrappedPostIds = (postIds, scrappedIds) => {
        const scrappedIdsSet = new Set(scrappedIds);

        const unscrappedIds = postIds.filter(x => !scrappedIdsSet.has(x));

        setScrapPostIds(oldSet => {
            const newSet = new Set(oldSet);
            for (const _id of scrappedIds) {
                newSet.add(parseInt(_id));
            }
            for (const _id of unscrappedIds) {
                newSet.delete(parseInt(_id));
            }
            return newSet;
        });
    }

    const setIsLoading = (postId, status) => {
        setLoadingPostIds(oldSet => {
            const newSet = new Set(oldSet);
            if (status) {
                newSet.add(postId);
            } else {
                newSet.delete(postId);
            }
            return newSet;
        });
    }

    const isLoading = (postId) => {
        return loadingPostIds.has(postId);
    }

    const contextValue = {
        isScrapped, addPostIds, removePostId, updatePostIds, isLoading, setIsLoading, updateByPostIdsAndScrappedPostIds,
    };

    return (
        <PostScrapContext.Provider value={contextValue}>
            <PostScrapContextWrapper>
                {children}
            </PostScrapContextWrapper>
        </PostScrapContext.Provider>
    )
}