import {useEffect, useState} from "react";

import styles from "components/foldableInfo.module.scss";
import utilStyles from "styles/utils.module.scss";
import classNames from "classnames";
import PlusIcon from "images/icons/plus.svg";
import MinusIcon from "images/icons/minus.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";


export default function FoldableInfo (props) {
    const [isExpanded, setIsExpanded] = useState(true);
    const Icon =  !isExpanded ? PlusIcon : MinusIcon;
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);

    useEffect(() => {
        if (!props.expanded && !isLarge && !isMobile) {
            setIsExpanded(false);
        }
    }, [isMobile, isLarge]);

    return (
        <div className={styles.container}  onClick={() => setIsExpanded(val => !val)}>
            <div className={styles.border} />
            <div className={classNames(utilStyles.justifyContentSpaceBetween, utilStyles.flexRow, styles.titleContainer)}>
                <span className={styles.title}>{props.title} :</span>
                <Icon className={utilStyles.cursorPointer} />
            </div>
            {
                <div className={classNames(styles.body, !(isExpanded && props.contents && props.contents.length > 0) && styles.fold)}>
                    {
                        props.contents.map((content, idx) => {
                            return (
                                <span className={styles.bodyRow} key={idx}>{`•`}<span>&nbsp;&nbsp;</span>{`${content}`}</span>
                            )
                        })
                    }
                </div>
            }
        </div>
    );
}


export function ExpandedInfo (props) {
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);

    return (
        <div className={styles.container} >
            {
                <div className={classNames(styles.body)}>
                    {
                        props.contents.map((content, idx) => {
                            return (
                                <div className={styles.rowWrapper} key={idx}>
                                    <span className={styles.bodyRow}>{`•`}<span>&nbsp;&nbsp;</span></span>
                                    <span className={styles.bodyRow}>{`${content}`}</span>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    );
}