
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import ErrorIcon from "images/icons/error.svg";
import styles from "pages/404.module.scss";
import NavigationLayout from "components/layout/NavigationLayout";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import utilStyles from "styles/utils.module.scss";
import useTranslation from "next-translate/useTranslation";
import parse from 'html-react-parser';
export const ErrorMessage = (props) => {
    const { padding, titleFontSize, descriptionFontSize, titleMargin, descriptionMargin } = props;
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const iconSize = props.iconSize || (isLarge ? 80 : 60);
    const { t } = useTranslation('common');
    return (<div className={styles.container} style={{ padding: padding }}>
            <ErrorIcon width={iconSize} height={iconSize} viewBox="0 0 80 80" className={styles.icon}/>
            <span className={styles.title} style={{ fontSize: titleFontSize, marginTop: titleMargin }}>
                {t('500.title')}
            </span>
            <span className={styles.description} style={{ fontSize: descriptionFontSize, marginTop: descriptionMargin }}>
                {parse(t('500.description'))}
            </span>
            {!isLarge && <div style={{ height: 70 }}/>}
        </div>);
};
const Custom500 = () => {
    return (<ErrorMessage padding={100}/>);
};
Custom500.getLayout = NavigationLayout;
export default Custom500;

    async function __Next_Translate__getStaticProps__1933462edba__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/500',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1933462edba__ as getStaticProps }
  