import Lottie from "lottie-react";
import loadingLottieJSON from "images/lottie/loading.json";
import utilStyles from "styles/utils.module.scss";
import styles from "components/loading.module.scss";
import classNames from "classnames";

export default function Loading (props) {
    return (
        <div
            className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter, utilStyles.justifyContentCenter)}
            style={props.style}
        >
            <Lottie className={styles.svg} animationData={loadingLottieJSON} autoplay={true} loop={true} />
        </div>
    );
}
