import {createContext, useContext, useEffect, useState} from "react";

const MediaContext = createContext(undefined);

export let mediaContextRef = {};

export default MediaContext;

const MediaContextWrapper = ({children}) => {
    const context = useContext(MediaContext);

    useEffect(() => {
        mediaContextRef.current = context;
    }, []);

    useEffect(() => {
        mediaContextRef.current = context;
    }, [context]);

    return(
        <>
            {children}
        </>
    )
}

export const MediaContextProvider = ({children}) => {
    const [playingMediaId, setPlayingMediaId] = useState(0);
    const [isMuted, setIsMuted] = useState(true);

    const isPlaying = (id) => {
        return playingMediaId === id;
    }

    const play = (id) => {
        // console.log('play', id, playingMediaId);
        setPlayingMediaId(x => {
            return id;
            // return x === 0 ? id : x;
        });
        // if (playingMediaId === 0) {
        //     return setPlayingMediaId(id);
        // }
    }

    const pause = (id) => {
        setPlayingMediaId(x => {
            return x === id ? 0 : x;
        });
    }

    const mute = () => {
        setIsMuted(true);
    };

    const unmute = () => {
        setIsMuted(false);
    }

    const isMute = () => {
        return isMuted;
    }

    const contextValue = {
        isPlaying, play, pause, mute, unmute, isMute
    };

    return (
        <MediaContext.Provider value={contextValue}>
            <MediaContextWrapper>
                {children}
            </MediaContextWrapper>
        </MediaContext.Provider>
    )
}
