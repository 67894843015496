import {createContext, useContext, useEffect, useState} from "react";
import Modal from "components/Modal";
import styles from "context/imageDetailContext.module.scss";

const ImageDetailContext = createContext(undefined);

export let imageDetailContextRef = {};

export default ImageDetailContext;

const ImageDetailContextWrapper = ({children}) => {
    const context = useContext(ImageDetailContext);

    useEffect(() => {
        imageDetailContextRef.current = context;
    }, []);

    useEffect(() => {
        imageDetailContextRef.current = context;
    }, [context]);

    return(
        <>
            {children}
        </>
    )
}

export const ImageDetailContextProvider = ({children}) => {
    const [modalImageUrl, setModalImageUrl] = useState(null);

    const closeModal = () => {
        setModalImageUrl(null);
    }

    const openDetailModal = (img) => {
        setModalImageUrl(img);
    }


    const contextValue = {
        openDetailModal
    };

    return (
        <ImageDetailContext.Provider value={contextValue}>
            <ImageDetailContextWrapper>
                <Modal isOpen={modalImageUrl !== null} close={closeModal} zIndex={1000001}>
                    {
                        modalImageUrl &&
                        <div className={styles.imageModal}>
                            <img src={modalImageUrl} alt={'image'} style={{}} />
                        </div>
                    }
                </Modal>
                {children}
            </ImageDetailContextWrapper>
        </ImageDetailContext.Provider>
    )
}