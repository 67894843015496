import {Axios} from "api/index";
import {trackPixel} from "api/pixel";
import {toast} from "react-toastify";


// options: list of {id, count}
const addOptionCountsToPouch = async (options, isAuthenticated, onSuccess, pouchContext, forceSendRequest = false, product_id= null, product_name= null) => {
    let doLocalFallback = false;
    const data = {
        content_ids: options.map(x => x.option_id),
    }
    trackPixel('AddToCart', data);
    const products = []
    for (const option of options) {
        products.push({
            id: product_id,
            name: product_name,
            quantity: option.count,
            price: option.price * option.count
        })
        const data = {
            option_id: !!option ? option.option_id : null, count: option.count,
            referral_id: option.referral_id, referral_post_id: option.referral_post_id
        };
        typeof mixpanel !== 'undefined' && mixpanel.track('AddToPouch', data);
    }
    try {
        typeof window !== 'undefined' && Boolean(window.karrotPixel) && window.karrotPixel.track('AddToCart', {
            'products' : products
        });
    } catch {}
    if (isAuthenticated || forceSendRequest) {
        try {
            const res = await Axios.post('v1/store/pouch/', {options});
            if (res.status < 400) {
                onSuccess();
            } else if (res.status === 401) {
                doLocalFallback = true;
            } else {
                toast.info(res.data.display_message || '오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
            }
        } catch (e) {
            alert('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
            console.log(e)
        }
    } else {
        doLocalFallback = true;
    }

    if (doLocalFallback) {
        pouchContext.addToLocalPouch(options);
        onSuccess();
    }
};

export default addOptionCountsToPouch;
