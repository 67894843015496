// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Integrations } from '@sentry/tracing';
import {VERSION, SERVICE_ENV} from "common/const";


const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://1d3ebe30a261437d899aa6a384ecf075@o1355869.ingest.sentry.io/6654818',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  attachStacktrace: true,
  integrations: [
      new Integrations.BrowserTracing(),
  ],
  release: VERSION,
  environment: SERVICE_ENV === 'prod' ? 'prod' : 'local',
  beforeSend: (event, hint) => {
    if (SERVICE_ENV !== 'prod') {
      if (hint.originalException || hint.syntheticException) {
        console.error(hint.originalException || hint.syntheticException);
      }
      return null; // this drops the event and nothing will be sent to sentry
    }
    return event;
  }
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
