import styles from "components/layout/palette/GridReviews.module.scss";
import classNames from "classnames";
import RollingImage from "components/images/RollingImage";


const GridReviews = (props) => {
    const {
        reviews, handleReviewClick, handleShowMoreClick
    } = props;

    const columnCount = props.columnCount || 3;

    const handleItemClick = (review, isShowMore) => {
        if (isShowMore) {
            handleShowMoreClick();
        } else {
            handleReviewClick(review);
        }
    };

    const itemCount = 6;

    return (
        <div className={classNames(styles.root, styles[`column_${columnCount}`])}>
            {
                reviews.slice(0, 6).filter(e => e.images.length > 0).map((review, index) => {
                    const isShowMore = index === (itemCount - 1) && reviews.length > itemCount;
                    return <div
                        key={`review-${review.id}`}
                        className={styles.imageWrapper}
                        onClick={() => handleItemClick(review, isShowMore)}
                    >
                        <RollingImage prefix={"images"} images={review.images.map(e => e.thumbnail_url)}/>
                        {
                            isShowMore &&
                            <div className={styles.showMore}>
                                <span>리뷰 더보기</span>
                                <span>+{reviews.length - itemCount}</span>
                            </div>
                        }
                    </div>
                })
            }
        </div>
    )
};

export default GridReviews;
