import styles from "components/review/starRating.module.scss";
import utilStyles from "styles/utils.module.scss";
import StarFilled from "images/icons/star_filled.svg";
import BrandStarFilled from "images/icons/star_brand_filled.svg";
import classNames from "classnames";


export default function StarRatingSmall(props) {
    const {fontSize, size, gap, noParenthesis, scoreTextClassname, color, brandStar} = props;
    const rate = props.rate || 0;
    const fixedRate = rate.toFixed(1);
    return (
        <div style={{display: 'inline-block', verticalAlign: 'middle'}} className={utilStyles.noLineHeight}>
            {
                !!brandStar ?
                    <BrandStarFilled className={styles.star} viewBox="0 0 24 24" style={{width: size, height: size}}/>
                    :
                    <StarFilled className={styles.star} viewBox="0 0 24 24" style={{width: size, height: size}}/>
            }
            <span className={classNames(styles.scoreText, scoreTextClassname)} style={{fontSize: fontSize, color: color, marginLeft: gap || undefined}}>
                {noParenthesis ? fixedRate : `(${fixedRate})`}
            </span>
        </div>
    )
};
