import classNames from "classnames";

import styles from 'components/layout/NavBar/navBar.module.scss';
import {useRouter} from "next/router";
import {useContext, useEffect, useState} from "react";
import ScrollContext from "context/ScrollContext";
import Link from "next/link";


const NavBarItem = ({category}) => {
    const router = useRouter();

    const [isActive, setIsActive] = useState(false);

    const onNavBarClick = (category) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('NavBarClick', { categoryName: category.korean_name });
    };

    const Icon = category.icon;

    useEffect(() => {
        setIsActive(
            category.getIsMobileActive ? category.getIsMobileActive(router) : category.getIsActive(router)
        )
    }, [router]);

    return (
        <Link href={category.route} scroll={false}>
            <a className={classNames(styles.navBarItem, !isActive ? styles.itemTextInactive : undefined)} onClick={() => onNavBarClick(category)}>
                <Icon className={classNames(styles.itemIcon, isActive ? styles.itemIconActive : styles.itemIconInactive)} />
                {category.korean_name}
            </a>
        </Link>
    );
}

export default function NavBar (props) {
    const {shouldHideNavBar} = useContext(ScrollContext);

    return (
        <div className={classNames(styles.mobileContainer, shouldHideNavBar ? styles.mobileContainerHidden : undefined)}>
            <ul className={styles.itemsContainer}>
                {
                    !!props.categories && props.categories.map((category, idx) => {
                        return (
                            <NavBarItem key={category.name} category={category} />
                        );
                    })
                }
            </ul>
        </div>
    )
}
