import styles from "components/cards/order/baseOrderOptionCard.module.scss";
import utilStyles from "styles/utils.module.scss";
import SpeechBubble from "components/SpeechBubble";
import Image from "next/image";

import PipeIcon from "images/icons/pipe.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {formattedPrice, getLocalStorageCountryCode, numberWithComma} from "common/utils";
import {getProductRoute, getReviewGroupRoute, reviewRoute} from "common/const";
import Link from "next/link";
import {useContext, useEffect, useMemo, useState} from "react";
import UserContext from "context/AuthContext";
import {Axios} from "api";
import Modal from "components/Modal";
import FullButton from "components/buttons/FullButton";
import CheckIcon from "images/icons/check_circle.svg";
import classNames from "classnames";
import addOptionCountsToPouch from "api/addToPouch";
import {useRouter} from "next/router";
import {captureException} from "@sentry/nextjs";
import {getReferralInfo, removeLocalReferralInfo} from "common/referral";
import PouchContext from "context/PouchContext";
import {toast} from "react-toastify";
import useTranslation from "next-translate/useTranslation";
import Trans from 'next-translate/Trans'


export const ActionButton = (props) => {
    const onClick = props.onClick || function () {};
    const {children} = props;
    return (
        <div className={styles.actionButton} onClick={(e) => {
            onClick();
        }}>
            {children}
        </div>
    )
}

export const AddToPouchButton = ({orderOption}) => {
    const {fetchUser} = useContext(UserContext);
    const pouchContext = useContext(PouchContext);
    const {t} = useTranslation('components-cards-BaseOrderOptionCard')

    const addToPouch = () => {
        const [referralId, referralPostId] = getReferralInfo();
        const options = [
            {
                option_id: orderOption.product_option_id,
                count: 1,
                referral_id: referralId,
                referral_post_id: referralPostId,
                price: orderOption.unit_sell_price
            }
        ];
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'OrderOptionCardAddToPouch'
        );

        addOptionCountsToPouch(options, false, () => {
            toast.info(t('BaseOrderOptionCard.message.productAdded'));
            fetchUser();
        }, pouchContext, true, orderOption.product_id, orderOption.product_name);
    }
    return (
        <a className={styles.buttonWrapper} onClick={addToPouch}>
            <ActionButton>{t('BaseOrderOptionCard.buttons.addCart')}</ActionButton>
        </a>
    )
}

export const WriteReviewButton = (props) => {
    const {productId, orderOption} = props;
    const router = useRouter();
    const {t} = useTranslation('components-cards-BaseOrderOptionCard')
    const onClick = orderOption.review_permission_group.permissions.length > 1 ? () => {
        router.push(getReviewGroupRoute(orderOption.review_permission_group.group_id));
    } : (props.onClick || function () {});
    const maxPoint = orderOption.review_permission_group.max_points;

    return (
        <a className={styles.buttonWrapper} onClick={onClick}>
            <ActionButton>{t('BaseOrderOptionCard.buttons.writeReview')}</ActionButton>
            <div className={styles.speechBubbleWrapper}>
                <SpeechBubble
                    sidePadding={6} verticalPadding={6}
                    pointLength={5}
                    bottom
                >
                    <div className={styles.bubbleContent}>
                        최대 {maxPoint}p
                    </div>
                </SpeechBubble>
            </div>
        </a>
    )
}

export const PurchaseConfirmButton = (props) => {
    const {orderNumber, optionId, pointsToGiveOnPurchaseConfirm} = props;
    const router = useRouter();
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [grantPoint, setGrantPoint] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const userContext = useContext(UserContext);
    const onPurchaseConfirmSuccess = props.onPurchaseConfirmSuccess || function () {};
    const [purchaseConfirmResult, setPurchaseConfirmResult] = useState(null);
    const countryCode = getLocalStorageCountryCode() ?? 'KR';
    const {t} = useTranslation('components-cards-BaseOrderOptionCard')

    const maxReviewPoints = useMemo(() => {
        let maxReviewPoints = 1000;
        if (purchaseConfirmResult?.max_review_points) {
            maxReviewPoints = purchaseConfirmResult.max_review_points;
        }
        if (countryCode === 'US') {
            maxReviewPoints = 100;
        }

        return numberWithComma(maxReviewPoints);
    }, [countryCode])

    const confirmPurchase = async () => {
        try {
            setIsLoading(true);
            const res = await Axios.post(`v1/store/orders/${orderNumber}/options/${optionId}/purchase-confirm`, {});
            if (res.status < 400) {
                userContext.setPoint(res.data.total_point);
                setGrantPoint(res.data.grant_point);
                setPurchaseConfirmResult(res.data);
                userContext.fetchUser();
            } else {
                console.log(res);
                alert(res.data.display_message || t('BaseOrderOptionCard.purchaseConfirm.error'));
            }
        } catch (e) {
            captureException(e);
            alert(t('BaseOrderOptionCard.purchaseConfirm.error'));
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (grantPoint && !isModalOpen) {
            onPurchaseConfirmSuccess();
        }
    }, [isModalOpen]);

    return (
        <a className={styles.buttonWrapper}>
            <Modal isOpen={isModalOpen} round close={() => setIsModalOpen(false)}>
                <div className={styles.purchaseConfirmModalContainer}>
                    {
                        grantPoint === undefined ?
                            <>
                                <span className={styles.modalTitle}>
                                    {
                                        pointsToGiveOnPurchaseConfirm > 0 ?
                                        <Trans
                                            i18nKey="components-cards-BaseOrderOptionCard:BaseOrderOptionCard.purchaseConfirm.pointsMessage"
                                            components={[<br/>, <span className={styles.modalPoint}/>]}
                                            values={{points: pointsToGiveOnPurchaseConfirm}}
                                        />
                                        :
                                        <>{t('BaseOrderOptionCard.purchaseConfirm.confirmQuestion')}</>
                                    }
                                </span>
                                <span className={styles.modalDescription}>
                                    <Trans
                                        i18nKey="components-cards-BaseOrderOptionCard:BaseOrderOptionCard.purchaseConfirm.warning"
                                        components={[<br/>]}
                                    />
                                </span>
                                <div className={styles.modalButtonWrapper}>
                                    <FullButton fontSize={16} title={t('BaseOrderOptionCard.purchaseConfirm.confirmButton')} height={44} onClick={confirmPurchase} />
                                </div>
                                <div className={styles.modalButtonWrapper}>
                                    <FullButton fontSize={16} white title={t('BaseOrderOptionCard.purchaseConfirm.cancelButton')} height={44} onClick={() => setIsModalOpen(false)} />
                                </div>
                            </>
                            :
                            <div>
                                <CheckIcon viewBox="0 0 32 32" width={isMobile ? 28 : 32} height={isMobile ? 28 : 32} />
                                {
                                    grantPoint > 0 &&
                                    <span className={styles.modalGrantPoint}>+{numberWithComma(grantPoint)}p</span>
                                }
                                <span className={styles.modalGrantDone}>{t('BaseOrderOptionCard.purchaseConfirm.completed')}</span>
                                <div className={classNames(styles.modalTotalPointContainer)}>
                                    <span>{t('BaseOrderOptionCard.purchaseConfirm.myPoints')}</span>
                                    <span className={classNames(styles.modalTotalPoint)}>{numberWithComma(userContext.user.point)}p</span>
                                </div>
                                <Link href={reviewRoute}>
                                    <a className={styles.reviewRewardContainer}>
                                        <span className={styles.reviewRewardText}>
                                            <Trans
                                                i18nKey="components-cards-BaseOrderOptionCard:BaseOrderOptionCard.purchaseConfirm.reviewInvite"
                                                components={[<br/>, <span className={styles.reviewRewardEmphasis}/>]}
                                                values={{
                                                    points: maxReviewPoints,
                                                }}
                                            />
                                        </span>
                                        <div className={styles.writeReviewButtonWrapper}>
                                            <FullButton useDiv fontSize={12} title={t('BaseOrderOptionCard.purchaseConfirm.writeReviewButton')} height={28} />
                                        </div>
                                    </a>
                                </Link>
                                <div className={styles.modalButtonWrapper}>
                                    <FullButton fontSize={16} title={t('BaseOrderOptionCard.purchaseConfirm.laterButton')} height={44} onClick={() => setIsModalOpen(false)} light />
                                </div>
                            </div>
                    }
                </div>
            </Modal>
            <ActionButton onClick={() => setIsModalOpen(true)}>{t('BaseOrderOptionCard.buttons.confirmPurchase')}</ActionButton>
            {
                !!pointsToGiveOnPurchaseConfirm &&
                <div className={styles.speechBubbleWrapper}>
                    <SpeechBubble
                        sidePadding={12} verticalPadding={6}
                        pointLength={5}
                        backgroundColor={'#FFA0CC'}
                        bottom
                    >
                        <div className={styles.bubbleContent}>
                            {t('BaseOrderOptionCard.purchaseConfirm.getPoints', { points: pointsToGiveOnPurchaseConfirm })}
                        </div>
                    </SpeechBubble>
                </div>
            }
        </a>
    )
}


export default function BaseOrderOptionCard (props) {
    const {
        count, brandName, productId, productName, optionName, price,
        status, imageUrl, children, disableLink, productOverrideLink, giftReceiverName, isReservation,
        reservationTime, currency
    } = props;
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const {t} = useTranslation('components-cards-BaseOrderOptionCard');
    const onProductClick = () => {
        removeLocalReferralInfo(productId);
    }

    const Wrapper = ({children}) => {
        return (
            disableLink
            ?
                <div className={styles.informationContainer}>
                    {children}
                </div>
            :
                <Link href={productOverrideLink ? productOverrideLink : getProductRoute(productId)}>
                    <a className={styles.informationContainer} onClick={onProductClick}>
                        {children}
                    </a>
                </Link>
        )
    }

    return (
        <div className={styles.container}>
            <div className={styles.infoSection}>
                <div className={styles.statusWrapper}>
                    <span className={styles.status}>{t('BaseOrderOptionCard.status.' + status)}</span>
                    {
                        giftReceiverName &&
                        <>
                            <PipeIcon/>
                            <div className={styles.giftReceiverName}>
                                TO. {giftReceiverName}
                            </div>
                        </>
                    }
                </div>
                <Wrapper>
                    <div className={styles.imageWrapper}>
                        <Image layout="fill" objectFit="cover" unoptimized src={imageUrl} />
                    </div>
                    <div className={styles.infoWrapper}>
                        <span className={styles.brand}>{brandName}</span>
                        <span className={styles.productName}>{productName}</span>
                        <div className={styles.optionInfoWrapper}>
                            <span className={styles.optionName}>{optionName}</span>
                            <>
                                <PipeIcon className={styles.pipeIcon}/>
                                <span className={styles.count}>
                                    {isReservation ? reservationTime : t('BaseOrderOptionCard.getCount', {count: count})}
                                </span>
                            </>
                        </div>
                        <span className={styles.price}>{formattedPrice(price, currency)}<span className={styles.won}>{t(`common:currency.${currency}`)}</span></span>
                    </div>
                </Wrapper>

            </div>
            {
                children &&
                <div className={styles.buttonSection}>
                    {children}
                </div>
            }
        </div>
    )
}
