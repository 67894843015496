import {getJsonLocalStorageByKey} from "common/utils";

export const DIRECT_PURCHASE_EXPIRATION_DAYS = 3;
export const DIRECT_PURCHASE_STORAGE_KEY = 'direct_purchase_expiration';

export const getProductIdToDirectPurchaseInfo = () => {
    return getJsonLocalStorageByKey(DIRECT_PURCHASE_STORAGE_KEY);
}

export const hasValidDirectPurchaseInfo = (productId) => {
    if (!productId) return false;

    const directPurchaseExpirationObj = getProductIdToDirectPurchaseInfo();

    if (parseInt(productId) in directPurchaseExpirationObj) {
        const expirationDate = new Date(directPurchaseExpirationObj[parseInt(productId)]);
        const currentDate = new Date();
        if (expirationDate > currentDate) {
            return true;
        }
    }

    return false;
}
