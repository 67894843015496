import Modal from "components/Modal";
import styles from "components/modal/ReservationModal.module.scss";
import TitleBar from "components/layout/TitleBar";
import FullButton from "components/buttons/FullButton";
import {useCallback, useContext, useState} from "react";
import classnames from "classnames";
import {toast} from "react-toastify";
import {Axios} from "api";
import {redirectToLogin} from "common/redirect";
import {captureException, captureMessage} from "@sentry/nextjs";
import UserContext from "context/AuthContext";
import {useRouter} from "next/router";
import {numberWithComma} from "common/utils";
import {order} from "api/store/orders";

export const ReservationModalMode = 'reservation';


const TextButton = (props) => {
    const {text, onSelect, isSelected, isSoldOut} = props;

    return (
        <button
            className={classnames(styles.button, isSelected && styles.selectedButton, isSoldOut && styles.soldOutButton)}
            onClick={onSelect}>
            <span>{text}</span>
        </button>
    )
};

const ReservationModalOptionCard = (props) => {
    const {option, isSelected, handleSelect, isSoldOut} = props;
    return (
        <button className={classnames(styles.optionCardContainer, isSelected && styles.selectedCardContainer)} onClick={handleSelect}>
            <div className={styles.optionCardName}>
                <div className={classnames(styles.optionCardDiscountRate, isSelected && styles.selectedCardText)}>{option.discount_rate}%</div>
                <div className={classnames(styles.optionCardName, isSelected && styles.selectedCardText)}>{option.name}</div>
            </div>
            <div className={styles.optionCardPriceInfo}>
                {
                    option.discounted_price !== option.price &&
                    <div className={classnames(styles.optionCardOriginalPrice, isSelected && styles.selectedCardText)}>{numberWithComma(option.price)}</div>
                }
                <div className={classnames(styles.optionCardDiscountedPrice, isSelected && styles.selectedCardText)}>{numberWithComma(option.discounted_price)}원</div>
            </div>
        </button>
    )
}

const ReservationModalOptionSelector = (props) => {
    const {availableTimes, selectedTime, product, selectedOption, handleOptionSelect} = props;

    return (
        <>
            <h2>옵션을 선택해주세요</h2>
            <div className={styles.optionListContainer}>
                {
                    product.options.filter((option, idx) => {
                        return availableTimes[selectedTime][2].includes(option.id);
                    }).map((option, index) => {
                        const isSoldOut = option.is_sold_out || option.inventory_count === 0;
                        return (
                            <ReservationModalOptionCard
                                key={`reservation-option-${option.id}`}
                                option={option}
                                handleSelect={() => handleOptionSelect(option)}
                                isSelected={selectedOption && selectedOption.id === option.id}
                                isSoldOut={isSoldOut}
                            />
                        )
                    })
                }
            </div>
        </>
    )
};

const ReservationModalTimeSelector = (props) => {
    const {availableTimes, product, selectedTime, handleTimeSelect} = props;

    return (
        <>
            <h2>예약할 시간을 선택해주세요</h2>
            <div className={styles.timeContainer}>
                {
                    Object.entries(availableTimes).map(([time, seatInfo], index) => {
                        return (
                            <div key={`reservation-${product.id}-${time}`} className={styles.timeButtonContainer}>
                                <TextButton
                                    text={time} onSelect={() => handleTimeSelect(time)}
                                    isSelected={selectedTime === time}
                                    isSoldOut={seatInfo[1] === 0}
                                />
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
};

const ReservationModal = (props) => {
    const {close, product} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTime, setSelectedTime] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const user = useContext(UserContext).user;
    const router = useRouter();

    const availableTimes = Object.assign({}, ...Object.entries(product.available_reservation).map(
        ([key, value]) => ({[key.split('T')[1]]: [key, value.initial_seats - value.reserved_seats, value.available_option_ids]}))
    );

    const checkout = useCallback(async () => {
        if (isLoading) {
            return;
        }
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailOptionSelectorPurchaseButtonClick',
            {
                productId: product.id,
                productName: product.name,
                isReservation: true,
            }
        );
        const option = selectedOption;
        if (!Boolean(option)) {
            toast.info('일시적인 오류로 주문 생성에 실패했습니다. 잠시 후 다시 시도해주세요.');
            return;
        }
        if (!Boolean(selectedTime) || !Boolean(availableTimes[selectedTime])) {
            toast.info('시간을 선택해주세요.');
            return;
        }
        const data = {
            checkout_items: [{
                option_id: parseInt(option.id),
                count: 1,
                reservation_time: availableTimes[selectedTime][0],
            }],
        };
        console.log('data', data);
        setIsLoading(true);
        try {
            const res = await order(data);
            if (res.status < 400) {
                const ordNum = res.data.order_number;
                const checkoutUrl = `/store/orders/${ordNum}/checkout`;
                if (user) {
                    router.push(checkoutUrl);
                } else {
                    redirectToLogin(router, true, undefined, checkoutUrl);
                }
            } else {
                captureMessage(JSON.stringify(res.data));
                toast.info(res.data.display_message || '일시적인 오류로 주문 생성에 실패했습니다. 잠시 후 시도해주세요.');
            }
        } catch (e) {
            captureException(e);
            toast.info('일시적인 오류로 주문 생성에 실패했습니다. 잠시 후 다시 시도해주세요.');
        } finally {
            setIsLoading(false);
        }
    }, [selectedTime, user, availableTimes]);

    const handleTimeSelect = (time) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReservationModalTimeSelect',
        );
        if (availableTimes[time][1] === 0) {
            toast.info('해당 시간은 예약이 마감되었습니다.');
            return;
        }
        setSelectedTime(prev => prev === time ? null : time);
    };

    const handleOptionSelect = (option) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReservationModalOptionSelect',
        );
        setSelectedOption(prev => prev === null || prev.id !== option.id ? option : null);
    };

    const handleConfirmButtonClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReservationModalConfirmButtonClick'
        );
        checkout();
    };

    const handleNextButtonClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReservationModalNextButtonClick'
        );
        setCurrentStep(2);
    };

    const [currentStep, setCurrentStep] = useState(1);

    const isDisabled = currentStep === 1 ? selectedTime === null : selectedOption === null;

    return (
        <Modal showOverflow={false} isOpen={true} bottom={false} width="100%" height="100%"
               close={close}
        >
            <div className={styles.root}>
                <div className={styles.titleContainer}>
                    <TitleBar title="헤메예약" isBack={true} close={close}/>
                </div>
                <div className={styles.content}>
                    {
                        currentStep === 1 ?
                            <ReservationModalTimeSelector
                                availableTimes={availableTimes} product={product}
                                selectedTime={selectedTime} handleTimeSelect={handleTimeSelect}
                            /> :
                            <ReservationModalOptionSelector
                                availableTimes={availableTimes} selectedTime={selectedTime} product={product}
                                selectedOption={selectedOption} handleOptionSelect={handleOptionSelect}
                            />
                    }
                </div>
                <div className={styles.buttonContainer}>
                    <FullButton
                        title={currentStep === 1 ? "다음" : "예약하기"}
                        height={48} fontSize={16}
                        disabled={isDisabled}
                        onClick={currentStep === 1 ? handleNextButtonClick : handleConfirmButtonClick}
                    />
                </div>
            </div>
        </Modal>
    )
};

export default ReservationModal;
