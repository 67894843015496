import classNames from "classnames";
import styles from "components/buttons/ReviewScrapButton.module.scss";
import UpvoteIcon from "images/icons/heart_fill.svg";
import NotUpvotedIcon from "images/icons/heart_shortcut.svg";
import {useContext, useRef} from "react";
import {Axios} from "api";
import * as Sentry from "@sentry/nextjs";
import ReviewScrapContext, {reviewScrapContextRef} from "context/ReviewScrapContext";
import {useRouter} from "next/router";
import UserContext from "context/AuthContext";
import {redirectToLogin} from "common/redirect";
import useTranslation from "next-translate/useTranslation";


const ReviewScrapButton = (props) => {
    const {review, onScrap, onUnscrap} = props;
    const reviewId = review ? review.id : null;
    const scrapContext = useContext(ReviewScrapContext);
    const {t} = useTranslation('components-buttons-ReviewScrapButton');
    const hasUpvoted = scrapContext.isScrapped(reviewId);

    const onUpvoteClick = props.onUpvoteClick || function () {
    };

    const requestAfterTimeoutRef = useRef(null);

    const sendRequest = async (isScrapped) => {
        try {
            reviewScrapContextRef.current.setIsLoading(reviewId, true);
            const res = isScrapped
                ? await Axios.post(`v1/store/reviews/${review.id}/upvote`)
                : await Axios.delete(`v1/store/reviews/${review.id}/upvote`);
            if (res.status < 400) {
            } else {
                // alert('스크랩 설정에 실패했습니다. 잠시 후 다시 시도해주세요.');
            }
        } catch (e) {
            // alert('스크랩 설정에 실패했습니다. 잠시 후 다시 시도해주세요.');
            Sentry.captureException(e);
        } finally {
            reviewScrapContextRef.current.setIsLoading(reviewId, false);
        }
    }

    const sendRequestIfNotLoading = async (isScrapped) => {
        if (!reviewScrapContextRef.current.isLoading(reviewId)) {
            sendRequest(isScrapped);
            requestAfterTimeoutRef.current = null;
        } else {
            requestAfterTimeoutRef.current = setTimeout(() => {
                sendRequestIfNotLoading(isScrapped);
            }, 200);
        }
    }

    const setRequest = (isScrapped) => {
        if (requestAfterTimeoutRef.current) {
            clearTimeout(requestAfterTimeoutRef.current);
        }
        requestAfterTimeoutRef.current = setTimeout(() => {
            sendRequestIfNotLoading(isScrapped);
        }, 200);
    }


    const scrap = () => {
        scrapContext.toggleScrap(reviewId);
        setRequest(true);
        if (onScrap !== undefined) {
            onScrap();
        }
    };

    const unscrap = () => {
        scrapContext.toggleScrap(reviewId);
        setRequest(false);
        if (onUnscrap !== undefined) {
            onUnscrap();
        }
    };

    const user = useContext(UserContext).user;
    const router = useRouter();
    const rootId = "review";

    const onClick = (e) => {
        const hasScrapped = scrapContext.isScrapped(reviewId);
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReviewCardVoteClick',
            {
                'hasScrapped': hasScrapped,
                'reviewId': reviewId,
            }
        );
        e.preventDefault();
        e.stopPropagation();

        onUpvoteClick(!hasScrapped);
        if (user) {
            hasScrapped ? unscrap() : scrap();
        } else {
            redirectToLogin(router, true, rootId);
        }
    };

    const upvoteCount = scrapContext.getUpvoteCount(reviewId);

    return (
        <div>
            <div
                className={classNames(styles.upvoteWrapper, hasUpvoted ? styles.hasUpvotedWrapper : undefined)}
                onClick={onClick}
            >
                {
                    hasUpvoted ?
                        <UpvoteIcon
                            viewBox="0 0 24 24"
                            className={classNames(styles.upvoteIcon)}
                        /> :
                        <NotUpvotedIcon
                            viewBox="0 0 25 25"
                            className={classNames(styles.upvoteIcon, styles.notUpvotedIcon)}
                        />
                }
                <span className={styles.upvoteText}>{t('helpful')}</span>
                <span>{upvoteCount}</span>
            </div>
        </div>
    )
};

export default ReviewScrapButton;
