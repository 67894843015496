import utilStyles from "styles/utils.module.scss";
import styles from "components/layout/store/purchase/SelectedOptionList.module.scss";
import classNames from "classnames";
import CloseIcon from "images/icons/close.svg";
import {checkHasConsonant, numberWithComma} from "common/utils";
import {useEffect, useMemo, useRef, useState} from "react";
import {Axios} from "api";
import {captureException, captureMessage} from "@sentry/nextjs";
import NewQuantitySelector from "components/buttons/NewQuantitySelector";
import {toast} from "react-toastify";
import LoadingDot from "components/LoadingDot";

export default function SelectedOptionList(props) {
    const {
        product, resetSelectedOptions, selectedOptions, addOptionCount,
        setOptionCount, deleteOptionCount, basePrice, small, buttonText, onAddToPouchSuccessOverride,
        mode,
    } = props;
    const referralInfo = props.referralInfo || [null, null];
    const options = product?.leaf_options || [];
    const optionIdToOption = {};

    const onOptionDelete = props.onOptionDelete || function () {
    };
    const onOptionCountChange = props.onOptionCountChange || function () {
    };

    options.map(e => {
        optionIdToOption[e.id] = e;
    })

    let isSoldOut = true;
    for (const option of options) {
        if (!option.is_sold_out) {
            isSoldOut = false;
        }
    }
    if (options.length === 0) {
        isSoldOut = false;
    }

    const numSelected = useMemo(() => {
        let num = 0;
        for (let option_id in selectedOptions) {
            num += selectedOptions[option_id];
        }
        return num;
    }, [selectedOptions]);

    const [isPriceLoading, setIsPriceLoading] = useState(false);

    const [priceInfo, setPriceInfo] = useState({
        total_price: 0,
        total_discounted_price: 0,
        option_id_to_discount_info: {},
    });

    const isCountModifiedRef = useRef(false);
    const lastSelectedOptionsRef = useRef(selectedOptions);

    useEffect(() => {
        if (isCountModifiedRef.current) {
            lastSelectedOptionsRef.current = selectedOptions;
            fetchPriceInfo();
        }
        isCountModifiedRef.current = true;
    }, [selectedOptions]);

    const fetchPriceInfo = async () => {
        const targetSelectedOptions = lastSelectedOptionsRef.current;
        try {
            setIsPriceLoading(true);
            const [referralId, referralPostId] = referralInfo;
            const option_count_list = [];
            for (let option_id in targetSelectedOptions) {
                const count = selectedOptions[option_id];
                option_count_list.push({
                    option_id: parseInt(option_id),
                    count: count,
                    referral_id: referralId,
                });
            }
            const res = await Axios.post('v1/store/price/', {
                light_option_count_with_seller_list: option_count_list
            });
            if (targetSelectedOptions !== lastSelectedOptionsRef.current) {
                return;
            }
            if (res.status < 400) {
                setPriceInfo(res.data);
            } else {
                captureMessage(JSON.stringify(res.data));
            }
        } catch (e) {
            captureException(e);
            return null;
        } finally {
            setIsPriceLoading(false);
        }
    };

    const hasReachedMaxBuyCount = product.max_buy_count > 0 && product.max_buy_count <= product.current_buy_count + numSelected;

    const maxBuyCountReachedOptionIds = useMemo(() => {
        for (let option_id in selectedOptions) {
            const option = optionIdToOption[parseInt(option_id)];
            // current_buy_count 대신 현재 선택한 옵션 개수만 보고 일단 처리
            if (option && option.max_buy_count > 0 && option.max_buy_count <= selectedOptions[option_id]) {
                return [parseInt(option_id)];
            }
        }
        return [];
    }, [selectedOptions]);

    const groupIdToGroup = useMemo(() => {
        const output = {};
        for (const group of product.product_option_group_list) {
            output[group.group_id] = group;
        }
        return output;
    }, [product]);

    useEffect(() => {
        if (Object.keys(groupIdToGroup).length === 0) {
            return;
        }
        const groupIdToOptionList = {};
        for (const selectedOptionId of Object.keys(selectedOptions)) {
            const selectedOption = optionIdToOption[selectedOptionId];
            if (selectedOption) {
                const groupId = selectedOption.option_group_id;
                const optionList = groupIdToOptionList[groupId] || [];
                optionList.push(selectedOption);
                groupIdToOptionList[groupId] = optionList;
            }
        }

        let isExtraOptionDeleted = false;

        for (const selectedOptionId of Object.keys(selectedOptions)) {
            const selectedOption = optionIdToOption[selectedOptionId];
            if (selectedOption) {
                const groupId = selectedOption.option_group_id;
                const group = groupId ? groupIdToGroup[groupId] : null;
                if (group && Boolean(group.parent_group_id)) {
                    const optionList = groupIdToOptionList[group.parent_group_id] || [];
                    if (optionList.length === 0) {
                        deleteOption(selectedOption);
                        isExtraOptionDeleted = true;
                    }
                }
            }
        }

        if (isExtraOptionDeleted) {
            toast.info('메인 옵션이 삭제돼서 추가 옵션이 삭제되었어요.');
        }
    }, [selectedOptions, optionIdToOption, groupIdToGroup]);

    const deleteOption = (option) => {
        onOptionDelete(option);
        deleteOptionCount(option.id);
    };

    const getOptionName = (option) => {
        if (props.getOptionName) {
            return props.getOptionName(option);
        }
        return `옵션 : ${option.name}`;
    };


    return (
        Object.keys(selectedOptions).length === 0 ?
            <div/> :
            <div>
                <div {...{'body-scroll-lock-ignore': "true"}} className={styles.resultContainer}>
                    {
                        Object.keys(selectedOptions).filter((key) => key in optionIdToOption).map((key, i) => {
                            const count = selectedOptions[key];
                            const option = optionIdToOption[key];
                            const optionPriceInfo = priceInfo.option_id_to_discount_info[option.id];
                            const discountRate = optionPriceInfo?.discount_rate || option.discount_rate;
                            return (
                                <div className={styles.result} key={option.id}>
                                    <div className={styles.optionNameRow}>
                                        <span className={styles.optionText}>
                                            {getOptionName(option)}
                                        </span>
                                        {
                                            options.length !== 1 &&
                                            <CloseIcon
                                                className={styles.floatingClose}
                                                viewBox="0 0 20 20"
                                                onClick={
                                                    () => {
                                                        deleteOption(option);
                                                    }
                                                }
                                            />
                                        }
                                    </div>
                                    <div className={styles.selectorContainer} style={{
                                        alignItems: 'center', marginTop: 12
                                    }}>
                                        <div
                                            className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter)}>
                                            <NewQuantitySelector
                                                count={optionPriceInfo?.count ?? count}
                                                fontSize={14}
                                                onPlus={() => {
                                                    onOptionCountChange(option, count + 1);
                                                    if (hasReachedMaxBuyCount) {
                                                        toast.info(`${product.max_buy_count}개까지만 구매 가능한 제품이에요.`);
                                                        return;
                                                    }
                                                    if (maxBuyCountReachedOptionIds.includes(option.id)) {
                                                        const maxReachedOption = optionIdToOption[maxBuyCountReachedOptionIds[0]];
                                                        toast.info(`${maxReachedOption.name}${checkHasConsonant(maxReachedOption.name) ? '은' : '는'} ${maxReachedOption.max_buy_count}개까지만 구매 가능한 옵션이에요.`);
                                                        return;
                                                    }
                                                    addOptionCount(option.id, 1);
                                                }}
                                                onMinus={() => {
                                                    onOptionCountChange(option, count - 1);
                                                    addOptionCount(option.id, -1);
                                                }}
                                                setCount={(val) => {
                                                    onOptionCountChange(option, val);
                                                    if (maxBuyCountReachedOptionIds.includes(option.id) && val > (selectedOptions[option.id] || 0)) {
                                                        const maxReachedOption = optionIdToOption[maxBuyCountReachedOptionIds[0]];
                                                        toast.info(`${maxReachedOption.name}${checkHasConsonant(maxReachedOption.name) ? '은' : '는'} ${maxReachedOption.max_buy_count}개까지만 구매 가능한 옵션이에요.`);
                                                        return;
                                                    }
                                                    setOptionCount(option.id, val);
                                                }}
                                            />
                                            <div style={{width: 12}}/>
                                        </div>
                                        <div>
                                            {
                                                isSoldOut ?
                                                    <span className={styles.soldOutText}>품절</span>
                                                    :
                                                    <>
                                                        {
                                                            isPriceLoading
                                                                ? <LoadingDot/>
                                                                :
                                                                <div className={styles.priceTextWrapper}>
                                                                    {
                                                                        Boolean(discountRate) && discountRate > 0 &&
                                                                        <span className={styles.discountRateText}>
                                                                                        {discountRate}%
                                                                                    </span>
                                                                    }
                                                                    <span className={styles.price}>
                                                                                    {numberWithComma((optionPriceInfo?.discounted_price ? optionPriceInfo?.discounted_price * optionPriceInfo.count : option.discounted_price * count))}
                                                                                </span>
                                                                    <span className={styles.won}>원</span>
                                                                </div>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                <div className={styles.bottomContainer}>
                    <div
                        className={classNames(utilStyles.flexRow, utilStyles.justifyContentSpaceBetween, utilStyles.alignItemsCenter)}>
                        <span className={styles.sum}>주문금액 합계 :</span>
                        <div className={styles.totalPriceContainer}>
                            {
                                isPriceLoading
                                    ? <LoadingDot/>
                                    :
                                    <div>
                                                <span className={classNames(styles.price, styles.totalPrice)}
                                                >
                                                    {
                                                        numberWithComma(priceInfo.total_discounted_price || Object.keys(selectedOptions).reduce(
                                                            (prev, curr) => prev + optionIdToOption[curr].discounted_price * selectedOptions[curr], 0
                                                        ))
                                                    }
                                                </span>
                                        <span className={classNames(styles.won, styles.totalWon)}>원</span>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
    );
}
