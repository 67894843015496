import {useEffect, useState} from "react";
import {arrayMoveImmutable} from "array-move";
import {Axios} from "api";
import {captureException, captureMessage} from "@sentry/nextjs";
import Modal from "components/Modal";
import styles from "components/modal/cropModal.module.scss";
import Loading from "components/Loading";
import utilStyles from "styles/utils.module.scss";
import classNames from "classnames";
import TitleBar from "components/layout/TitleBar";
import BlackCloseIcon from "images/icons/close_191919.svg";
import SortableList, {SortableItem} from "react-easy-sort";
import SpeechBubble from "components/SpeechBubble";
import Cropper from 'react-easy-crop';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';

export default function CropModal(props) {
    const {t} = useTranslation('modal-CropModal');
    const {close, isOpen, setResultImages, setImages, successRef, prevImagesRef, uploadUrl} = props;

    const images = props.images;

    const [_targetId, setTargetId] = useState(images[0].id);

    const targetImage = images.filter(e => e.id === _targetId).length > 0 ? images.filter(e => e.id === _targetId)[0] : images[0];
    const targetId = targetImage.id;

    const closeModal = () => {
        close()
    }

    useEffect(() => {
        if (isOpen) {
            if (prevImagesRef.current.length > 0) {
                const newImages = images.filter(e => {
                    return !prevImagesRef.current.map(el => el.id).includes(e.id);
                })
                if (newImages.length > 0) {
                    setTargetId(newImages[0].id)
                }
            }
        }
    }, [isOpen]);


    const [idToCropData, setIdToCropData] = useState({});

    const [isCropping, setIsCropping] = useState(false);

    const onSortEnd = (oldIndex, newIndex) => {
        setImages(arrayMoveImmutable(images, oldIndex, newIndex));
    }

    const [doneCount, setDoneCount] = useState(null);

    const isLoading = doneCount !== null;

    const onSubmit = () => {
        setDoneCount(0);
        const requests = [];
        for (const image of images) {
            const formData = new FormData();
            const cropData = getCropData(image.id).cropAreaPixel || {};

            formData.append('image', image.file);
            formData.append('width', cropData.width || '');
            formData.append('height', cropData.height || '');
            formData.append('x', cropData.x || '');
            formData.append('y', cropData.y || '');

            requests.push(
                Axios.post(uploadUrl, formData, {
                    timeout: 30000,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }).then(res => {
                    if (!(res.status < 400)) {
                        captureMessage(JSON.stringify(res.data));
                        throw new Error('invalid')
                    } else {
                        setDoneCount(val => val + 1);
                    }
                    return res;
                })
            )
        }
        Promise.all(requests).then(results => {
            for (const res of results) {
                if (!(res.status < 400)) {
                    setDoneCount(null);
                    captureMessage(JSON.stringify(res.data));
                    alert(t('CropModal.uploadError'));
                    return;
                }
            }
            setResultImages(results.map(e => e.data));
            prevImagesRef.current = [...images].map(e => {
                if ('crop' in getCropData(e.id)) {
                    e.prevCropData = getCropData(e.id);
                }
                return e;
            });
            successRef.current = true;
            close();
        }).catch(e => {
            if (e.message === 'invalid') {
                captureException(e);
            }
            console.log(e)
            setDoneCount(null);
            alert(t('CropModal.uploadError'));
        }).finally(() => {
            setDoneCount(null);
        })
    }

    useEffect(() => {
        if (isOpen && prevImagesRef.current.length > 0) {
            setIdToCropData(oldValue => {
                const newValue = JSON.parse(JSON.stringify(oldValue));
                for (const prevImage of prevImagesRef.current) {
                    if (prevImage.prevCropData) {
                        newValue[prevImage.id] = prevImage.prevCropData;
                    } else {
                        newValue[prevImage.id] = {};
                    }
                }
                return newValue;
            })
        }
    }, [isOpen]);

    const [isBubbleOpen, setIsBubbleOpen] = useState(true);

    const getCropData = (_id) => {
        return idToCropData[_id] || {};
    }

    return (
        <Modal isOpen={isOpen} width={'100%'} height={'100%'} close={() => {
            closeModal();
        }}>
            <div className={styles.cropModalContainer}>
                {
                    isLoading &&
                    <div className={styles.cropModalLoadingScreenContainer}>
                        <Loading/>
                        <span className={styles.cropModalLoadingCount}>
                            <span className={utilStyles.brandColorText}>{doneCount}</span>/{images.length}
                        </span>
                        <Trans 
                            i18nKey="modal-CropModal:CropModal.loading.count"
                            components={[<br key="br"/>]}
                        />
                        <span className={styles.cropModalLoadingText}>
                            {t('CropModal.loading.count')}<br/>{t('CropModal.loading.wait')}
                        </span>
                    </div>
                }
                <div className={utilStyles.pageContainer}>
                    <div className={classNames(utilStyles.sidePadding, styles.cropModalTitleBar)}>
                        <TitleBar
                            dark
                            title={t('CropModal.title')}
                            renderLeftButton={() => {
                                return (
                                    <BlackCloseIcon
                                        onClick={closeModal}
                                        className={styles.cropModalCloseButton}/>
                                )
                            }}
                            renderRightButton={() => {
                                return (
                                    <div onClick={onSubmit} className={styles.cropModalDoneButton}>{t('CropModal.done')}</div>
                                )
                            }}
                        />
                    </div>
                    {
                        <SortableList onSortEnd={onSortEnd} className={styles.cropModalPreviewContainer} lockAxis={'x'}>
                            {
                                images.map((image, idx) => {
                                    const isInitialized = 'crop' in getCropData(image.id);
                                    const zoom = getCropData(image.id).zoom || 1;
                                    const top = (((getCropData(image.id).cropArea || {}).y || 0) * zoom).toFixed(3);
                                    const left = (((getCropData(image.id).cropArea || {}).x || 0) * zoom).toFixed(3);

                                    return (
                                        <SortableItem key={image.id}>
                                            <div
                                                className={styles.cropModalPreviewWrapper} style={{zIndex: 999999}}
                                                onClick={() => {
                                                    setTargetId(image.id);
                                                }}
                                            >
                                                <div className={styles.cropModalPreviewWrapper3}>
                                                    <div
                                                        className={classNames(`cropperPreview${image.id}`, styles.cropModalPreviewWrapper2)}>
                                                        <img src={image.data} style={
                                                            isInitialized ?
                                                                {
                                                                    // position: 'absolute',
                                                                    width: image.height > image.width ? '100%' : undefined,
                                                                    height: image.height <= image.width ? '100%' : undefined,
                                                                    // width: `${zoom * 100}%`,
                                                                    transform: `translate(-${left}%, -${top}%) scale(${zoom})`,
                                                                    // objectPosition: 'left top',
                                                                    transformOrigin: 'left top',
                                                                    willChange: 'transform',
                                                                    maxWidth: "none",
                                                                }
                                                                :
                                                                {
                                                                    width: '100%', height: '100%', objectFit: "cover",
                                                                }
                                                        }/>
                                                    </div>
                                                    <div
                                                        className={classNames(targetId !== image.id ? styles.cropModalPreviewOverlay : undefined)}/>
                                                </div>
                                            </div>
                                            {/*<Image unoptimized layout="fill" objectFit="cover" src={image.data} />*/}
                                        </SortableItem>
                                    )
                                })
                            }
                        </SortableList>
                    }
                    <div className={classNames(utilStyles.relative)}>
                        {
                            isBubbleOpen &&
                            <div style={{position: 'absolute', top: 9, left: 20,}}>
                                <SpeechBubble sidePadding={0} verticalPadding={0} pointLength={5} top
                                              pointOffset={'15px'}>
                                    <div className={styles.cropModalSpeechBubbleContainer}>
                                        <span className={styles.cropModalSpeechBubbleText}>
                                            {t('CropModal.dragHint')}
                                        </span>
                                        <BlackCloseIcon viewBox="0 0 24 24"
                                                        onClick={() => setIsBubbleOpen(false)}
                                                        className={styles.cropModalSpeechBubbleButton}/>
                                    </div>
                                </SpeechBubble>
                            </div>
                        }
                    </div>
                    <div style={{paddingLeft: 20, paddingRight: 20, marginTop: 40, position: 'relative'}}>
                        <div
                            className={classNames(styles.cropperWrapper)}
                        >
                            <div style={{
                                width: 11,
                                height: 11,
                                position: 'absolute',
                                top: -1,
                                left: -1,
                                borderTop: '1px solid white',
                                borderLeft: '1px solid white',
                                zIndex: 1
                            }}/>
                            <div style={{
                                width: 11,
                                height: 11,
                                position: 'absolute',
                                top: -1,
                                right: -1,
                                borderTop: '1px solid white',
                                borderRight: '1px solid white',
                                zIndex: 1
                            }}/>
                            <div style={{
                                width: 11,
                                height: 11,
                                position: 'absolute',
                                bottom: -1,
                                left: -1,
                                borderBottom: '1px solid white',
                                borderLeft: '1px solid white',
                                zIndex: 1
                            }}/>
                            <div style={{
                                width: 11,
                                height: 11,
                                position: 'absolute',
                                bottom: -1,
                                right: -1,
                                borderBottom: '1px solid white',
                                borderRight: '1px solid white',
                                zIndex: 1
                            }}/>
                            <div className={styles.cropGrid}
                                 style={{width: 0.5, top: 0, bottom: 0, left: '33%', opacity: isCropping ? 1 : 0}}/>
                            <div className={styles.cropGrid}
                                 style={{width: 0.5, top: 0, bottom: 0, right: '33%', opacity: isCropping ? 1 : 0}}/>
                            <div className={styles.cropGrid}
                                 style={{height: 0.5, left: 0, right: 0, bottom: '33%', opacity: isCropping ? 1 : 0}}/>
                            <div className={styles.cropGrid}
                                 style={{height: 0.5, left: 0, right: 0, top: '33%', opacity: isCropping ? 1 : 0}}/>

                            <Cropper
                                key={targetImage.id}
                                image={targetImage.data}
                                crop={getCropData(targetId).crop || {x: 0, y: 0}}
                                zoom={getCropData(targetId).zoom || 1}
                                minZoom={1}
                                maxZoom={10}
                                aspect={1}
                                style={{
                                    cropAreaStyle: {
                                        // border: '1px solid white',
                                        boxShadow: 'none',
                                    },
                                    containerStyle: {
                                        border: `1px solid white`,
                                    }
                                }}
                                classes={{
                                    cropAreaClassName: styles.cropArea,
                                    containerClassName: styles.cropContainer,
                                }}
                                onInteractionStart={() => setIsCropping(true)}
                                onInteractionEnd={() => setIsCropping(false)}
                                objectFit={
                                    targetImage.width < targetImage.height ? 'horizontal-cover' : 'vertical-cover'
                                }
                                onCropChange={(val) => {
                                    setIdToCropData(oldValue => {
                                        const newValue = JSON.parse(JSON.stringify(oldValue));
                                        if (!newValue[targetId]) newValue[targetId] = {};
                                        newValue[targetId].crop = val;
                                        return newValue;
                                    })
                                }}
                                onCropComplete={(croppedAreaPercentages, croppedAreaPixels) => {
                                    setIdToCropData(oldValue => {
                                        const newValue = JSON.parse(JSON.stringify(oldValue));
                                        if (!newValue[targetId]) newValue[targetId] = {};
                                        newValue[targetId].cropArea = croppedAreaPercentages;
                                        newValue[targetId].cropAreaPixel = croppedAreaPixels;
                                        return newValue;
                                    })
                                }}
                                onCropAreaChange={(croppedAreaPercentages, croppedAreaPixels) => {
                                    setIdToCropData(oldValue => {
                                        const newValue = JSON.parse(JSON.stringify(oldValue));
                                        if (!newValue[targetId]) newValue[targetId] = {};
                                        newValue[targetId].cropArea = croppedAreaPercentages;
                                        newValue[targetId].cropAreaPixel = croppedAreaPixels;
                                        return newValue;
                                    })
                                }}
                                onZoomChange={(zoom) => {
                                    setIdToCropData(oldValue => {
                                        const newValue = JSON.parse(JSON.stringify(oldValue));
                                        if (!newValue[targetId]) newValue[targetId] = {};
                                        newValue[targetId].zoom = zoom;
                                        return newValue;
                                    })
                                }}
                                setMediaSize={(mediaSize) => {
                                    setIdToCropData(oldValue => {
                                        const newValue = JSON.parse(JSON.stringify(oldValue));
                                        if (!newValue[targetId]) newValue[targetId] = {};
                                        newValue[targetId].mediaSize = mediaSize;
                                        return newValue;
                                    })
                                }}
                                setCropSize={(cropSize) => {
                                    setIdToCropData(oldValue => {
                                        const newValue = JSON.parse(JSON.stringify(oldValue));
                                        if (!newValue[targetId]) newValue[targetId] = {};
                                        newValue[targetId].cropSize = cropSize;
                                        return newValue;
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
};