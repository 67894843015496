import {Axios} from "api/index";
import {toast} from "react-toastify";

export const downloadCouponsByMoldKeys = async (commaSeparatedMoldKeys, onSuccess) => {
    const data = {
        mold_keys: commaSeparatedMoldKeys.split(',').map(x => x.trim()),
    }
    try {
        const res = await Axios.post('v1/coupons/download-bulk', data);
        if (res.status < 400) {
            console.log('res.data', res.data);
            if (res.data.success_count > 0) {
                toast.info('쿠폰이 발급되었어요!\n쿠폰은 마이페이지 > 쿠폰에서 확인할 수 있어요.');
                if (onSuccess) {
                    onSuccess();
                }
            }
        }
    } catch (err) {
        console.log('failed to download coupons', err);
    }
};
