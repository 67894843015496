import axios from "axios";
import {BASE_URL, CDN_BASE_URL, SSR_BASE_URL} from "common/const";
import axiosBetterStacktrace from "axios-better-stacktrace";
import {captureException} from "@sentry/nextjs";
import {isApp} from "common/utils";
import {buildKeyGenerator, buildMemoryStorage, setupCache} from "axios-cache-interceptor";
import {v4} from "uuid";


export const _Axios = axios.create({
    baseURL: `${BASE_URL}/api`,
    timeout: 10000,
    withCredentials: true,
    validateStatus: status => status < 500,
    headers: {
        "content-type": 'application/json',
        'is-app': isApp(),
    },
});

_Axios.interceptors.request.use(
    (config) => {
        try {
            if (typeof document !== 'undefined' && document.referrer) {
                config.headers['referrer-site'] = document.referrer;
            }
            return config;
        } catch (err) {
            captureException(err);
        }
        return config;
    },
    (error) => {
        // 요청 에러 직전 호출됩니다.
        return Promise.reject(error);
    }
);

export const Axios = setupCache(_Axios, {
    storage: buildMemoryStorage(false, false, 100),
    generateKey: buildKeyGenerator((request ) => {
        return {
            method: request.method,
            url: request.url,
            params: request.params,
            custom: typeof window === 'undefined' ? v4() : (window.history.state?.key ? window.history.state.key : v4()),
        }
    }),
    cacheTakeover: false,
});

export const defaultCacheOption = typeof window === 'undefined' ? false : Object.assign({}, Axios.defaults.cache);
if (defaultCacheOption) {
    defaultCacheOption.ttl = 60 * 60 * 60 * 1000;
}
Axios.defaults.cache = false;

export const SSRAxios = axios.create({
    baseURL: `${SSR_BASE_URL}/api`,
    timeout: 10000,
    withCredentials: true,
    validateStatus: status => status < 500,
    headers: {
        "content-type": 'application/json',
    },
});

export const CDNAxios = axios.create({
    baseURL: `${CDN_BASE_URL}/api`,
    timeout: 10000,
    withCredentials: true,
    validateStatus: status => status < 500,
    headers: {
        "content-type": 'application/json',
    },
});


axiosBetterStacktrace(Axios);
axiosBetterStacktrace(SSRAxios);


export * from 'api/products';
