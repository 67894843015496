import {isAndroid, isApp, isIOS, postRNMessage} from "common/utils";
import {REVERSE_MESSAGE_TYPE_REQUEST_STORE_REVIEW} from "pages/_app";
import semver from "semver";


export const requestAppStoreReview = () => {
    if (!isApp()) {
        return;
    }
    const appVersion = typeof window !== 'undefined' && isApp() ? (window.RNAppVersion || '0.0.0') : '0.0.0';
    if (semver.lt(appVersion, '1.0.16')) {
        if (isAndroid()) {
            document.location.href = "market://details?id=com.hemekolab.app";
        } else if (isIOS()) {
            document.location.href = `itms-apps://apps.apple.com/app/id6448773004?action=write-review`;
        }
    } else {
        postRNMessage({
            type: REVERSE_MESSAGE_TYPE_REQUEST_STORE_REVIEW,
            data: {},
        });
    }
};
