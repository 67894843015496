import Layout from "components/layout/layout";


export default function NavigationLayout(page) {
    return (
        <Layout>
            {page}
        </Layout>
    )
}
