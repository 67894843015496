import {Axios} from "api";
import {isApp, showRewardToast} from "common/utils";
import {captureException, captureMessage} from "@sentry/nextjs";


export const completeReward = async (
    router, rewardKey, customTitle = null, customDescription = null, closeButtonText = '포인트 더 받기'
) => {
    try {
        const res = await Axios.post(`/v1/rewards/`, {
            reward_key: rewardKey,
            is_app: isApp(),
        });
        if (res.status < 400) {
            if (!Boolean(res.data.given_reward)) {
                return;
            }
            showRewardToast(
                router, res.data.given_reward.key, res.data.given_reward.point, customTitle, customDescription,
                closeButtonText,
            );
        } else {
            captureMessage(JSON.stringify(res.data));
        }
    } catch (e) {
        captureException(e);
    }
};
