import ShareIcon from "images/icons/share.svg";
import utilStyles from "styles/utils.module.scss";
import styles from 'components/buttons/ProductShareButton.module.scss';
import ProductShareModal from "components/modal/ProductShareModal";
import {useState, useContext} from "react";
import SpeechBubble from "components/SpeechBubble";
import SelfVerificationModal from "components/modal/SelfVerificationModal";
import UserContext from "context/AuthContext";

const ProductShareButton = (props) => {
    const {product} = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

    const user = useContext(UserContext).user;

    const handleShareButtonClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductShareButtonClick'
        );
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleShareSuccess = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductShareSuccess'
        );
        setIsModalOpen(false);
        if (user && !user.phone_number) {
            setIsAuthModalOpen(true);
        }
    };

    const handleAuthModalClose = () => {
        setIsAuthModalOpen(false);
    };

    return (
        <div className={styles.root}>
            {
                isModalOpen &&
                <ProductShareModal product={product} handleClose={handleModalClose} onSuccess={handleShareSuccess}/>
            }
            {
                isAuthModalOpen &&
                <SelfVerificationModal product={product} handleClose={handleAuthModalClose}/>
            }
            <div className={styles.speechBubbleWrapper}>
                <SpeechBubble
                    sidePadding={6} verticalPadding={6}
                    pointLength={5}
                    pointLeft="30%"
                    bottom
                    backgroundColor="black"
                >
                    <div className={styles.bubbleContent}>
                        공유하고 포인트 받기!
                    </div>
                </SpeechBubble>
            </div>
            <button className={utilStyles.transparentButton} onClick={handleShareButtonClick}>
                <ShareIcon viewBox="0 0 24 24" className={utilStyles.cursorPointer} width={25} height={25}/>
            </button>
        </div>
    )
};

export default ProductShareButton;
