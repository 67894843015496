import styles from "components/modal/IntegratedReservationModal.module.scss";
import {useState} from "react";
import OptionSelectionDropDown from "components/dropdown/OptionSelectionDropDown";
import {toast} from "react-toastify";
import {checkHasConsonant} from "common/utils";


export const IntegratedReservationModalOptionSelector = (props) => {
    const {selection, setSelection} = props;
    const [isExpanded, setIsExpanded] = useState(false);
    const options = selection.product ? selection.product.options : [];

    const handleOptionSelect = (option) => {
        if (option.max_buy_count && selection.options[option.id] >= option.max_buy_count) {
            toast.info(`${option.name}${checkHasConsonant(option.name) ? '은' : '는'} ${option.max_buy_count}개까지만 구매 가능한 옵션이에요.`);
            return;
        }
        setSelection(prev => {
            const newSelection = JSON.parse(JSON.stringify(prev));
            const newDict = JSON.parse(JSON.stringify(newSelection.options));
            newDict[option.id] = newDict[option.id] ? newDict[option.id] + 1 : 1;
            newSelection.options = newDict;
            return newSelection;
        });
    };

    return (
        <>
            <h2 className={styles.sectionTitleContainer}>관리권을 선택해주세요</h2>
            <div className={styles.optionSelectorContainer}>
                <OptionSelectionDropDown
                    isExpanded={isExpanded} setIsExpanded={setIsExpanded}
                    small={true}
                    product={selection.product}
                    options={options}
                    onClick={handleOptionSelect}
                    selectedOptions={selection.options}
                />
            </div>
        </>
    )
};
