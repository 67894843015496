import {useState} from "react";
import Modal from "components/Modal";
import styles from "components/modal/ProductReviewModal.module.scss";
import BlackCloseIcon from "images/icons/close_191919.svg";
import Image from "next/image";
import {ReviewCard} from "components/sections/product/ProductReviewSection";
import ArrowLeftIcon from "images/icons/arrow_left_757575.svg";
import RollingImage from "components/images/RollingImage";
import {swiperCustomAnimation} from "common/utils";
import {Swiper, SwiperSlide} from "swiper/react";
import FullButton from "components/buttons/FullButton";
import classNames from "classnames";
import utilStyles from "styles/utils.module.scss";
import TitleBar from "components/layout/TitleBar";


const ProductReviewModalDetailContent = (props) => {
    const {review} = props;

    const onUpvoteClick = (isUpvote) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailBestReviewUpvoteClick', {
            reviewId: review.id,
            upvote: isUpvote,
        });
    };

    return (
        <div>
            <Swiper
                // loop={product.image_urls.length > 1}
                onSetTranslate={swiperCustomAnimation}
                spaceBetween={0}
                slidesPerView={1}
                threshold={30}
                pagination={{
                    clickable: true,
                    horizontalClass: styles.productImageSwiperHorizontalClass,
                    bulletClass: styles.productImageSwiperBulletClass,
                    bulletActiveClass: styles.productImageSwiperBulletActiveClass,
                }}
            >
                {
                    review.images.map((image, index) => {
                        return (
                            <SwiperSlide key={`review_image_${image.id}`}>
                                <div className={styles.reviewModalContentWrapper}
                                     style={{paddingBottom: '100%'}}>
                                    <div className={styles.reviewModalContentItem}>
                                        <Image onDrag={e => e.preventDefault()} unoptimized
                                               src={image.image_url} layout="fill" objectFit="cover"
                                               objectPosition="center"/>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
            <ReviewCard review={review} isExpanded hideImages onUpvoteClick={onUpvoteClick} isBottomBorderEmpty/>
        </div>
    )
}


const ProductReviewModalDetail = (props) => {
    const {isClose, reviews, review, closeModal, goToGrid, title, hideBottomButton} = props;

    const initialIndex = reviews.findIndex(e => e.id === review.id);

    const [currentIndex, setCurrentIndex] = useState(initialIndex);

    const handleCloseClick = () => {
        closeModal();
    };

    const onSlideChange = (swiper) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductReviewModalDetailSlideChange');
        setCurrentIndex(swiper.activeIndex);
    };

    const handleGoToGridButtonClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductReviewModalDetailGoToGridButtonClick');
        goToGrid();
    };

    return (
        <>
            <div className={classNames(utilStyles.newSidePadding, utilStyles.topSticky, utilStyles.whiteBackground)}>
                <TitleBar isBack={!isClose} isClose={isClose} title={title || `포토리뷰 (${currentIndex + 1}/${reviews.length})`} close={handleCloseClick} />
            </div>
            {/*<div className={styles.reviewModalTitle}>*/}
            {/*    <ArrowLeftIcon viewBox="0 0 16 16" className={styles.arrowLeftIcon} onClick={handleCloseClick}/>*/}
            {/*    <span className={styles.reviewModalTitleText}>{}</span>*/}
            {/*    <div style={{width: 24}}/>*/}
            {/*</div>*/}
            <div>
                <Swiper
                    initialSlide={initialIndex}
                    onSetTranslate={swiperCustomAnimation}
                    spaceBetween={0}
                    slidesPerView={1}
                    threshold={30}
                    onSlideChange={onSlideChange}
                    autoHeight={true}
                >
                    {
                        reviews.map((review, idx) => {
                            return (
                                <SwiperSlide key={`review_content_${review.id}`}>
                                    <ProductReviewModalDetailContent review={review}/>
                                </SwiperSlide>
                            );
                        })
                    }
                </Swiper>
            </div>
            {
                !hideBottomButton &&
                <div className={styles.reviewModalGoToGrid}>
                    <FullButton onClick={handleGoToGridButtonClick} title={`사진 모아보기 (${reviews.length})`} height={48} white fontSize={16} />
                </div>
            }
        </>
    )
};

const ProductReviewModalGrid = (props) => {
    const {reviews, handleReviewClick, handleCloseClick} = props;

    return (
        <div className={styles.gridRoot}>
            <div className={styles.reviewModalTitle}>
                <div style={{width: 24}}/>
                <span className={styles.reviewModalTitleText}>모아보기</span>
                <BlackCloseIcon onClick={handleCloseClick}/>
            </div>
            <div className={styles.gridWrapper}>
                {
                    reviews.filter(e => e.images.length > 0).map((review, index) => {
                        return <div
                            key={`review-${review.id}`}
                            className={styles.imageWrapper}
                            onClick={() => handleReviewClick(review)}
                        >
                            <RollingImage prefix={"images"} images={review.images.map(e => e.thumbnail_url)}/>
                        </div>
                    })
                }
            </div>
        </div>
    )
}


export const ProductReviewModal = (props) => {
    const {reviews, closeModal, title, hideBottomButton, isClose} = props;
    const [selectedReview, setSelectedReview] = useState(props.selectedReview === 'list' ? null : props.selectedReview);
    const [isFromGrid, setIsFromGrid] = useState(false);

    const onCloseClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailBestReviewModalClose');
        closeModal();
    };

    const handleReviewClick = (review) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductReviewModalReviewClick', {
            reviewId: review.id,
        });
        setIsFromGrid(true);
        setSelectedReview(review);
    };

    const handleDetailCloseClick = () => {
        if (isFromGrid) {
            setSelectedReview(null);
        } else {
            closeModal();
        }
    };

    const handleGoToGrid = () => {
        setSelectedReview(null);
    };

    return (
        <Modal isOpen={true} width={'100%'} height={'100%'} close={onCloseClick}>
            {
                selectedReview ?
                    <ProductReviewModalDetail isClose={isClose} hideBottomButton={hideBottomButton} title={title} reviews={reviews} review={selectedReview} goToGrid={handleGoToGrid}
                                              closeModal={handleDetailCloseClick}/> :
                    <ProductReviewModalGrid reviews={reviews} handleReviewClick={handleReviewClick}
                                            handleCloseClick={closeModal}/>
            }
        </Modal>
    )
};

export default ProductReviewModal;
