import Lottie from "lottie-react";
import loadingDotLottieJSON from "images/lottie/loading_dot.json";
import utilStyles from "styles/utils.module.scss";
import styles from "components/loading.module.scss";
import classNames from "classnames";

export default function LoadingDot(props) {
    const {width, height, white} = props;

    return (
        <div
            className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter, utilStyles.justifyContentCenter)}
            style={props.style}
        >
            <Lottie
                className={classNames(styles.svgDot, classNames(white && styles.whiteDots))} animationData={loadingDotLottieJSON} autoplay={true} loop={true}
                style={{width, height}}
            />
        </div>
    );
}
