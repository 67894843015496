import classNames from "classnames";
import styles from 'components/layout/layout.module.scss'
import utilStyles from 'styles/utils.module.scss'
import TopBanner from "components/layout/TopBanner";
import NavBar from "components/layout/NavBar/NavBar";
import StoreIcon from "images/icons/store.svg";
import PaletteIcon from "images/icons/palette.svg";
import HomeIcon from "images/icons/home.svg";
import MyPageIcon from "images/icons/mypage.svg";
import {
    addressRoute,
    couponsRoute,
    faqRoute,
    getHomeRoute,
    inquiryRoute,
    isHomeRoute, isProductRoute,
    likeRoute,
    myInfoRoute,
    myPageRoute,
    ordersRoute,
    paletteRoute,
    pointsRoute,
    productInquiriesRoute,
    productsRoute,
    refundAccountRoute,
    reviewRoute,
    storeRoute
} from "common/const";
import Footer from "components/layout/Footer/Footer";
import {useRouter} from "next/router";
import {useContext, useEffect, useState} from "react";
import {Axios} from "api";
import {categoryContextRef} from "context/CategoryContext";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {STATUS_CLAIMED} from "pages/mypage/orders";
import HeartIcon from "images/icons/heart_shortcut.svg";
import UserContext from "context/AuthContext";
import {isExpandedRoute} from "common/utils";
import ABTestContext, {ABTEST_KEY_PALETTE_NAME} from "context/ABTestContext";
import QuestionIcon from "images/icons/question_circle.svg";


let scrollPos = 0;
let accUp = 0;
let accDown = 0;

export default function Layout(props) {
    const { children, home, topBar, footer, isNavBarHidden, isTopBannerHidden } = props;
    const user = useContext(UserContext).user;
    const router = useRouter();
    const locale = router.locale;

    const paletteNameGroup = useContext(ABTestContext).getGroupFromKey(ABTEST_KEY_PALETTE_NAME);

    let defaultCategories =
        locale === 'ko' ?
            [
                { name: 'HOME', korean_name: '홈', icon: HomeIcon, route: getHomeRoute(), getIsActive: () => (typeof window !== 'undefined') && isHomeRoute(window.location.pathname) },
                { name: 'PALETTE', korean_name: '팔레트', icon: PaletteIcon, route: paletteRoute, getIsActive: () => (typeof window !== 'undefined') && window.location.pathname.startsWith(paletteRoute) },
                { name: 'STORE', korean_name: '스토어', icon: StoreIcon, route: productsRoute, getIsActive:  () => (typeof window !== 'undefined')  && window.location.pathname.startsWith(storeRoute) },
                // { name: 'EVENT', korean_name: '이벤트', icon: EventIcon, route: eventRoute, getIsActive:  () => (typeof window !== 'undefined')  && window.location.pathname === eventRoute },
                { name: 'LIKE', mobile_only: true, korean_name: '마이픽', icon: (props) => <HeartIcon viewBox="0 0 25 25" height="24" width="24" {...props} />, route: likeRoute, getIsActive:  () => (typeof window !== 'undefined') && window.location.pathname === likeRoute },
                {
                    name: 'MY PAGE', korean_name: '마이페이지', icon: MyPageIcon, route: myPageRoute,
                    getIsActive:  () => (typeof window !== 'undefined') && window.location.pathname.startsWith(myPageRoute),
                    getIsMobileActive:  () => (typeof window !== 'undefined') && window.location.pathname.startsWith(myPageRoute) && window.location.pathname !== likeRoute,
                    children: [],
                },
            ] :
            [
                { name: 'HOME', korean_name: 'HOME', icon: HomeIcon, route: getHomeRoute(), getIsActive: () => (typeof window !== 'undefined') && isHomeRoute(window.location.pathname) },
                { name: 'STORE', korean_name: 'SHOP', icon: StoreIcon, route: productsRoute, getIsActive:  () => (typeof window !== 'undefined')  && window.location.pathname.startsWith(storeRoute) },
                { name: 'LIKE', mobile_only: true, korean_name: 'MY PICK', icon: (props) => <HeartIcon viewBox="0 0 25 25" height="24" width="24" {...props} />, route: likeRoute, getIsActive:  () => (typeof window !== 'undefined') && window.location.pathname === likeRoute },
                {
                    name: 'MY PAGE', korean_name: 'MY PAGE', icon: MyPageIcon, route: myPageRoute,
                    getIsActive:  () => (typeof window !== 'undefined') && window.location.pathname.startsWith(myPageRoute),
                    getIsMobileActive:  () => (typeof window !== 'undefined') && window.location.pathname.startsWith(myPageRoute) && window.location.pathname !== likeRoute,
                    children: [],
                },
            ];
    const [categories, setCategories] = useState(defaultCategories);

    useEffect(async () => {
        let newCategory = [...defaultCategories];
        try {
            const res = await Axios.get('v1/store/categories');
            if (res.status < 400) {
                const categories = res.data.categories.concat([{
                    id: 0, parent_id: null, name: 'ALL', children: [], rank: -1, query_string: ''
                }]);
                categories.sort((a, b) => a.rank - b.rank);
                categoryContextRef.current.setProductCategories(categories);
                newCategory.filter(e => e.name === 'STORE')[0].children = [...res.data.categories].sort((a, b) => a.rank - b.rank);
            }
        } catch (e) {
            console.log(e);
        }
        setCategories(newCategory);
    }, []);

    useEffect(() => {
        setCategories(oldCategories => {
            const newCategories = [...oldCategories];
            if (user && newCategories[4]) {
                newCategories[4].children = [
                    {id: 1, name: '나의 쇼핑정보', route: ordersRoute, children: [
                            {id: 4, name: '주문/배송 조회', route: ordersRoute, query: {status: undefined, months: 1}, getIsActive:  () => (typeof window !== 'undefined') && window.location.pathname === ordersRoute && new URLSearchParams(window.location.search).get('status') !== STATUS_CLAIMED},
                            {id: 5, name: '취소/교환/반품 조회', route: ordersRoute, query: {status: STATUS_CLAIMED, months: 1}, getIsActive:  () => (typeof window !== 'undefined') && window.location.pathname === ordersRoute && new URLSearchParams(window.location.search).get('status') === STATUS_CLAIMED},
                            {id: 6, name: '나의 리뷰 관리', route: reviewRoute},
                            {id: 7, name: '마이픽', route: likeRoute},
                        ]},
                    {id: 1.5, name: '나의 혜택관리', route: pointsRoute, children: [
                            {id: 7.2, name: '포인트', route: pointsRoute},
                            {id: 7.1, name: '쿠폰', route: couponsRoute},
                        ]},
                    {id: 2, name: '나의 정보관리', route: myInfoRoute, children: [
                            {id: 8, name: '회원정보 수정', route: myInfoRoute},
                            {id: 9, name: '배송지 관리', route: addressRoute},
                            {id: 10, name: '환불계좌 관리', route: refundAccountRoute},
                        ]},
                    {id: 3, name: '고객센터', route: inquiryRoute, children: [
                            {id: 11, name: '1 : 1 문의내역', route: inquiryRoute},
                            {id: 12, name: '제품 Q&A', route: productInquiriesRoute},
                            {id: 13, name: 'FAQ', route: faqRoute},
                        ]}
                ];
            }
            return newCategories;
        })
    }, [user]);

    return (
        <div className={styles.rootWrapper}>
            <div className={styles.rootBody}>
                {
                    !isTopBannerHidden && <TopBanner />
                }
                {
                    topBar || <div/>
                }
                <div className={classNames(utilStyles.rootContainer, utilStyles.flexCol)}>
                    <div className={classNames(styles.root)}>
                        <div className={classNames(styles.container)}>
                            {children}
                        </div>
                        {
                            isHomeRoute(router.pathname) || isProductRoute(router.pathname)
                                ?
                                <Footer locale={locale} />
                                :
                                <div className={styles.bottomPadding} />
                        }
                    </div>
                </div>
                { !isNavBarHidden && <NavBar categories={categories} /> }
            </div>
        </div>
    )
}
