import {createContext, useContext, useEffect, useState} from "react";


const SellerProductContext = createContext(undefined);

export let sellerProductContextRef = {};

export default SellerProductContext;

const SellerProductContextWrapper = ({children}) => {
    const context = useContext(SellerProductContext);

    useEffect(() => {
        sellerProductContextRef.current = context;
    }, []);

    useEffect(() => {
        sellerProductContextRef.current = context;
    }, [context]);

    return(
        <>
            {children}
        </>
    )
}

// TODO: Inspect why render is called twice on context change
export const SellerProductContextProvider = ({children}) => {
    const [shopProductIds, setShopProductIds] = useState(new Set());
    const [loadingProductIds, setLoadingProductIds] = useState(new Set());

    const isAdded = (productId) => {
        return shopProductIds.has(productId);
    }

    const overrideProductIds = (productIds) => {
        if (!productIds) {
            return
        }
        setShopProductIds(new Set(productIds));
    }

    const addProductIds = (productIds) => {
        if (!productIds) {
            return
        }
        setShopProductIds(oldSet => {
            return new Set([...oldSet, ...productIds]);
        });
    }

    const removeProductId = (productId) => {
        setShopProductIds(oldSet => {
            const newSet = new Set(oldSet);
            newSet.delete(productId);
            return newSet;
        });
    }

    const updateProductIds = (productIdStatusMap) => {
        setShopProductIds(oldSet => {
            const newSet = new Set(oldSet);
            Object.entries(productIdStatusMap).map(([productId, status]) => {
                if (status) {
                    newSet.add(parseInt(productId));
                } else {
                    newSet.delete(parseInt(productId));
                }
            });
            return newSet;
        });
    }

    const updateByProductIdsAndAddedProductIds = (productIds, addedIds) => {
        const addedIdsSet = new Set(addedIds);

        const unaddedIds = productIds.filter(x => !addedIdsSet.has(x));

        setShopProductIds(oldSet => {
            const newSet = new Set(oldSet);
            for (const _id of addedIds) {
                newSet.add(parseInt(_id));
            }
            for (const _id of unaddedIds) {
                newSet.delete(parseInt(_id));
            }
            return newSet;
        });
    }

    const setIsLoading = (productId, status) => {
        setLoadingProductIds(oldSet => {
            const newSet = new Set(oldSet);
            if (status) {
                newSet.add(productId);
            } else {
                newSet.delete(productId);
            }
            return newSet;
        });
    }

    const isLoading = (productId) => {
        return loadingProductIds.has(productId);
    }

    const contextValue = {
        isAdded, addProductIds, removeProductId, updateProductIds, overrideProductIds, isLoading, setIsLoading,
        updateByProductIdsAndAddedProductIds,
    };

    return (
        <SellerProductContext.Provider value={contextValue}>
            <SellerProductContextWrapper>
                {children}
            </SellerProductContextWrapper>
        </SellerProductContext.Provider>
    )
}
