import styles from "pages/_app.module.scss";
import CloseIcon from "images/icons/close_new.svg";
import {getAppRedirectUrl, redirectToApp} from "common/redirect";
import FullButton from "components/buttons/FullButton";
import {hideUntilTomorrow} from "common/modalHelper";
import React from "react";
import BottomModal from "components/BottomModal";
import Clipboard from "react-clipboard.js";
import {toast} from "react-toastify";


export const LOCAL_STORAGE_KEY_APP_MODAL_CLOSE_UNTIL = 'app_modal_close_until';

const AppInstallModal = (props) => {
    const close = props.close || function () {
    };
    const isOpen = props.isOpen;

    const handleHideForDay = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('AppInstallModalHideForDay');
        hideUntilTomorrow(LOCAL_STORAGE_KEY_APP_MODAL_CLOSE_UNTIL);
        close();
    }

    const handleClose = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('AppInstallModalClose');
        close();
    }

    const handleCopy = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('AppInstallModalCopy');
        toast.info('시크릿 코드가 복사되었어요');
    }

    const handleInstallClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('AppInstallModalGoToApp');
        try {
            typeof window !== 'undefined' && Boolean(window.karrotPixel) && window.karrotPixel.track('Install');
        } catch {}
        redirectToApp();
    }

    const finalUrl = typeof window === 'undefined' ? '' : getAppRedirectUrl();
    const encodedFinalUrl = btoa(finalUrl);


    return (
        <BottomModal isOpen={isOpen} close={() => {
            close()
        }} preventBackgroundClose>
            <div className={styles.appModalContainer}>
                <div className={styles.appModalCloseIcon} onClick={handleClose}>
                    <CloseIcon viewBox="0 0 17 17" width={24} height={24}/>
                </div>
                <div className={styles.appModalContentContainer}>
                    <div className={styles.appModalCouponIcon}/>
                    <div className={styles.appModalTitle}>
                        앱에서 바로 쓸 수 있는<br/>1,000P와 쿠폰이 있어요
                    </div>
                    <div className={styles.appModalDescription}>
                        앱 다운로드 혜택 받기 전이라면<br/>
                        누구나 받을 수 있어요<br/>
                        시크릿 코드 복사하고 혜택 받으러 가보세요!
                    </div>
                    <div className={styles.inputButtonContainer}>
                        <div className={styles.appModalInput}>
                            {
                                encodedFinalUrl && encodedFinalUrl.length > 13 ?
                                    encodedFinalUrl.substring(0, 13) + '...'
                                    :
                                    encodedFinalUrl
                            }
                        </div>
                        <Clipboard
                            className={styles.appModalCopyContainer}
                            option-text={() => encodedFinalUrl}
                            onSuccess={handleCopy}
                        >
                            <div className={styles.copyButtonWrapper}>
                                <FullButton white fontSize={14} height={46}>코드 복사하기</FullButton>
                            </div>
                        </Clipboard>
                    </div>
                    <div className={styles.appModalButtonWrapper}>
                        <FullButton
                            fontSize={14} height={46}
                            onClick={handleInstallClick}
                        >
                            앱으로 가기
                        </FullButton>
                    </div>
                    <div className={styles.appModalHideToday} onClick={handleHideForDay}>
                        오늘 하루 보지 않기
                    </div>
                </div>
            </div>
        </BottomModal>
    )
};

export default AppInstallModal;
