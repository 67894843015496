import React, {Suspense} from "react";
import LeftArrowIcon from "images/icons/arrow_left_757575.svg";
import RightArrowIcon from "images/icons/arrow_right_757575.svg";
import styles from "components/calendar/CustomCalendar.module.scss";
import {format} from "date-fns";

const ReactCalendar = React.lazy(() => import("react-calendar"));

export const CustomCalendar = (props) => {
    const {selectedDate, setSelectedDate, unavailableDateStrings, startDate, endDate} = props;
    const today = new Date();
    const todayStr = format(today, 'yyyy-MM-dd');

    const tileDisabled = ({date}) => {
        let isUnavailable = false;
        if (!date) {
            return false;
        }
        const dateStr = format(date, 'yyyy-MM-dd');
        if (unavailableDateStrings) {
            isUnavailable = unavailableDateStrings.some(unavailableDateStr => unavailableDateStr === dateStr);
        }
        if (startDate) {
            isUnavailable = isUnavailable || (dateStr < startDate);
        }
        if (endDate) {
            isUnavailable = isUnavailable || (dateStr > endDate);
        }
        if (dateStr <= todayStr) {
            isUnavailable = true;
        }
        return isUnavailable;
    };

    return (
        <div>
            <Suspense fallback={<div/>}>
                <ReactCalendar
                    onChange={setSelectedDate} value={selectedDate}
                    formatDay={(locale, date) =>
                        date.toLocaleString('en', {day: 'numeric'})
                    }
                    calendarType="gregory"
                    minDetail="month"
                    nextLabel={<RightArrowIcon viewBox="0 0 12 12" className={styles.icon}/>}
                    prevLabel={<LeftArrowIcon viewBox="0 0 16 16" className={styles.icon}/>}
                    next2Label={null}
                    prev2Label={null}
                    tileDisabled={tileDisabled}
                    locale="ko"
                />
            </Suspense>
        </div>
    )
};
