import utilStyles from "styles/utils.module.scss";
import commonStyles from "components/sections/product/index.module.scss";
import styles from "components/sections/product/productInquirySection.module.scss";
import ProductInfoTitle from "components/sections/product/ProductInfoTitle";
import {forwardRef, useContext, useEffect, useRef, useState} from "react";
import LinesEllipsis from "react-lines-ellipsis";
import classNames from "classnames";
import Paginator from "components/Paginator";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ProductInquiryModal from "components/modal/ProductInquiryModal";
import {Axios} from "api";
import Loading from "components/Loading";
import FAQCard from "components/cards/FAQCard";
import {formatDate} from "common/utils";
import {useRouter} from "next/router";
import UserContext from "context/AuthContext";
import {redirectToLogin} from "common/redirect";
import {ErrorMessage} from "pages/500";
import {captureException, captureMessage} from "@sentry/nextjs";


const INQUIRY_PAGE_SIZE = 10

const ProductInquirySection = forwardRef((props, ref) => {
    const {product, totalInquiryCount, isReservation} = props;
    const setTotalInquiryCount = props.setTotalInquiryCount || function () {}
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);

    const router = useRouter();

    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [inquiries, setInquiries] = useState([]);
    // const [totalCount, setTotalCount] = useState(0);
    const [error, setError] = useState(false);
    const [page, setInquiryPage] = useState(1);

    const userContext = useContext(UserContext);
    const rootId = "inquiry";

    const initialized = useRef(false);

    useEffect(() => {
        if (!router.isReady || page <= 0) return;
        getProductInquiries(page);
        if (initialized.current) {
            if (!!ref.current) {
                ref.current.scrollIntoView();
            }
        }
    }, [router.isReady, page]);

    const getProductInquiries = async (page_num) => {
        if (page_num < 1) return;
        try {
            setIsLoading(true);
            setInquiries([]);
            setError(false);
            setActiveQuestion(null);
            //TODO: fix this
            const data = {page_num: page_num, page_size: INQUIRY_PAGE_SIZE}
            const res = await Axios.get(`v1/store/products/${product.id}/inquiries/`, {params: data});
            if (res.status < 400) {
                setModalOpen(false);
                setInquiries(res.data.inquiries);
                setTotalInquiryCount(res.data.total_count);
                initialized.current = true;
            } else {
                setError(true);
                if (! res.data.display_message) {
                    captureMessage(JSON.stringify(res.data));
                }
            }
        } catch (e) {
            setError(true);
            captureException(e);
        } finally {
            setIsLoading(false);
        }
    }

    const onLinkClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailInquirySectionCreateInquiryClick', {
            productId: product.id,
            productName: product.name,
        });
        if (userContext.user) {
            setModalOpen(true)
        }
        else {
            redirectToLogin(router, true, rootId);
        }
    };

    const toggleInquiryClick = (id) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailInquirySectionInquiryClick',
            {
                productId: product.id,
                productName: product.name,
                inquiryId: id,
                expand: activeQuestion !== id,
            }
        );
        if (activeQuestion === id) {
            setActiveQuestion(null);
        } else {
            setActiveQuestion(id);
        }
    };

    return (
        <div ref={ref} className={commonStyles.infoSection} id={rootId}>
            <ProductInquiryModal
                pid={product.id} isModalOpen={isModalOpen} setModalOpen={setModalOpen}
                onSuccess={() => {
                     if (page === 1)
                         getProductInquiries(1)
                     else
                         setInquiryPage(1)
                }}
                isReservation={isReservation}
            />
            <ProductInfoTitle title="문의" subTitle={`(${totalInquiryCount})`} link={{title: "문의하기"}}
                              onClick={onLinkClick}/>
            <div className={classNames(utilStyles.borderUnderLine, !isMobile && styles.sideMargin)}/>
            <div className={styles.container}>
                {
                    isLoading ?
                        <Loading style={{marginTop: 60}} />
                        :
                        (
                            inquiries.length > 0
                                ?
                                <>
                                    <div className={styles.listRoot}>
                                        {inquiries.map(e => {
                                            return (
                                                <FAQCard question={e.question} answer={e.answer} key={`${e.id}`}
                                                         date={formatDate(e.created_at)}
                                                         showAnsweredState
                                                         isOpen={activeQuestion === e.id}
                                                         image={e.thumbnail_urls.length > 0 ? e.thumbnail_urls[0] : null}
                                                         onClick={() => toggleInquiryClick(e.id)}
                                                         nickname={e.user_nickname}
                                                         isSecret={e.is_secret}
                                                         // onDelete={() => onDeleteInquiry(e.id)}
                                                />
                                            );
                                        })}
                                    </div>
                                    <Paginator currentPage={page} size={isLarge ? 10 : 5}
                                               onChangeCurrentPage={(e) => { setInquiryPage(e); }}
                                               totalCount={totalInquiryCount} countPerPage={INQUIRY_PAGE_SIZE} isLoading={isLoading}
                                               style={{marginTop: isLarge ? 60 : 40}} />
                                </>
                                :
                                error
                                    ?
                                    (
                                        <ErrorMessage padding={60} titleFontSize={18} descriptionFontSize={15} iconSize={48} titleMargin={20} descriptionMargin={8}/>
                                    )
                                    :
                                    <div className={styles.errorContainer}>
                                        <span className={styles.errorText}>
                                            {
                                                "궁금한 점은 언제든지 문의 주세요!"
                                            }
                                        </span>
                                    </div>
                        )

                }
            </div>
        </div>
    );
})


export default ProductInquirySection;
