import {useLayoutEffect} from "react";

import Cookies from "js-cookie";
import {getLocalizationInfoInCSR} from "common/i18n";
import {saveCountryCode} from "common/utils";
import {useRouter} from "next/router";


const usePersistLocalizationCookie = () => {
    const {locale} = useRouter();

    const persistLocaleCookie = () => {
        const {countryCode} = getLocalizationInfoInCSR();
        const siteUrl = `.${window.location.host.split('.').slice(-2).join('.')}`;

        if (locale !== Cookies.get("NEXT_LOCALE")) {
            const date = new Date();
            const expireMs = 100 * 24 * 60 * 60 * 1000; // 100 days
            date.setTime(date.getTime() + expireMs);
            if (countryCode === 'global') {
                Cookies.set("NEXT_LOCALE", 'en', {
                    expires: 365, domain: siteUrl,
                })
            } else {
                Cookies.set("NEXT_LOCALE", locale, {
                    expires: 365, domain: siteUrl,
                });
            }
        }

        if (countryCode && (countryCode !== Cookies.get("NEXT_COUNTRY"))) {
            Cookies.set("NEXT_COUNTRY", countryCode, {
                expires: 365, domain: siteUrl,
            });
            saveCountryCode(countryCode);
        }
    };

    useLayoutEffect(persistLocaleCookie, [locale]);
};

export default usePersistLocalizationCookie;
