import styles from "components/modal/IntegratedReservationModal.module.scss";
import {CustomCalendar} from "components/calendar/CustomCalendar";

export const IntegratedReservationModalDateSelector = (props) => {
    const {selectedDate, setSelectedDate, selectedProduct} = props;

    const reservationAvailableDateInfo = selectedProduct?.extra_data?.reservation_available_date_info;

    return (
        <>
            <h2 className={styles.sectionTitleContainer}>첫 방문일자를 선택해주세요</h2>
            <div className={styles.calendarContainer}>
                <CustomCalendar
                    setSelectedDate={setSelectedDate} selectedDate={selectedDate}
                    unavailableDateStrings={reservationAvailableDateInfo?.unavailable_dates}
                    startDate={reservationAvailableDateInfo?.start_date}
                    endDate={reservationAvailableDateInfo?.end_date}
                />
            </div>
        </>
    )
};
