import styles from "components/topTabBar.module.scss";
import newStyles from "components/newTopTabBar.module.scss";
import utilStyles from "styles/utils.module.scss";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";


export default function TopTabBar(props) {
    const tabs = props.tabs || [];
    const {isLoading, activeRoute, smallText, height, gray} = props;
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    return (
        <div className={styles.container}>
            <div className={classNames(utilStyles.flexRow, utilStyles.justifyContentSpaceBetween)}>
                {
                    tabs.map(tab => {
                        const onClick = tab.onClick || function () {
                        }
                        return (
                            <div
                                onClick={!isLoading ? onClick : function () {
                                }}
                                className={
                                    classNames(
                                        styles.tabItemContainer,
                                        activeRoute !== tab.route ? styles.tabItemInactive : styles.tabItemActive,
                                        utilStyles.alignItemsCenter,
                                        utilStyles.justifyContentCenter,
                                        gray && activeRoute !== tab.route && styles.gray,
                                    )
                                }
                                key={tab.route} style={{
                                    fontSize: smallText ? (
                                        isMobile ? 14 : (!isLarge ? 15 : undefined)
                                    ) : undefined,
                                    height,
                                }}
                            >
                                {tab.title}
                                {
                                    tab.subTitle &&
                                    <span className={classNames(styles.subTitle,
                                        activeRoute === tab.route && styles.subTitleActive)}>{tab.subTitle}</span>
                                }
                                {tab.renderChild && tab.renderChild()}
                            </div>
                        );
                    })
                }
            </div>
        </div>
    )
}

export function NewTopTabBar(props) {
    const tabs = props.tabs || [];
    const {isLoading, activeRoute, smallText} = props;
    return (
        <div className={newStyles.container}>
            <div className={newStyles.leftPadding} />
            <div className={classNames(utilStyles.flexRow)}>

                {
                    tabs.map(tab => {
                        const onClick = tab.onClick || function () {}
                        return (
                            <div
                                onClick={!isLoading ? onClick : function () {}}
                                className={
                                    classNames(
                                        newStyles.tabItemContainer,
                                        // utilStyles.alignItemsCenter,
                                        utilStyles.justifyContentCenter,
                                    )
                                }
                                key={tab.route}
                            >
                                <div className={newStyles.itemLeftPadding} />
                                <div className={classNames(newStyles.title, activeRoute !== tab.route ? newStyles.tabItemInactive : newStyles.tabItemActive)}>
                                    <span> {tab.title} </span>
                                </div>
                                <div className={newStyles.itemRightPadding} />
                                {/*{*/}
                                {/*    tab.subTitle &&*/}
                                {/*    <span className={newStyles.subTitle}>{tab.subTitle}</span>*/}
                                {/*}*/}
                            </div>
                        );
                    })
                }
                <div className={newStyles.additionalRightPadding} />
            </div>
            <div className={newStyles.rightPadding} />
        </div>
    )
}

