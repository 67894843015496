import styles from "components/input/input.module.scss";


export default function InputError (props) {
    const {message} = props;
    return (
        <div className={styles.errorMessage} style={{lineHeight: message && message.includes('\n') ? '130%' : undefined}}>
            {message ? message : ''}
        </div>
    );
}
