import {createContext, useContext, useEffect, useState} from "react";


const ReviewScrapContext = createContext(undefined);

export let reviewScrapContextRef = {};

export default ReviewScrapContext;

const ReviewScrapContextWrapper = ({children}) => {
    const context = useContext(ReviewScrapContext);

    useEffect(() => {
        reviewScrapContextRef.current = context;
    }, []);

    useEffect(() => {
        reviewScrapContextRef.current = context;
    }, [context]);

    return(
        <>
            {children}
        </>
    )
}

// TODO: Inspect why render is called twice on context change
export const ReviewScrapContextProvider = ({children}) => {
    const [reviewIdToUpvoteCount, setReviewIdToUpvoteCount] = useState({});
    const [scrapReviewIds, setScrapReviewIds] = useState(new Set());
    const [loadingReviewIds, setLoadingReviewIds] = useState(new Set());

    const isScrapped = (reviewId) => {
        return scrapReviewIds.has(reviewId);
    }

    const addReviewIds = (reviewIds) => {
        if (!reviewIds) {
            return
        }
        setScrapReviewIds(oldSet => {
            return new Set([...oldSet, ...reviewIds]);
        });
    }

    const removeReviewId = (reviewId) => {
        setScrapReviewIds(oldSet => {
            const newSet = new Set(oldSet);
            newSet.delete(reviewId);
            return newSet;
        });
    }

    const updateReviewIds = (reviewIdStatusMap) => {
        setScrapReviewIds(oldSet => {
            const newSet = new Set(oldSet);
            Object.entries(reviewIdStatusMap).map(([reviewId, status]) => {
                if (status) {
                    newSet.add(parseInt(reviewId));
                } else {
                    newSet.delete(parseInt(reviewId));
                }
            });
            return newSet;
        });
    }

    const updateByReviewIdsAndScrappedReviewIds = (reviewIds, scrappedIds) => {
        const scrappedIdsSet = new Set(scrappedIds);

        const unscrappedIds = reviewIds.filter(x => !scrappedIdsSet.has(x));

        setScrapReviewIds(oldSet => {
            const newSet = new Set(oldSet);
            for (const _id of scrappedIds) {
                newSet.add(parseInt(_id));
            }
            for (const _id of unscrappedIds) {
                newSet.delete(parseInt(_id));
            }
            return newSet;
        });
    }

    const setIsLoading = (reviewId, status) => {
        setLoadingReviewIds(oldSet => {
            const newSet = new Set(oldSet);
            if (status) {
                newSet.add(reviewId);
            } else {
                newSet.delete(reviewId);
            }
            return newSet;
        });
    }

    const toggleScrap = (reviewId) => {
        const scrapped = isScrapped(reviewId);
        scrapped ? removeReviewId(reviewId) : addReviewIds([reviewId]);
        setReviewIdToUpvoteCount(oldMap => {
            if (reviewId in oldMap) {
                const newMap = {...oldMap};
                newMap[reviewId] = newMap[reviewId] + (scrapped ? -1 : 1);
                return newMap;
            }
            return oldMap;
        });
    };

    const isLoading = (reviewId) => {
        return loadingReviewIds.has(reviewId);
    };

    const setScrapInfo = (reviews) => {
        setReviewIdToUpvoteCount(oldMap => {
            const newMap = {...oldMap};
            reviews.map(review => {
                newMap[review.id] = review.upvote_count;
            });
            return newMap;
        });
        const upvotedReviewIds = reviews.filter(review => review.has_upvoted).map(review => review.id);
        addReviewIds(upvotedReviewIds);
    };

    const getUpvoteCount = (reviewId) => {
        if (reviewId in reviewIdToUpvoteCount) {
            return reviewIdToUpvoteCount[reviewId];
        }
        return 0;
    };

    const contextValue = {
        isScrapped, addReviewIds, removeReviewId, updateReviewIds, isLoading,
        setIsLoading, updateByReviewIdsAndScrappedReviewIds, reviewIdToUpvoteCount, toggleScrap,
        setScrapInfo, getUpvoteCount,
    };

    return (
        <ReviewScrapContext.Provider value={contextValue}>
            <ReviewScrapContextWrapper>
                {children}
            </ReviewScrapContextWrapper>
        </ReviewScrapContext.Provider>
    )
}
