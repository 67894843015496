import NotificationIcon from "images/icons/notification.svg";
import CheckIcon from "images/icons/check_grey.svg";
import classNames from "classnames";
import styles from "components/buttons/SubscriptionButton.module.scss";
import useTranslation from 'next-translate/useTranslation';

export const SubscriptionButton = (props) => {
    const {isSubscribed, onClick} = props;
    const { t } = useTranslation('components-buttons-SubscriptionButton');

    const handleOnClick = (e) => {
        if (onClick) {
            onClick();
        }
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <button
            className={classNames(styles.buttonContainer, isSubscribed && styles.subscribedButtonContainer)}
            onClick={handleOnClick}
        >
            {
                isSubscribed ?
                    <CheckIcon/> :
                    <NotificationIcon viewBox="0 0 24 24" className={classNames(styles.icon)}/>
            }
            <span className={classNames(styles.text, isSubscribed && styles.subscribedText)}>
                {isSubscribed ? t('SubscriptionButton.subscribed') : t('SubscriptionButton.subscribe')}
            </span>
        </button>
    )
};

export const ProductOpenSubscriptionButton = (props) => {
    const {isSubscribed, onClick} = props;
    const { t } = useTranslation('components-buttons-SubscriptionButton');

    const handleOnClick = (e) => {
        if (onClick) {
            onClick();
        }
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <button
            className={classNames(styles.productOpenButtonContainer, isSubscribed && styles.subscribedButtonContainer)}
            onClick={handleOnClick}
        >
            {
                isSubscribed ?
                    <CheckIcon/> :
                    <NotificationIcon viewBox="0 0 24 24" className={classNames(styles.icon)}/>
            }
            <span className={classNames(styles.productOpenText, isSubscribed && styles.subscribedText)}>
                {isSubscribed ? t('ProductOpenSubscriptionButton.subscribed') : t('ProductOpenSubscriptionButton.subscribe')}
            </span>
        </button>
    )
};
