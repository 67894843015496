export const KOREA_COUNTRY_CODE = 'KR';
export const US_COUNTRY_CODE = 'US';
export const JAPAN_COUNTRY_CODE = 'JP';
export const GLOBAL_COUNTRY_CODE = 'GLOBAL';


const getCountryCodeFromHost = (host) => {
    console.log('getCountryCodeFromHost', host);
    let countryCode;

    if (host === process.env.GLOBAL_DOMAIN) {
        countryCode = GLOBAL_COUNTRY_CODE;
    } else if (host === process.env.US_DOMAIN) {
        countryCode = US_COUNTRY_CODE;
    } else if (host === process.env.KR_DOMAIN) {
        countryCode = KOREA_COUNTRY_CODE;
    } else if (host === process.env.JP_DOMAIN) {
        countryCode = JAPAN_COUNTRY_CODE;
    } else {
        countryCode = KOREA_COUNTRY_CODE;
    }

    return countryCode;
};

export const getLocalizationInfoInSSR = (context) => {
    console.log('context.req.headers.host', context.req.headers.host, context.req.headers.referer, context.req.headers);
    const target = context.req.headers.referer || context.req.headers['x-forwarded-host'] || context.req.headers.host;
    const host = target ?
        (target.split('/').length > 1 ? target.split('/')[2].replace('https://', '') : target) :
        null;
    console.log('host', host);
    const locale = context.locale;
    const countryCode = getCountryCodeFromHost(host);

    return {
        countryCode,
        locale
    }
};

export const getLocalizationInfoInCSR = () => {
    console.log('window.location.host', window.location.host);
    const host = window.location.host.split('/')[0].replace('https://', '');

    const countryCode = getCountryCodeFromHost(host);

    return {
        countryCode,
    }
};

export const setCountryCodeInCookieSSR = (context) => {
    const {countryCode} = getLocalizationInfoInSSR(context);
    // Get the original cookie string
    const originalCookie = context.req.headers.cookie || '';

    // Parse the cookie string into an object
    const cookies = {};
    originalCookie.split(';').forEach(cookie => {
        const [key, value] = cookie.trim().split('=');
        cookies[key] = value;
    });

    // Modify the cookie value
    cookies['NEXT_COUNTRY'] = countryCode;

    // Reconstruct the cookie string
    const newCookieString = Object.entries(cookies)
        .map(([key, value]) => `${key}=${value}`)
        .join('; ');

    // Set the modified cookie string back to the headers
    context.req.headers.cookie = newCookieString;
    return {countryCode}
};

export const forceCountryCodeInCookieSSR = (context, countryCode) => {
    if (!context.req || !context.req.headers) {
        return;
    }
    const originalCookie = context.req.headers.cookie || '';
    // Modify the cookie value
    // Parse the cookie string into an object
    const cookies = {};
    originalCookie.split(';').forEach(cookie => {
        const [key, value] = cookie.trim().split('=');
        cookies[key] = value;
    });
    cookies['NEXT_COUNTRY'] = countryCode;

    // Reconstruct the cookie string
    const newCookieString = Object.entries(cookies)
        .map(([key, value]) => `${key}=${value}`)
        .join('; ');

    // Set the modified cookie string back to the headers
    context.req.headers.cookie = newCookieString;
    return {countryCode}
};
