import {createContext, useContext, useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";

const ToastContext = createContext(undefined);

export let toastContextRef = {};

export default ToastContext;

const ToastContextWrapper = ({children}) => {
    const context = useContext(ToastContext);

    useEffect(() => {
        toastContextRef.current = context;
    }, []);

    useEffect(() => {
        toastContextRef.current = context;
    }, [context]);

    return(
        <>
            {children}
        </>
    )
}

export const ToastContextProvider = ({children}) => {
    const toastIdRef = useRef(null);

    const registerToast = (id) => {
        toastIdRef.current = id;
        setTimeout(() => {
            closeToastById(id);
        }, 1000);
    };

    const closeToastById = (id) => {
        if (toastIdRef.current === id) {
            closeToast();
        }
    };

    const closeToast = () => {
        if (!Boolean(toastIdRef.current)) {
            return;
        }
        toast.dismiss(toastIdRef.current);
        toastIdRef.current = null;
    };

    const closeAndOpenToast = (message, options) => {
        closeToast();
        const id = toast.info(message, options);
        registerToast(id);
    };

    const info = (message, options) => {
        closeAndOpenToast(message, options);
    };

    const contextValue = {
        registerToast, closeToast, closeAndOpenToast, info
    };

    return (
        <ToastContext.Provider value={contextValue}>
            <ToastContextWrapper>
                {children}
            </ToastContextWrapper>
        </ToastContext.Provider>
    )
}
