import styles from 'components/cards/coupon/DownloadableCouponCard.module.scss';
import DownloadIcon from "images/icons/download.svg";
import classNames from "classnames";
import {Axios} from "api";
import {redirectToApp, redirectToLogin} from "common/redirect";
import {captureException, captureMessage} from "@sentry/nextjs";
import {toast} from "react-toastify";
import {useState, useContext} from "react";
import {useRouter} from "next/router";
import {COUPON_TYPE_PERCENTAGE} from "components/cards/CheckoutCouponCard";
import UserContext from "context/AuthContext";
import {
    customAlert,
    customConfirm,
    formatDate,
    getCurrentTimeKSTString,
    getExpiresAtDateFromDMY,
    isAndroid,
    isApp,
    isIOS, numberWithComma,
} from "common/utils";
import CouponDownloadIcon from "images/icons/coupon_download.svg";
import utilStyles from "styles/utils.module.scss";
import CheckIcon from "images/icons/check_grey.svg";



export const handleCouponDownload = async (couponMold, isLoading, setIsLoading, onSuccess, router, user) => {
    typeof mixpanel !== 'undefined' && mixpanel.track('DownloadableCouponCardCouponDownload', {
        mold_key: couponMold.mold_key,
        is_app_only: couponMold.is_app_only,
    });

    if (isLoading) {
        return;
    }

    if (!couponMold.is_obtainable) {
        toast.info('이미 다운받은 쿠폰이에요!');
        return;
    }

    if (couponMold.is_app_only && !isApp()) {
        if (isIOS() || isAndroid()) {
            customConfirm('앱 전용 쿠폰이 포함되어 있어요. 다운 받으러 갈까요?', '확인', '취소', () => {
                redirectToApp();
            }, () => {});
        } else {
            customAlert('앱 전용 쿠폰이 포함되어 있어요. 모바일 환경에서 다시 시도해주세요!', '확인', () => {});
        }
        return;
    }

    if (!user) {
        redirectToLogin(router, true);
        return;
    }

    setIsLoading(true);
    try {
        const data = {
            mold_key: couponMold.mold_key,
        }
        const res = await Axios.post('v1/coupons/download', data);
        if (res.status < 400) {
            onSuccess(res);
        } else if (res.status === 401) {
            close();
            redirectToLogin(router, true);
        } else {
            captureMessage(JSON.stringify(res.data));
            toast.info(res.data.display_message || '일시적인 오류로 쿠폰 다운로드에 실패했습니다. 잠시 후 시도해주세요.');
        }
    } catch (e) {
        captureException(e);
        toast.info('일시적인 오류로 쿠폰 다운로드에 실패했습니다. 잠시 후 다시 시도해주세요.');
    } finally {
        setIsLoading(false);
    }
};

export const getConditionText = (couponMold) => {
    const couponAmount = couponMold.type === COUPON_TYPE_PERCENTAGE ? `${couponMold.amount}%` : `${numberWithComma(couponMold.amount)}원`;

    const couponExpiresAt = couponMold.expiration_type === 'fixed_date' ?
        `${formatDate(couponMold.expires_at, false)} 까지` :
        `발급일로부터 ${getExpiresAtDateFromDMY(couponMold.valid_day, couponMold.valid_month, couponMold.valid_year)}`;

    const condition = (
        couponMold.min_amount ?
            (couponMold.max_amount ?
                    `${numberWithComma(couponMold.min_amount)}원 이상 구매 시 (최대 ${numberWithComma(couponMold.max_amount)}원 할인)` :
                    `${numberWithComma(couponMold.min_amount)}원 이상 구매 시`
            ) :
            couponMold.max_amount ?
                `최대 ${numberWithComma(couponMold.max_amount)}원 할인` :
                null
    );
    return {
        couponAmount,
        couponExpiresAt,
        condition,
    }
}

const DownloadableCouponCard = (props) => {
    const {couponMold, onSuccess, hasDownloaded} = props;
    const isInactive = hasDownloaded || !couponMold.is_obtainable;

    const [isLoading, setIsLoading] = useState(false);
    const router = useRouter();
    const user = useContext(UserContext).user;

    const _handleCouponDownload = () => {
        handleCouponDownload(couponMold, isLoading, setIsLoading, onSuccess, router, user);
    }

    const {couponAmount, couponExpiresAt, condition} = getConditionText(couponMold);

    return (
        <div
            className={classNames(styles.root, isInactive && styles.inactiveRoot)}
            onClick={_handleCouponDownload}
        >
            <div className={styles.content}>
                <div className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter, (
                    couponMold.is_app_only || couponMold.is_gift_only || couponMold.is_duplication_allowed
                ) ? styles.tagsContainer : undefined, isInactive && styles.inactiveTagsContainer)}>
                    {
                        couponMold.is_app_only &&
                        <div className={
                            classNames(styles.appOnlyTag)
                        }>
                            앱전용
                        </div>
                    }
                    {
                        couponMold.is_gift_only &&
                        <div className={styles.giftOnlyLabel}>
                            선물전용
                        </div>
                    }
                    {
                        couponMold.is_duplication_allowed &&
                        <div className={styles.duplicateAllowedLabel}>
                            무제한발급
                        </div>
                    }
                </div>
                <div className={styles.title}>
                    <div className={classNames(styles.discountAmount, isInactive && styles.inactiveTitle)}>
                        {couponAmount}
                    </div>
                </div>
                <div className={styles.descriptionContainer}>
                    {/*{*/}
                    {/*    couponMold.is_brand_coupon &&*/}
                    {/*    <span className={classNames(styles.brandCoupon, isInactive && styles.inactiveText)}>[브랜드 쿠폰]</span>*/}
                    {/*}*/}
                    <span
                        className={classNames(styles.description, isInactive && styles.inactiveDescription)}>{couponMold.name}</span>
                </div>
                {
                    condition &&
                    <div className={classNames(styles.conditionText, isInactive && styles.inactiveConditionText)}>
                        {condition}
                    </div>
                }
                <div className={classNames(styles.expiresAt, condition && styles.expiresAtWithCondition)}>
                    {couponExpiresAt}
                </div>
            </div>
            <div className={classNames(styles.downloadButton, isInactive && styles.downloadButtonInactive)}>
                {
                    hasDownloaded ?
                        <CheckIcon viewBox="0 0 16 16" className={styles.checkIcon}/> :
                        <DownloadIcon viewBox="0 0 19 18"
                                      className={classNames(styles.downloadIcon, isInactive && styles.inactiveDownloadIcon)}/>
                }
            </div>
        </div>
    )
};

export default DownloadableCouponCard;
