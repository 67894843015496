import Layout from "components/layout/layout";
import Script from "next/script";
import IamportScript from "components/IamportScript";


export default function IamportLayout(page) {
    return (
        <>
            <IamportScript />
            <Layout>
                {page}
            </Layout>
        </>
    )
}
