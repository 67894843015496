import styles from "components/orderStatusInfo.module.scss";
import utilStyles from 'styles/utils.module.scss';
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";

import ArrowRightIcon from "images/icons/arrow_right_sharp.svg";
import AlertIcon from "images/icons/alert.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function OrderStatusInfo() {
    const { t } = useTranslation('components-OrderStatusInfo');
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const isDesktop = useMediaQuery(`(max-width:${utilStyles.breakpointDesktop})`);

    return (
        <div>
            <div className={styles.titleWrapper}>
                <AlertIcon className={styles.verticalMiddle} />
                <span className={styles.title}>{t('OrderStatusInfo.title')}</span>
            </div>
            <div className={styles.container}>
                <div className={styles.contentContainer}>
                    <div className={styles.noLineHeight}>
                        <span className={styles.index}>01.</span>
                        <span className={styles.state}>{t('OrderStatusInfo.orderReceived')}</span>
                    </div>
                    <span className={styles.content}>
                        <Trans
                            i18nKey="components-OrderStatusInfo:OrderStatusInfo.orderReceivedDesc"
                            components={[!isDesktop ? <br key="br"/> : <span key="span"/>]}
                        />
                    </span>
                </div>
                {
                    !isDesktop ?
                        <ArrowRightIcon />
                        :
                        <div className={styles.horizontalBorder} />
                }
                <div className={styles.contentContainer}>
                    <div className={styles.noLineHeight}>
                        <span className={styles.index}>02.</span>
                        <span className={styles.state}>{t('OrderStatusInfo.paymentComplete')}</span>
                    </div>
                    <span className={styles.content}>
                        <Trans
                            i18nKey="components-OrderStatusInfo:OrderStatusInfo.paymentCompleteDesc"
                            components={[!isDesktop ? <br key="br"/> : <span key="span"/>]}
                        />
                    </span>
                </div>
                {
                    !isDesktop ?
                        <ArrowRightIcon />
                        :
                        <div className={styles.horizontalBorder} />
                }
                <div className={styles.contentContainer}>
                    <div className={styles.noLineHeight}>
                        <span className={styles.index}>03.</span>
                        <span className={styles.state}>{t('OrderStatusInfo.preparing')}</span>
                    </div>
                    <span className={styles.content}>
                        <Trans
                            i18nKey="components-OrderStatusInfo:OrderStatusInfo.preparingDesc"
                            components={[!isDesktop ? <br key="br"/> : <span key="span"/>]}
                        />
                    </span>
                </div>
                {
                    !isDesktop ?
                        <ArrowRightIcon />
                        :
                        <div className={styles.horizontalBorder} />
                }
                <div className={styles.contentContainer}>
                    <div className={styles.noLineHeight}>
                        <span className={styles.index}>04.</span>
                        <span className={styles.state}>{t('OrderStatusInfo.shipping')}</span>
                    </div>
                    <span className={styles.content}>
                        <Trans
                            i18nKey="components-OrderStatusInfo:OrderStatusInfo.shippingDesc"
                            components={[!isDesktop ? <br key="br"/> : <span key="span"/>]}
                        />
                    </span>
                </div>
                {
                    !isDesktop ?
                        <ArrowRightIcon />
                        :
                        <div className={styles.horizontalBorder} />
                }
                <div className={styles.contentContainer}>
                    <div className={styles.noLineHeight}>
                        <span className={styles.index}>05.</span>
                        <span className={styles.state}>{t('OrderStatusInfo.delivered')}</span>
                    </div>
                    <span className={styles.content}>
                        {t('OrderStatusInfo.deliveredDesc')}
                    </span>
                </div>
            </div>
        </div>
    )
}
