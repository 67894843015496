import {useContext} from "react";
import ProductScrapContext, {productScrapContextRef} from "context/ProductScrapContext";
import {Axios} from "api";
import UserContext from "context/AuthContext";
import {redirectToLogin} from "common/redirect";
import {useRouter} from "next/router";
import * as Sentry from "@sentry/nextjs";
import {trackPixel} from "api/pixel";
import styles from 'components/buttons/productScrapButton.module.scss';
import classNames from "classnames";
import utilStyles from "styles/utils.module.scss";
import {showRewardToast} from "common/utils";

let requestAfterTimeout;

export default function ProductScrapButton(props) {
    const {productId, renderScrapped, renderUnscrapped, addPadding} = props;
    const ScrappedComponent = renderScrapped;
    const UnscrappedComponent = renderUnscrapped;
    const user = useContext(UserContext).user;

    const router = useRouter();

    const scrapContext = useContext(ProductScrapContext);
    const onScrapClick = props.onScrapClick || function () {};

    const sendRequest = async (isScrapped) => {
        // consider contextRef.current is not updated due to the component unmount
        // const isScrapped = scrapContextRef.current.isScrapped(productId);
        const data = {'product_id': productId, 'is_scrapped': isScrapped};
        try {
            productScrapContextRef.current.setIsLoading(productId, true);
            if (isScrapped) {
                const data = {
                    content_ids: productId,
                }
                trackPixel('AddToWishlist', data);
            }
            const res = isScrapped
                ? await Axios.post(`v1/store/scraps/`, data)
                : await Axios.delete(`v1/store/scraps/`, {params: data});
            if (res.status < 400) {
                if (res.data.given_reward) {
                    showRewardToast(router, res.data.given_reward.key, res.data.given_reward.point);
                }
            } else {
                // alert('스크랩 설정에 실패했습니다. 잠시 후 다시 시도해주세요.');
            }
        } catch(e) {
            // alert('스크랩 설정에 실패했습니다. 잠시 후 다시 시도해주세요.');
            Sentry.captureException(e);
        } finally {
            productScrapContextRef.current.setIsLoading(productId, false);
        }
    }

    const sendRequestIfNotLoading = async (isScrapped) => {
        if (!productScrapContextRef.current.isLoading(productId)) {
            sendRequest(isScrapped);
            requestAfterTimeout = null;
        }
        else {
            requestAfterTimeout = setTimeout(() => {
                sendRequestIfNotLoading(isScrapped);
            }, 200);
        }
    }

    const setRequest = (isScrapped) => {
        if (requestAfterTimeout) {
            clearTimeout(requestAfterTimeout);
        }
        requestAfterTimeout = setTimeout(() => {
            sendRequestIfNotLoading(isScrapped);
        }, 200);
    }


    const scrap = () => {
        scrapContext.addProductIds([productId]);
        setRequest(true);
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductScrap', {
            productId,
        });
        onScrapClick(true);
    };

    const unscrap = () => {
        scrapContext.removeProductId(productId);
        setRequest(false);
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductUnscrap', {
            productId,
        });
        onScrapClick(false);
    }

    const onClick = scrapContext.isScrapped(productId) ? unscrap : scrap;

    return (
        <div
            className={classNames(utilStyles.noLineHeight, addPadding ? styles.wrapper : undefined)}
            onClick={(e) => {
            if (user) {
                onClick();
            } else {
                redirectToLogin(router, true);
            }
            e.stopPropagation();
            e.preventDefault();
        }}>
            {
                scrapContext.isScrapped(productId)
                    ? <ScrappedComponent />
                    : <UnscrappedComponent />
            }
        </div>
    )
}
