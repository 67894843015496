import {Axios} from "api";
import {redirectToLogin} from "common/redirect";
import {isApp} from "common/utils";
import {useContext, useEffect, useState} from "react";
import PushContext from "context/PushContext";
import ModalContext from "context/ModalContext";
import UserContext from "context/AuthContext";
import {useRouter} from "next/router";
import {newCustomConfirm} from "common/newCustomConfirm";
import {toast} from "react-toastify";


// notiKeyToIsSubscribed: noti key 정보와 미리 fetch 한 isSubscribed 정보를 가진 object
// key 는 Noti type 과 key 가 @ 로 이어진 string
// ex. custom_noti_topic@daily_reward, event@10
const useNotiSubscription = (_notiTypeAndKeyToIsSubscribed, onSubscribe = null, onUnsubscribe = null, contextName = null) => {
    const router = useRouter();
    const pushContext = useContext(PushContext);
    const userContext = useContext(UserContext);
    const user = userContext.user;

    const {openAppInstallModal} = useContext(ModalContext);

    const [notiTypeAndKeyToIsSubscribed, setNotiTypeAndKeyToIsSubscribed] = useState(_notiTypeAndKeyToIsSubscribed);
    const [notiTypeAndKeyToIsSubscribedAndPushOn, setNotiTypeAndKeyToIsSubscribedAndPushOn] = useState({});

    const [lastPushAgreeRequestKey, setLastPushAgreeRequestKey] = useState(null);

    const isPushOn = pushContext.isDevicePushOn && pushContext.isMarketingAgreed;

    useEffect(() => {
        if (!isPushOn) {
            setNotiTypeAndKeyToIsSubscribedAndPushOn(
                Object.assign({}, ...Object.keys(notiTypeAndKeyToIsSubscribed).map(key => ({[key]: false})))
            );
        } else {
            setNotiTypeAndKeyToIsSubscribedAndPushOn({...notiTypeAndKeyToIsSubscribed});
            if (lastPushAgreeRequestKey) {
                subscribe(lastPushAgreeRequestKey);
            }
        }

    }, [isPushOn, lastPushAgreeRequestKey, notiTypeAndKeyToIsSubscribed]);

    const overrideIsSubscribed = (_keyToIsSubscribed) => {
        setNotiTypeAndKeyToIsSubscribed(_keyToIsSubscribed);
    };

    const subscribe = async (notiTypeAndKey) => {
        setLastPushAgreeRequestKey(null);
        try {
            const [notiType, notiKey] = notiTypeAndKey.split('@');
            const res = await Axios.post('v1/noti/subscription/', {
                type: notiType,
                noti_key: notiKey,
            });
            if (res.status < 400) {
                setNotiTypeAndKeyToIsSubscribed({
                    ...notiTypeAndKeyToIsSubscribed,
                    [notiTypeAndKey]: true,
                });
                if (onSubscribe) {
                    onSubscribe(notiTypeAndKey);
                }
            } else if (res.status === 401) {
                redirectToLogin(router, true);
            } else {
                alert('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
            }
        } catch (e) {
            alert('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
            console.log(e)
        }
    };

    const unsubscribe = async (notiTypeAndKey) => {
        setLastPushAgreeRequestKey(null);
        try {
            const [notiType, notiKey] = notiTypeAndKey.split('@');
            console.log('unsubscribe', notiType, notiKey);
            const res = await Axios.delete('v1/noti/subscription/', {
                params: {
                    type: notiType,
                    noti_key: notiKey,
                }
            });
            if (res.status < 400) {
                setNotiTypeAndKeyToIsSubscribed({
                    ...notiTypeAndKeyToIsSubscribed,
                    [notiTypeAndKey]: false,
                });
                if (onUnsubscribe) {
                    onUnsubscribe(notiTypeAndKey);
                }
            } else if (res.status === 401) {
                redirectToLogin(router, true);
            } else {
                alert('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
            }
        } catch (e) {
            alert('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
            console.log(e)
        }
    };

    const toggleSubscription = async (notiTypeAndKey) => {
        const inApp = isApp();
        const [notiType, notiKey] = notiTypeAndKey.split('@');
        const isSubscribed = notiTypeAndKeyToIsSubscribed[notiTypeAndKey] === true;
        const isSubscribedAndPushOn = isSubscribed && isPushOn;
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'NotiSubscriptionToggle',
            {
                notiType,
                notiKey,
                isSubscribed,
                isPushOn,
                contextName,
            }
        );
        if (!user) {
            if (!inApp) {
                toast.info("앱에서만 알림 설정이 가능해요.", {autoClose: 500});
                openAppInstallModal();
                return;
            }
            redirectToLogin(router, true);
            return;
        }

        if (isSubscribedAndPushOn) {
            await unsubscribe(notiTypeAndKey);
            return;
        }

        if (!inApp) {
            toast.info("앱에서만 알림 설정이 가능해요.", {autoClose: 500});
            openAppInstallModal();
        } else {
            if (isPushOn) {
                await subscribe(notiTypeAndKey);
            } else {
                newCustomConfirm(
                    '헤메코랩 소식을 받아보시겠어요?',
                    null,
                    '좋아요', '다음에요',
                    () => {
                        typeof mixpanel !== 'undefined' && mixpanel.track(
                            'NotiSubscriptionToggleAgree',
                            {
                                contextName,
                            }
                        );
                        setLastPushAgreeRequestKey(notiTypeAndKey);
                        pushContext.sendPushAgreementRequest(true);
                    },
                    () => {
                        typeof mixpanel !== 'undefined' && mixpanel.track(
                            'NotiSubscriptionToggleDisagree',
                            {
                                contextName,
                            }
                        );
                    }
                );
            }
        }
    };

    return {notiTypeAndKeyToIsSubscribedAndPushOn, toggleSubscription, overrideIsSubscribed}

};

export default useNotiSubscription;
