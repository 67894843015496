import styles from "components/input/input.module.scss";
import utilStyles from "styles/utils.module.scss";
import classNames from "classnames";
import {useEffect} from "react";
import PlusIcon from "images/icons/plus_brand.svg";
import Image from "next/image";
import CloseIcon from "images/icons/close.svg";

const ImageUploadPreview = (props) => {
    const {src} = props;
    const onDelete = props.onDelete || function () {};

    return (
        <div className={styles.imagePreviewWrapper} >
            <img className={styles.imagePreview} alt="문의 이미지" src={src}  />
            <div className={styles.closeButton} onClick={(e) => {
                onDelete();
            }}>
                <CloseIcon viewBox="0 0 20 20" width="15" height="15" />
            </div>
        </div>
    )
}

export default ImageUploadPreview;