import {createContext, useContext, useEffect, useState} from "react";


const ProductScrapContext = createContext(undefined);

export let productScrapContextRef = {};

export default ProductScrapContext;

const ProductScrapContextWrapper = ({children}) => {
    const context = useContext(ProductScrapContext);

    useEffect(() => {
        productScrapContextRef.current = context;
    }, []);

    useEffect(() => {
        productScrapContextRef.current = context;
    }, [context]);

    return(
        <>
            {children}
        </>
    )
}

// TODO: Inspect why render is called twice on context change
export const ProductScrapContextProvider = ({children}) => {
    const [scrapProductIds, setScrapProductIds] = useState(new Set());
    const [loadingProductIds, setLoadingProductIds] = useState(new Set());

    const isScrapped = (productId) => {
        return scrapProductIds.has(productId);
    }

    const addProductIds = (productIds) => {
        if (!productIds) {
            return
        }
        setScrapProductIds(oldSet => {
            return new Set([...oldSet, ...productIds]);
        });
    }

    const removeProductId = (productId) => {
        setScrapProductIds(oldSet => {
            const newSet = new Set(oldSet);
            newSet.delete(productId);
            return newSet;
        });
    }

    const updateProductIds = (productIdStatusMap) => {
        setScrapProductIds(oldSet => {
            const newSet = new Set(oldSet);
            Object.entries(productIdStatusMap).map(([productId, status]) => {
                if (status) {
                    newSet.add(parseInt(productId));
                } else {
                    newSet.delete(parseInt(productId));
                }
            });
            return newSet;
        });
    }

    const updateByProductIdsAndScrappedProductIds = (productIds, scrappedIds) => {
        const scrappedIdsSet = new Set(scrappedIds);

        const unscrappedIds = productIds.filter(x => !scrappedIdsSet.has(x));

        setScrapProductIds(oldSet => {
            const newSet = new Set(oldSet);
            for (const _id of scrappedIds) {
                newSet.add(parseInt(_id));
            }
            for (const _id of unscrappedIds) {
                newSet.delete(parseInt(_id));
            }
            return newSet;
        });
    }

    const setIsLoading = (productId, status) => {
        setLoadingProductIds(oldSet => {
            const newSet = new Set(oldSet);
            if (status) {
                newSet.add(productId);
            } else {
                newSet.delete(productId);
            }
            return newSet;
        });
    }

    const isLoading = (productId) => {
        return loadingProductIds.has(productId);
    }

    const contextValue = {
        isScrapped, addProductIds, removeProductId, updateProductIds, isLoading, setIsLoading,
        updateByProductIdsAndScrappedProductIds,
    };

    return (
        <ProductScrapContext.Provider value={contextValue}>
            <ProductScrapContextWrapper>
                {children}
            </ProductScrapContextWrapper>
        </ProductScrapContext.Provider>
    )
}