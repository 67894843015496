import styles from "components/pages/ProductDetail.module.scss";
import {forwardRef, useRef, useState} from "react";
import Image from "next/image";
import classNames from "classnames";
import utilStyles from "styles/utils.module.scss";
import commonStyles from "components/sections/product/index.module.scss";
import ArrowDown from "images/icons/arrow_down.svg";
import ProductInfoTitle from "components/sections/product/ProductInfoTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useInView} from "react-intersection-observer";
import YoutubePlayer from "components/media/youtube";
import ReactPlayer from "react-player";
import DOMPurify from "isomorphic-dompurify";
import useTranslation from "next-translate/useTranslation";

export const LazyImage = (props) => {
    const {eager, src, style, onLoad} = props;
    const { ref, inView, entry } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: '0px 0px 200px 0px',
        fallbackInView: true,
    });

    const lazy = !eager && !inView;

    const [vimeoSize, setVimeoSize] = useState({width: 1, height: 1});

    if (lazy) {
        return (
            <div
                ref={ref}
                style={{zIndex: 0, width: '100%', paddingBottom: '100%', height: 0,}}
            />
        )
    }

    if (src && (src.includes('</') || src.includes('/>') || (src.includes('<') && src.includes('>')))) {
        return (
            <div dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(src),
            }} className={styles.htmlWrapper} />
        );
    } else if (src && src.includes('youtube.com')) {
        return (
            <div style={{paddingBottom: '56.25%', height: 0, width: '100%', position: "relative"}}>
                <div style={{width: '100%', height: '100%', position: 'absolute'}}>
                    <ReactPlayer
                        url={src}
                        width={'100%'} height={`100%`} loop
                        controls
                        // playing={}
                        volume={1}
                    />
                </div>
            </div>
        )
    } else if (src && (src.endsWith('.mp4') || src.endsWith('.mov'))) {
        return (
            // <div style={{paddingBottom: '56.25%', height: 0, width: '100%', position: "relative"}}>
            //     <div style={{width: '100%', height: '100%', position: 'absolute'}}>
                    <ReactPlayer
                        autoPlay
                        playsinline
                        url={src}
                        playing
                        className={styles.videoWrapper}
                        width={'100%'}
                        height={null}
                        loop
                        controls={false}
                        muted
                        // volume={1}
                    />
                // </div>
            // </div>
        )
    } else if (src && (src.includes('.vimeo.com'))) {
        return (
            <div className={styles.vimeoWrapper} style={{
                paddingTop: `${(vimeoSize.height / vimeoSize.width * 100).toFixed(3)}%`,
            }}>
                <div className={styles.vimeoAbsolute}>
                    <ReactPlayer
                        onReady={(e) => {
                            const player = e.getInternalPlayer();
                            Promise.all([player.getVideoWidth(), player.getVideoHeight()]).then(dimensions => {
                                const [width, height] = dimensions;
                                setVimeoSize({
                                    width, height,
                                })
                            });
                        }}
                        autoPlay
                        playsinline
                        url={src}
                        playing
                        className={styles.videoWrapper}
                        width={'100%'}
                        height={'100%'}
                        loop={true}
                        controls={false}
                        muted
                    />
                </div>
            </div>
        )
    }
    else {
        return <img
            onLoad={onLoad}
            src={src}
            style={Object.assign({}, {
                zIndex: 0,
                // width: !lazy ? undefined : '100%',
                width: '100%',
                paddingBottom: !lazy ? undefined : '100%',
                height: !lazy ? undefined : 0,
            }, style)}
        />
    }
}

const ProductInfoSection = forwardRef((props, ref) => {
    const { t } = useTranslation('components-sections-product-ProductInfoSection');
    const imageExpanded = props.isExpanded || false;
    const { product } = props;
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);

    const onExpandClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailInfoSectionExpandClick',
            {productId: product.id, productName: product.name, expand: !imageExpanded}
        );
        if (props.toggleExpanded) {
            props.toggleExpanded();
        }
    };

    return (
        <div ref={ref} className={classNames(commonStyles.infoSection, styles.productInfoSection)} >
            <ProductInfoTitle title={t('ProductInfoSection.productInfo')} />
            <div  className={styles.imageInfoContainer} >
                <div style={{maxHeight: imageExpanded ? undefined : 800, overflow: 'hidden'}}>
                    {
                        product.info_image_urls.map((url, idx) => {
                            if (!imageExpanded && idx !== 0) {
                                return <div key={`${url}${idx}`} />
                            }
                            return (
                                <LazyImage
                                    eager={idx === 0}
                                    key={`${url}${idx}`}
                                    src={url}
                                />
                            )
                        })
                    }
                </div>
                <div
                    className={
                        classNames(styles.showMore, isLarge ? styles.showMoreLarge : undefined, utilStyles.justifyContentCenter, utilStyles.alignItemsCenter, utilStyles.cursorPointer)
                    }
                    onClick={onExpandClick}
                >
                    {t(`ProductInfoSection.${imageExpanded ? "showLess" : "showMore"}`)}
                    <ArrowDown viewBox="0 0 24 24" width="20" height="20" style={{marginLeft: 4, transform: imageExpanded ? "scaleY(-1)": null}} />
                </div>
            </div>
        </div>
    );
});


export default ProductInfoSection;
