import {createContext, useContext, useEffect, useRef, useState} from "react";
import {Axios} from "api";
import {productScrapContextRef} from "context/ProductScrapContext";
import {postScrapContextRef} from "context/PostScrapContext";
import FeedOptionModal from "components/modal/FeedOptionModal";
import {captureException, captureMessage} from "@sentry/nextjs";


const PostListContext = createContext(undefined);

export let postListContextRef = {};

export default PostListContext;

const PostListContextWrapper = ({children}) => {
    const context = useContext(PostListContext);

    useEffect(() => {
        postListContextRef.current = context;
    }, []);

    useEffect(() => {
        postListContextRef.current = context;
    }, [context]);

    return (
        <>
            {children}
        </>
    )
}

export const CHUNK_SIZE = 10;

export const initialScrollInfo = {
    cursors: [undefined],
    itemLength: 0,
    finalScroll: 0,
    idxToHeight: {},
    hasNext: true,
    // ordering: [...Array(20).keys()].sort(() => Math.random() - 0.5),
    ordering: [...Array(20).keys()],
};

const initialFeedModalTarget = {
    option: null,
    postId: null,
    referralCode: null,
}

export const PostListContextProvider = ({children}) => {
    const [queryToPostList, setQueryToPostList] = useState({});
    const queryToPostListRef = useRef({});
    const queryToScrollInfo = useRef({});
    const [feedOptionModalTarget, setFeedOptionModalTarget] = useState(initialFeedModalTarget);
    const requestedIndexInfo = useRef({});
    const [shortsList, setShortsList] = useState([]);
    const [reportedPostIds, setReportedPostIds] = useState(new Set());

    const getScrollInfo = (query) => {
        if (!queryToScrollInfo.current[query]) {
            queryToScrollInfo.current[query] = JSON.parse(JSON.stringify(initialScrollInfo));
        }
        return queryToScrollInfo.current[query];
    };
    const reset = (query, cb) => {
        requestedIndexInfo.current[query] = [];
        queryToPostListRef.current[query] = {};
        setQueryToPostList(queryToPostListRef.current);
        cb();
    };

    const loadMoreRows = async (query, startIndex, stopIndex) => {
        const startPage = Math.floor(startIndex / CHUNK_SIZE);
        const stopPage = Math.floor(stopIndex / CHUNK_SIZE);
        const promises = [];
        const scrollInfo = getScrollInfo(query);
        if (requestedIndexInfo.current[query] === undefined) {
            requestedIndexInfo.current[query] = [];
        }
        const _requestedPages = requestedIndexInfo.current[query];

        const newlyRequestedPages = [];
        for (let page = startPage; page <= stopPage; page++) {
            if (!_requestedPages.includes(page)) {
                newlyRequestedPages.push(page);
            }
        }
        console.log('loadMoreRows', query, startIndex, stopIndex, queryToPostListRef.current[query], newlyRequestedPages, scrollInfo, requestedIndexInfo.current[query]);

        for (let page of newlyRequestedPages) {
            const cursor = scrollInfo.cursors[page];
            if (page !== 0 && !cursor) {
                // console.log('loadMoreRows continue', query, page, scrollInfo, cursor);
                continue;
            }
            requestedIndexInfo.current[query].push(page)
            try {
                const res = await Axios.get('/v1/palette/posts/', {params: {cursor: cursor, size: CHUNK_SIZE, query}});
                if (res.status < 400) {
                    // setQueryToPostList(queryToPostList => {
                    const newQueryToPostList = JSON.parse(JSON.stringify(queryToPostListRef.current));
                    const newData = newQueryToPostList[query] || {items: []};
                    scrollInfo.cursors[page + 1] = res.data.cursor;
                    const idToScore = {};
                    for (const idx in res.data.post_list) {
                        const item = res.data.post_list[idx];
                        idToScore[item.id] = scrollInfo.ordering[idx];
                    }

                    res.data.post_list.sort((a, b) => idToScore[a.id] - idToScore[b.id]);
                    newData.items[page * CHUNK_SIZE] = res.data.post_list[0];
                    newData.items.splice(page * CHUNK_SIZE, res.data.post_list.length, ...res.data.post_list);
                    scrollInfo.itemLength = Math.max(newData.items.length, scrollInfo.itemLength);
                    if (!res.data.cursor) {
                        scrollInfo.hasNext = false;
                    }
                    const finalItems = newData.items.filter((item) => Boolean(item) && !reportedPostIds.has(item.id)).map((item, i) => {
                        if (item) {
                            item.index = i;
                        }
                        return item;
                    });
                    newQueryToPostList[query] = {
                        items: finalItems,
                        storage: Object.assign(
                            {}, ...finalItems.filter((item) => Boolean(item)).map((item, i) => ({[item.index]: item}))
                        ),
                        rowCount: finalItems.length + (res.data.cursor ? CHUNK_SIZE : 0),
                        finalRowCount: res.data.cursor ? undefined : finalItems.length,
                        error: false,
                    };
                    console.log('newQueryToPostList', newQueryToPostList, res.data.cursor);
                    queryToPostListRef.current = newQueryToPostList;

                    productScrapContextRef.current.addProductIds(res.data['scrapped_product_ids']);
                    postScrapContextRef.current.addPostIds(res.data['scrapped_post_ids']);
                } else {
                    console.error(res.data);
                    queryToPostListRef.current[query] = Object.assign(
                        {}, queryToPostListRef.current[query] || {}, {error: true}
                    )
                }
            } catch (e) {
                console.error(e);
                queryToPostListRef.current[query] = Object.assign(
                    {}, queryToPostListRef.current[query] || {}, {error: true}
                )
            }
            // console.log('loadMoreRows finished', query, startIndex, stopIndex, queryToPostListRef.current[query], newlyRequestedPages, requestedIndexInfo.current[query]);
        }
        // console.log('setQueryToPostList', queryToPostListRef.current);
        setQueryToPostList(queryToPostListRef.current);
    };

    const shortsFetchInfoRef = useRef({
        cursor: null,
        hasNext: true,
    });

    const fetchShorts = async (cursor) => {
        try {
            const params = {
                cursor,
                size: CHUNK_SIZE,
            };
            if (shortsList.filter((item) => item.id < cursor).length > CHUNK_SIZE / 2) {
                return;
            }
            const res = await Axios.get('/v1/palette/shorts/', {params});
            if (res.status < 400) {
                setShortsList((prev) => {
                    const newPosts = res.data.shorts.filter(newPost => {
                        return prev.findIndex(prevPost => newPost.id === prevPost.id) === -1
                    });
                    return JSON.parse(JSON.stringify(prev)).concat(newPosts);
                });
                productScrapContextRef.current.addProductIds(res.data['scrapped_product_ids']);
                postScrapContextRef.current.addPostIds(res.data['scrapped_post_ids']);
                shortsFetchInfoRef.current.cursor = res.data.cursor;
                shortsFetchInfoRef.current.hasNext = res.data.cursor !== null;
            }
        } catch (e) {
            console.error(e);
        }
    };

    const closeFeedOptionModal = () => {
        setFeedOptionModalTarget(initialFeedModalTarget);
    };

    const reportPost = async (user, postId) => {
        try {
            setReportedPostIds((prev) => {
                const newSet = new Set(prev);
                newSet.add(postId);
                return newSet;
            });
            if (user) {
                const res = await Axios.post(`/v1/palette/posts/${postId}/report`);
                if (res.status < 400) {
                } else {
                    alert(res.data.display_message || '일시적인 오류로 글을 신고할 수 없습니다.');
                    captureMessage(JSON.stringify(res.data));
                }
            }
        } catch (e) {
            alert('일시적인 오류로 글을 신고할 수 없습니다.');
            captureException(e);
        } finally {
            const newQueryToPostList = {};
            Object.entries(queryToPostListRef.current).forEach(([query, postListInfo]) => {
                const items = postListInfo.items;
                const newItems = items.filter((item) => item.id !== postId).map((item, i) => {
                    if (item) {
                        item.index = i;
                    }
                    return item;
                });
                newQueryToPostList[query] = {
                    finalRowCount: (
                        (postListInfo.finalRowCount !== undefined && postListInfo.finalRowCount > 0) ? postListInfo.finalRowCount - 1 : undefined
                    ),
                    rowCount: postListInfo.rowCount - 1,
                    items: newItems,
                    storage: Object.assign(
                        {}, ...newItems.filter((item) => Boolean(item)).map((item, i) => ({[item.index]: item}))
                    ),
                };
            });
            queryToPostListRef.current = newQueryToPostList;
            setQueryToPostList(newQueryToPostList);
        }
    };

    const addReportedPostIds = (postIds) => {
        setReportedPostIds((prev) => {
            const newSet = new Set(prev);
            postIds.forEach((postId) => {
                newSet.add(postId);
            });
            return newSet;
        });
    };

    const contextValue = {
        queryToPostList,
        loadMoreRows,
        getScrollInfo,
        feedOptionModalTarget,
        setFeedOptionModalTarget,
        closeFeedOptionModal,
        reset,
        shortsList,
        setShortsList,
        fetchShorts,
        reportPost,
        reportedPostIds,
        addReportedPostIds
    };

    return (
        <PostListContext.Provider value={contextValue}>
            <PostListContextWrapper>
                {
                    feedOptionModalTarget.option !== null &&
                    <FeedOptionModal
                        isOpen={feedOptionModalTarget.option !== null}
                        productOption={feedOptionModalTarget.option}
                        close={() => {
                            if (feedOptionModalTarget.option) {
                                window.history.back();
                                closeFeedOptionModal();
                            }
                        }}
                        postId={feedOptionModalTarget.postId}
                        referralCode={feedOptionModalTarget.referralCode}
                    />
                }
                {children}
            </PostListContextWrapper>
        </PostListContext.Provider>
    )
};
