import styles from "components/ButtonModal.module.scss";
import {useEffect, useRef, useState} from "react";
import classNames from "classnames";


const BottomModal = ({children, isOpen, close, preventBackgroundClose}) => {
    const containerRef = useRef();
    const isOpenRef = useRef(isOpen);
    const [pseudoState, setPseudoState] = useState(0);

    useEffect(() => {
        isOpenRef.current = isOpen;
        setPseudoState(val => val + 1);
    }, [isOpen]);

    useEffect(() => {
        if (!!isOpenRef.current && containerRef.current) {
            containerRef.current.style.visibility = 'visible';
        } else {
            setTimeout(() => {
                if (containerRef.current && !isOpenRef.current) {
                    containerRef.current.style.visibility = 'hidden';
                }
            }, 300);
        }
    }, [isOpenRef.current]);

    return (
        <div className={classNames('__bottom_modal__', styles.container)} onClick={(e) => {
            // e.preventDefault();
            e.stopPropagation();
            if (!preventBackgroundClose) {
                close();
            }
        }} ref={containerRef} style={{
            backgroundColor: isOpenRef.current ? undefined : 'rgba(0, 0, 0, 0)',
        }}>
            <div className={styles.contentContainer} style={{
                transform: isOpenRef.current ? 'translateY(0)' : 'translateY(100%)',
            }} onClick={(e) => {
                // e.preventDefault();
                e.stopPropagation();
            }}>
                {children}
            </div>
        </div>
    );
}

export default BottomModal;
