import styles from "components/sections/product/productInfoTitle.module.scss";
import classNames from "classnames";
import utilStyles from "styles/utils.module.scss";
import ArrowRight from "images/icons/arrow_right.svg";
import ArrowDownIcon from "images/icons/arrow_down_757575.svg";

export default function ProductInfoTitle (props) {
    const {isFold, foldable} = props;
    const onClick = props.onClick || function () {};

    const onFoldClick = props.onFoldClick || function () {};
    const setIsFold = props.setIsFold || function () {};
    return (
        <div className={classNames(styles.container, foldable ? utilStyles.cursorPointer : undefined)}
             onClick={() => {
                 onFoldClick();
                 setIsFold(val => !val);
             }}
        >
            <span className={styles.infoTitle}>
                {props.title}
                {
                    !!props.subTitle &&
                    <span className={styles.infoSubTitle}>{props.subTitle}</span>
                }
            </span>
            {
                !!props.link &&
                <div onClick={onClick} className={styles.linkContainer}>
                    <span className={styles.postLink}>{props.link.title}</span>
                    <ArrowRight viewBox="0 0 16 16" className={styles.arrowRight} />
                </div>
            }
            {
                !!foldable &&
                (
                    isFold ?
                        <ArrowDownIcon
                            viewBox="0 0 20 20" height="16" width="16" className={utilStyles.cursorPointer} />
                        :
                        <ArrowDownIcon
                            viewBox="0 0 20 20" height="16" width="16" className={utilStyles.cursorPointer} style={{transform: 'rotate(180deg)'}} />
                )
            }
        </div>
    );
}
