import styles from 'components/layout/Footer/footer.module.scss';
import utilStyles from 'styles/utils.module.scss';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import KoFooter from "components/layout/Footer/KoFooter";
import EnFooter from "components/layout/Footer/EnFooter";


export default function Footer (props) {
    const {locale} = props;

    const FooterComponent = () => {
        if (locale === 'ko') {
            return <KoFooter/>
        } else {
            return <EnFooter/>
        }
    };

    return (
        <div className={styles.container}>
            <FooterComponent />
        </div>
    )
}
