import styles from "components/speechBubble.module.scss";
import utilStyles from "styles/utils.module.scss";
import classNames from "classnames";


export default function SpeechBubble (props) {
    const {sidePadding, borderRadius, verticalPadding, children, pointLength, top, left, bottom, right, pointOffset, pointLeft, noDisplayBlock} = props;
    const backgroundColor = props.backgroundColor || '#FFA0CC';
    const onClick = props.onClick || function () {};


    const topStyle = {
        top: 0,
        left: pointLeft || (!pointOffset ? '50%' : '0'),
        borderTop: 0,
        borderBottomColor: backgroundColor,
        marginLeft: -(pointLength + 2),
        marginTop: -pointLength,
        transform: `scaleX(0.75) ${pointOffset ? `translateY(${pointOffset})` : ''}`,
    };

    const bottomStyle = {
        top: '100%',
        left: pointLeft || (!pointOffset ? '50%' : '0'),
        borderBottom: 0,
        borderTopColor: backgroundColor,
        marginLeft: -(pointLength + 2),
        marginTop: -2,
        transform: `scaleX(0.75) ${pointOffset ? `translateY(${pointOffset})` : ''}`,
    };

    const rightStyle = {
        top: !pointOffset ? '50%' : '0',
        left: '100%',
        borderRight: 0,
        borderLeftColor: backgroundColor,
        marginLeft: -2,
        marginTop: -(pointLength + 2),
        transform: `scaleY(0.75) ${pointOffset ? `translateY(${pointOffset})` : ''}`,
    };

    const leftStyle = {
        top: !pointOffset ? '50%' : '0',
        left: 0,
        borderLeft: 0,
        borderRightColor: backgroundColor,
        marginLeft: -pointLength,
        marginTop: -(pointLength + 2),
        transform: `scaleY(0.75) ${pointOffset ? `translateY(${pointOffset})` : ''}`,
    };

    let style;
    if (top) style = topStyle;
    else if (left) style = leftStyle;
    else if (right) style = rightStyle;
    else style = bottomStyle;

    return (
        <div>
            <div
                className={classNames(styles.speechBubbleContainer, styles.speechBubble, noDisplayBlock && utilStyles.inlineBlock)}
                style={{padding: `${verticalPadding}px ${sidePadding}px`, backgroundColor, borderRadius}}>
                <>
                    {children}
                    <div
                        className={classNames(styles.speechBubbleTail)}
                        style={{
                            border: `${pointLength + 2}px solid transparent`,
                            ...style,
                        }}
                    />
                </>
            </div>
        </div>
    )
}
