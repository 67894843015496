import Modal from "components/Modal";
import styles from "components/modal/IntegratedReservationModal.module.scss";
import TitleBar from "components/layout/TitleBar";
import {useContext, useRef, useState} from "react";
import {toast} from "react-toastify";
import {redirectToLogin} from "common/redirect";
import {captureException, captureMessage} from "@sentry/nextjs";
import UserContext from "context/AuthContext";
import {useRouter} from "next/router";
import {order} from "api/store/orders";
import {
    IntegratedReservationModalLocationSelector
} from "components/modal/Reservation/IntegratedReservationModalLocationSelector";
import {
    IntegratedReservationModalOptionSelector
} from "components/modal/Reservation/IntegratedReservationModalOptionSelector";
import {
    IntegratedReservationModalDateSelector
} from "components/modal/Reservation/IntegratedReservationModalDateSelector";
import {
    IntegratedReservationModalStateButton
} from "components/modal/Reservation/IntegratedReservationModalStateButton";
import {format} from "date-fns";


const IntegratedReservationModal = (props) => {
    const {close, product} = props;
    const isLoadingRef = useRef(false);
    const [selection, setSelection] = useState({
        product: null,
        options: {},
        date: null,
    });
    const user = useContext(UserContext).user;
    const router = useRouter();

    const checkout = async () => {
        if (isLoadingRef.current) {
            return;
        }
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductDetailOptionSelectorPurchaseButtonClick',
            {
                productId: product.id,
                productName: product.name,
                isReservation: true,
            }
        );
        if (Object.keys(selection.options).length === 0) {
            toast.info('관리권을 선택해주세요!');
            return;
        }
        if (!Boolean(selection.product)) {
            toast.info('지점을 선택해주세요.');
            return;
        }
        if (!Boolean(selection.date)) {
            toast.info('첫 방문일자를 선택해주세요.');
            return;
        }
        const data = {
            checkout_items: Object.entries(selection.options).map(([option_id, count]) => {
                return {
                    option_id: parseInt(option_id),
                    count: count,
                }
            }),
        };
        const selectedDateStr = format(selection.date, 'yyyy-MM-dd');
        isLoadingRef.current = true;
        try {
            const res = await order(
                data, {reservation_selected_date: selectedDateStr}
            );
            if (res.status < 400) {
                const ordNum = res.data.order_number;
                const checkoutUrl = `/store/orders/${ordNum}/checkout`;
                if (user) {
                    router.push(checkoutUrl);
                } else {
                    redirectToLogin(router, true, undefined, checkoutUrl);
                }
            } else {
                captureMessage(JSON.stringify(res.data));
                toast.info(res.data.display_message || '일시적인 오류로 주문 생성에 실패했습니다. 잠시 후 시도해주세요.');
            }
        } catch (e) {
            captureException(e);
            toast.info('일시적인 오류로 주문 생성에 실패했습니다. 잠시 후 다시 시도해주세요.');
        } finally {
            isLoadingRef.current = false;
        }
    };

    const handleProductSelect = (product) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReservationModalTimeSelect',
        );
        setSelection(prev => {
            if (!prev.product || prev.product.id !== product.id) {
                return {product: product, options: {}, date: null};
            }
            return prev;
        });
    };

    const [currentStep, setCurrentStep] = useState(1);

    const setSelectedDate = (date) => {
        setSelection(prev => ({...prev, date: date}));
    };

    return (
        <Modal showOverflow={false} isOpen={true} bottom={false} width="100%" height="100%"
               close={close}
        >
            <div className={styles.root}>
                <div className={styles.titleContainer}>
                    <TitleBar title="헤메티켓" isBack={false} isClose={true} close={close}/>
                </div>
                <div className={styles.content}>
                    {
                        currentStep === 1 ?
                            <IntegratedReservationModalLocationSelector
                                product={product}
                                selection={selection}
                                handleProductSelect={handleProductSelect}
                            /> :
                            currentStep === 2 ?
                                <IntegratedReservationModalOptionSelector
                                    selection={selection}
                                    setSelection={setSelection}
                                /> :
                                <IntegratedReservationModalDateSelector
                                    selectedDate={selection.date}
                                    setSelectedDate={setSelectedDate}
                                    selectedProduct={selection.product}
                                />
                    }
                </div>
                <IntegratedReservationModalStateButton
                    currentStep={currentStep} setCurrentStep={setCurrentStep}
                    checkout={checkout}
                    selection={selection}
                    setSelection={setSelection}
                />
            </div>
        </Modal>
    )
};

export default IntegratedReservationModal;
