import {useEffect, useRef, useState} from "react";
import moment from "moment";

const useTimer = (targetTimestamp) => {
    const [seconds, setSeconds] = useState(0);
    const timerRef = useRef();
    useEffect(() => {
        const seconds = targetTimestamp ? targetTimestamp - (Date.now() / 1000) : 0;
        setSeconds(seconds) ;
        if (seconds > 0) {
            timerRef.current = setInterval(() => {
                const remainSeconds = targetTimestamp ? targetTimestamp - (Date.now() / 1000) : 0;
                if (remainSeconds <= 0) {
                    setSeconds(remainSeconds);
                    clearInterval(timerRef.current);
                } else {
                    setSeconds(remainSeconds);
                }
            }, 1000);
        }

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        }
    }, [targetTimestamp]);

    const dayString = seconds > 3600 * 24 ? `${Math.floor(seconds / (3600 * 24))}일 ` : '';
    const hoursString = seconds >= 1 ? moment.utc(seconds * 1000).format('HH:mm:ss') : '';
    const detailHour = seconds >= 1 ? moment.utc(seconds * 1000).format('HH') : '';
    const detailMinute = seconds >= 1 ? moment.utc(seconds * 1000).format('mm') : '';
    const detailSecond = seconds >= 1 ? moment.utc(seconds * 1000).format('ss') : '';

    const timeString = seconds >= 1 ? `${dayString}${hoursString}` : null;

    return {seconds, timeString, detailHour, detailMinute, detailSecond};
};

export default useTimer;
