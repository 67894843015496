import styles from "components/input/input.module.scss";
import utilStyles from "styles/utils.module.scss";
import classNames from "classnames";
import {useEffect} from "react";
import PlusIcon from "images/icons/plus_brand.svg";
import {Axios} from "api";
import Loading from "components/Loading";
import {onFileSelect} from "common/utils";
import useTranslation from "next-translate/useTranslation";

const ImageInput = (props) => {
    const onSelect = props.onSelect || function() {};
    const {isLoading, disabled, accept, component} = props;
    const {t} = useTranslation('common');

    const onClick = e => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ImageInputClick',
        );
        if (isLoading) {
            return;
        }
        e.target.value = null;
    };

    return (
        <div className={styles.imageInputContainer} style={{opacity: disabled ? 0.4 : 1}}>
            {
                !isLoading ?
                    (
                        !!component ?
                            component :
                            <>
                                <PlusIcon />
                                <span className={styles.imageInputText}>{t('common:image.uploadText')}</span>
                            </>
                    )
                    :
                    <Loading />
            }
            <input type="file" id="img" name="img" accept={!!accept || "image/*,.heic"} disabled={isLoading || disabled} className={styles.imageInputInvisible}
                   onChange={onFileSelect(onSelect)}
                   onClick={onClick}
            />
        </div>
    )
}

export default ImageInput;
