import styles from "components/modal/IntegratedReservationModal.module.scss";
import FullButton from "components/buttons/FullButton";
import {useMemo} from "react";
import SelectedOptionList from "components/layout/store/purchase/SelectedOptionList";
import classNames from "classnames";


export const IntegratedReservationModalStateButton = (props) => {
    const {currentStep, checkout, setCurrentStep, selection, setSelection} = props;

    const getNextButtonText = () => {
        if (currentStep !== 3) {
            return "다음";
        } else {
            return "결제하기";
        }
    };

    const handleConfirmButtonClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReservationModalConfirmButtonClick'
        );
        checkout();
    };

    const handleNextButtonClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReservationModalNextButtonClick'
        );
        setCurrentStep(prev => Math.min(prev + 1, 3));
    };

    const getNextButtonOnClick = () => {
        if (currentStep !== 3) {
            return handleNextButtonClick;
        } else {
            return handleConfirmButtonClick;
        }
    };

    const handlePrevButtonClick = () => {
        setCurrentStep(prev => Math.max(prev - 1, 1));
    };

    const optionIdToOption = useMemo(() => {
        const optionIdToOption = {};
        if (!selection.product) {
            return {};
        }
        selection.product.options.forEach(option => {
            optionIdToOption[option.id] = option;
        });
        return optionIdToOption;
    }, [selection.product]);


    const addOptionCount = (optionId, addAmount) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReservationModalOptionSelect',
        );
        setSelection(val => {
            const newSelection = JSON.parse(JSON.stringify(val));
            const newDict = JSON.parse(JSON.stringify(newSelection.options));
            const newVal = newSelection.options[optionId] ? newSelection.options[optionId] + addAmount : addAmount;
            newDict[optionId] = newVal;
            if (newVal === 0) {
                delete newDict[optionId];
            }
            newSelection.options = newDict;
            return newSelection;
        });
    };

    const deleteOptionCount = (optionId) => {
        setSelection(prev => {
            const newSelection = JSON.parse(JSON.stringify(prev));
            delete newSelection.options[optionId];
            return newSelection;
        });
    };

    const setOptionCount = (optionId, count) => {
        setSelection(prev => {
            const newSelection = JSON.parse(JSON.stringify(prev));
            const newDict = JSON.parse(JSON.stringify(newSelection.options));
            newDict[optionId] = count;
            newSelection.options = newDict;
            return newSelection;
        });
    };

    const resetSelectedOptions = () => {
        setSelection(prev => {
            const newSelection = JSON.parse(JSON.stringify(prev));
            newSelection.options = {};
            return newSelection;
        });
    };

    const hasOptions = selection && selection.product && (Object.keys(selection.options).length > 0);
    console.log('hasOptions', hasOptions, Object.keys(selection.options).length);

    const isDisabled = currentStep === 1 ?
        selection.product === null :
        currentStep === 2 ?
            Object.keys(selection.options).length === 0 :
            selection.date === null;

    const getOptionName = (option) => {
        if (selection?.product?.alias_name) {
            const aliasName = selection.product.alias_name;
            return `${aliasName} / ${option.name}`;
        }
        return option.name;
    };

    return (
        <div className={classNames(styles.bottomContainer, hasOptions && styles.selectedOptionContainer)}>
            {
                hasOptions &&
                <SelectedOptionList
                    product={selection.product}
                    selectedOptions={selection.options}
                    getOptionName={getOptionName}
                    optionIdToOption={optionIdToOption}
                    onClick={addOptionCount}
                    onPouchSuccess={() => {
                    }}
                    resetSelectedOptions={resetSelectedOptions}
                    addOptionCount={addOptionCount}
                    deleteOptionCount={deleteOptionCount}
                    setOptionCount={setOptionCount}
                    small={true}
                    type={3}
                />
            }
            <div className={styles.buttonContainer}>
                {
                    currentStep > 1 &&
                    <FullButton
                        title="뒤로가기"
                        white
                        height={48} fontSize={16}
                        disabled={currentStep === 1}
                        onClick={handlePrevButtonClick}
                    />
                }
                <FullButton
                    title={getNextButtonText()}
                    height={48} fontSize={16}
                    disabled={isDisabled}
                    onClick={getNextButtonOnClick()}
                />
            </div>
        </div>
    )
};
