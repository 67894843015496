import Head from "next/head";
import classNames from "classnames";
import utilStyles from "styles/utils.module.scss";
import TitleBar from "components/layout/TitleBar";
import {useRouter} from "next/router";
import styles from "components/layout/AuthLayout.module.scss";
import {signUpRoute} from "common/const";


export default function AuthLayout(page) {
    const router = useRouter();

    const preventGoBack = router.query.preventGoBack === 'true';
    const withForm = !!router.query.withForm;

    return (
        <>
            <Head>
                <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,viewport-fit=cover" />
            </Head>
            <>
                {/*<div style={{height: 50}} />*/}
                {
                    (!preventGoBack || withForm) &&
                    <div className={styles.titleBarContainer}>
                        <div className={classNames(utilStyles.whiteBackground)}>
                            <TitleBar title={router.asPath === signUpRoute ? '이메일 가입' : ""} isBack close={() => router.back()} />
                        </div>
                    </div>
                }
                {page}
            </>
        </>
    )
}
