import {useRouter} from "next/router";
import {useEffect, useRef} from "react";
import {downloadCouponsByMoldKeys} from "api/coupon";
import {couponsRoute, isHomeRoute} from "common/const";
import {redirectToApp, redirectToLogin} from "common/redirect";
import {customConfirm, isApp} from "common/utils";


const useCouponDownloadByUrl = (user) => {
    const router = useRouter();
    const {query, pathname} = router;
    const moldKeys = query.molds || '';
    const isAppForced = (query.appCoupon || '') === 'true';
    const downloadedMoldKeysRef = useRef([]);

    const onSuccess = () => {
        // remove mold keys from url
        const newQuery = {...query};
        delete newQuery.molds;
        router.replace({
            pathname: pathname,
            query: newQuery,
        }, undefined, {shallow: true});
    };

    useEffect(() => {
        if (!Boolean(moldKeys) || user === undefined || (!isHomeRoute(pathname) && pathname !== couponsRoute)) {
            return;
        }
        if (isAppForced && !isApp()) {
            redirectToApp();
            return;
        }
        if (user === null) {
            customConfirm(
                '쿠폰을 받으려면 로그인이 필요해요!',
                '로그인하기',
                '취소',
                () => {
                    redirectToLogin(router, true);
                },
                () => {
                },
            );
            return;
        }
        if (downloadedMoldKeysRef.current.includes(moldKeys)) {
            return;
        }
        downloadedMoldKeysRef.current.push(moldKeys);
        downloadCouponsByMoldKeys(moldKeys, onSuccess);
    }, [moldKeys, user, pathname]);
};

export default useCouponDownloadByUrl;
