import {createContext, useContext, useEffect, useMemo, useState} from "react";


const PouchContext = createContext(undefined);

export let pouchContextRef = {};

export default PouchContext;

const PouchContextWrapper = ({children}) => {
    const context = useContext(PouchContext);

    useEffect(() => {
        pouchContextRef.current = context;
    }, []);

    useEffect(() => {
        pouchContextRef.current = context;
    }, [context]);

    return(
        <>
            {children}
        </>
    )
}

// TODO: Inspect why render is called twice on context change
export const PouchContextProvider = ({children}) => {
    const [_pouchItems, _setPouchItems] = useState([]);


    const pouchItems = useMemo(() => {
        return typeof window !== 'undefined' ?
            JSON.parse(window.localStorage.getItem('pouch_items')) || [] :
            [];
    }, [_pouchItems]);

    const addToLocalPouch = (options) => {
        if (typeof window === 'undefined') {
            return;
        }
        const originalPouchItems = JSON.parse(window.localStorage.getItem('pouch_items')) || [];
        for (const option of options) {
            const originalItem = originalPouchItems.filter(e => e['option_id'] === option.option_id)[0];
            if (originalItem) {
                originalItem.count = originalItem.count + option.count;
                originalItem.referral_id = option.referral_id;
                originalItem.referral_post_id = option.referral_post_id;
            } else {
                originalPouchItems.push({
                    'id': Date.now().toString() + option['option_id'].toString(),
                    'created_at': Date.now(),
                    'option_id': option['option_id'],
                    'count': option['count'],
                    'referral_id': option['referral_id'],
                    'referral_post_id': option['referral_post_id'],
                });
            }
        }
        originalPouchItems.sort((a, b) => b.id - a.id);
        setPouchItems(originalPouchItems);
    };

    const setPouchItems = (items) => {
        window.localStorage.setItem('pouch_items', JSON.stringify(items));
        _setPouchItems(items);
    };


    const contextValue = {
        pouchItems, addToLocalPouch, setPouchItems,
    };

    return (
        <PouchContext.Provider value={contextValue}>
            <PouchContextWrapper>
                {children}
            </PouchContextWrapper>
        </PouchContext.Provider>
    )
}