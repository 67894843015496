import BaseOrderOptionCard, {ActionButton, WriteReviewButton} from "components/cards/order/BaseOrderOptionCard";
import {getReservationProductRoute} from "common/const";
import styles from "components/cards/order/baseOrderOptionCard.module.scss";
import {useContext, useMemo, useState} from "react";
import ReviewModal from "components/modal/ReviewModal";
import UserContext from "context/AuthContext";


export const ReservationCallButton = ({reservation}) => {
    const onClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'OrderReservationCardReservationCallButtonClick',
        );
    };
    return (
        <a className={styles.buttonWrapper} onClick={onClick}
           href={`tel:${reservation.shop_info.shop_phone_number}`}
        >
            <ActionButton>샵에 전화하기</ActionButton>
        </a>
    )
};


const GetButtons = (reservation, isReviewModalOpen, setIsReviewModalOpen) => {
    const user = useContext(UserContext).user;
    const is_confirmed = reservation.order_option_status === '구매확정';
    return (
        <>
            <ReservationCallButton reservation={reservation}/>
            {
                !!user && !reservation.has_reviewed && is_confirmed &&
                <WriteReviewButton onClick={() => setIsReviewModalOpen(true)} orderOption={reservation}/>
            }
        </>
    );
}

export default function OrderReservationCard(props) {
    const {reservation, currency} = props;
    const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
    const onReviewPostSuccess = props.onReviewPostSuccess || function () {
    };

    const isSinglePermission = reservation.review_permission_group.permissions.length === 1;
    const permissionId = useMemo(() => {
        if (isSinglePermission) {
            return reservation.review_permission_group.permissions[0].permission_id;
        } else {
            return null;
        }
    }, [reservation]);

    return (
        <BaseOrderOptionCard
            productOverrideLink={reservation.product_override_link || getReservationProductRoute(reservation.product_id)}
            brandName={reservation.brand_name}
            count={reservation.count}
            productId={reservation.product_id}
            productName={reservation.product_name}
            status={reservation.status}
            imageUrl={reservation.product_image_url}
            optionName={reservation.option_name}
            reservationTime={reservation.reservation_time}
            price={reservation.unit_sell_price}
            disableLink={props.disableLink}
            giftReceiverName={reservation.gift_receiver_name}
            isReservation={true}
            currency={reservation?.currency || currency}
        >
            <ReviewModal
                isOpen={isReviewModalOpen}
                close={() => setIsReviewModalOpen(false)}
                imageUrl={reservation.product_image_url}
                orderOptionId={permissionId ? null : reservation.order_option_id}
                optionId={reservation.product_option_id}
                permissionId={permissionId}
                brandName={reservation.brand_name}
                optionName={reservation.option_name}
                productName={reservation.product_name}
                resetVariable={isReviewModalOpen}
                onSuccess={() => {
                    setIsReviewModalOpen(false);
                    onReviewPostSuccess();
                }}
            />
            {
                GetButtons(reservation, isReviewModalOpen, setIsReviewModalOpen)
            }
        </BaseOrderOptionCard>
    )
}
