import Link from "next/link";
import {getCreatorRoute} from "common/const";
import {useRouter} from "next/router";

export default function Nickname (props) {
    const {value, creatorCode, className} = props;
    const router = useRouter();

    return (
         <a className={className} onClick={async (e) => {
             typeof mixpanel !== 'undefined' && mixpanel.track(
                 'NicknameClick',
             );
             e.stopPropagation();
             for (const key in window.history.state) {
                 if (key.toLowerCase().includes('modal')) {
                     if (creatorCode) {
                         router.replace(getCreatorRoute(creatorCode));
                         return
                     }
                 }
             }
             if (creatorCode) {
                 router.push(getCreatorRoute(creatorCode));
             }
         }} style={{cursor: 'pointer'}}>
            {
                value ?
                    value.split(/(_+)/).map((e, idx) => {
                        if (e.includes('_')) {
                            return <span style={{
                                fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,\n' +
                                    '  Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
                            }} key={idx}>{e}</span>
                        } else {
                            return e;
                        }
                    })
                    :
                    value
            }
        </a>
    )
}
