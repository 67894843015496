import {createContext, useMemo, useState} from "react";
import {useContext, useEffect} from "react";

const CategoryContext = createContext(undefined);

export let categoryContextRef = {};

export default CategoryContext;

const CategoryContextWrapper = ({children}) => {
    const context = useContext(CategoryContext);

    useEffect(() => {
        categoryContextRef.current = context;
    }, []);

    useEffect(() => {
        categoryContextRef.current = context;
    }, [context]);

    return(
        <>
            {children}
        </>
    )
}

export const CategoryContextProvider = ({children}) => {
    const [productCategories, setProductCategories] = useState([]);
    const categoryIdToCategory = useMemo(() => {
        const map = {};
        for (const category of productCategories) {
            map[category.id] = category;
            for (const child of category.children) {
                map[child.id] = child;
            }
        }
        return map;
    }, [productCategories]);

    const getCategoryById = (id) => {
        if (!id) {
            return null;
        }
        let targetId = id;
        if (id.includes('_')) {
            targetId = id.split('_')[1];
        }
        for (const category of productCategories) {
            if (category.id.toString() === targetId.toString()) {
                return category;
            }
            const targetChild = category.children.filter(e => e.id.toString() === targetId.toString())[0];
            if (targetChild) {
                return targetChild;
            }
        }
        return null;
    };

    const getParentCategoryIdById = (id) => {
        if (!id) {
            return null;
        }
        if (id in categoryIdToCategory) {
            const category = categoryIdToCategory[id];
            return category.parent_id || category.id;
        }
        else {
            return null;
        }
    };

    const getCategoryIdByName = (name) => {
        if (!name) {
            return '0';
        }
        const hasParent = name.includes('_');
        const parentCategory = hasParent ? name.split('_')[0].trim() : '';
        const targetName = hasParent ? name.split('_')[1].trim() : name;
        console.log('parentCategory', parentCategory);
        for (const category of productCategories) {
            if (category.name === targetName) {
                return `${category.id}`;
            }
            console.log('parentCategory', parentCategory, category.name);
            if (hasParent && parentCategory !== category.name) {
                continue;
            }
            const targetChild = category.children.filter(e => e.name === targetName)[0];
            if (targetChild) {
                return `${category.id}_${targetChild.id}`;
            }
        }
        return '0';
    };

    const getMidCategory = (qs) => {
        if (qs) {
            let midCategoryId = qs.toString().split('_')[0];
            if (isNaN(midCategoryId)) {
                midCategoryId = getCategoryIdByName(midCategoryId);
            }
            const categories = productCategories.filter(x => x.query_string === midCategoryId);
            return categories.length > 0
                ? categories[0]
                : productCategories[0];
        }
        else {
            return productCategories.length > 0 ? productCategories[0] : null;
        }
    }

    // const setCategory = (qs) => {
    //     if (qs) {
    //         const midCategoryId = qs.toString().split('_')[0];
    //         const categories = productCategories.filter(x => x.query_string === midCategoryId);
    //         if (categories.length > 0) {
    //             const subCategories = categories[0].children.filter(x => x.query_string === qs);
    //             if (subCategories.length > 0) {
    //                 setCurrentCategory(subCategories[0]);
    //             }
    //             else {
    //                 setCurrentCategory(categories[0]);
    //             }
    //         }
    //     }
    // }

    const contextValue = {
        getCategoryById, productCategories, setProductCategories, getMidCategory, getCategoryIdByName, getParentCategoryIdById
    }

    // {categories: [], getCategoryById: () => null}

    return (
        <CategoryContext.Provider value={contextValue}>
            <CategoryContextWrapper>
                {children}
            </CategoryContextWrapper>
        </CategoryContext.Provider>
    )
}
