import Modal from "components/Modal";
import styles from "components/buttons/purchaseButton.module.scss";
import OptionSelector from "components/input/OptionSelector";
import {useEffect, useState} from "react";
import {addOptionCountImpl, deleteOptionCountImpl, setOptionCountImpl} from "components/pages/ProductDetail";


export default function OptionSelectorModal(props) {
    const {product, buttonText, referralInfo, onAddToPouchSuccessOverride, isPouchForced} = props;
    const options = product ? product.options : [];
    const defaultSelectedOption = options.length === 0
        ? {}
        : (options.length > 1 ? {} : {[options[0].id]: 1});
    const [selectedOptions, setSelectedOptions] = useState(defaultSelectedOption);

    const addOptionCount = (optionId, amount) => {
        addOptionCountImpl(optionId, amount, setSelectedOptions);
    }

    const setOptionCount = (optionId, amount) => {
        setOptionCountImpl(optionId, amount, setSelectedOptions);
    }

    const deleteOptionCount = (optionId) => {
        deleteOptionCountImpl(optionId, setSelectedOptions);
    }

    useEffect(() => {
        if (props.isModalOpen) {
            setSelectedOptions(defaultSelectedOption);
        }
    }, [props.isModalOpen]);

    const close = () => {
        setSelectedOptions({});
        props.close();
    };

    const basePrice = product ? product.discounted_price : 0;

    const onOptionSelect = props.onOptionSelect || function () {};
    const onOptionDelete = props.onOptionDelete || function () {};
    const onOptionCountChange = props.onOptionCountChange || function () {};
    const onPurchase = props.onPurchase || function () {};
    const onPouchSuccess = props.onPouchSuccess || function () {};

    return (
        <Modal showOverflow={false} isOpen={props.isModalOpen} bottom={true} width="100%"
               close={close}>
            <div className={styles.selectorContainer}>
                {
                    product &&
                    <OptionSelector
                        onPouchSuccess={onPouchSuccess}
                        resetSelectedOptions={() => {
                            setSelectedOptions({})
                        }}
                        small
                        type={3}
                        basePrice={basePrice}
                        product={product}
                        selectedOptions={selectedOptions}
                        addOptionCount={addOptionCount}
                        deleteOptionCount={deleteOptionCount}
                        setOptionCount={setOptionCount}
                        referralInfo={referralInfo}
                        onOptionSelect={onOptionSelect}
                        onOptionDelete={onOptionDelete}
                        onOptionCountChange={onOptionCountChange}
                        onPurchase={onPurchase}
                        buttonText={buttonText}
                        handleCancel={close}
                        onAddToPouchSuccessOverride={onAddToPouchSuccessOverride}
                        isPouchForced={isPouchForced}
                    />
                }
            </div>
        </Modal>
    )
}
