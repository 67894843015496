import Image from "next/image";

import utilStyles from 'styles/utils.module.scss'
import KakaoLogoSmall from 'images/icons/kakao_logo.svg';
import KakaoLogo from 'images/icons/kakao.svg';
import {AUTH_TYPE_GOOGLE, AUTH_TYPE_KAKAO, GOOGLE_CLIENT_ID, KAKAO_CLIENT_ID, SITE_URL} from "common/const";
import {forwardRef, useEffect} from "react";
import Script from "next/script";
import {v4 as uuidv4} from "uuid";
import {REDIRECT_URI} from "components/buttons/NaverLoginButton";
import {useRouter} from "next/router";
import authStyles from "pages/auth/index.module.scss";
import classNames from "classnames";
import SpeechBubble from "components/SpeechBubble";
import KakaoLoader from "components/KakaoLoader";
import useTranslation from "next-translate/useTranslation";


const getRedirectUri = (isSignUp) => {
    let uri = `${SITE_URL}/auth/sign-in?auth_type=${AUTH_TYPE_KAKAO}`;
    if (isSignUp) {
        uri += '&is_sign_up=true'
    }
    return uri;
}

export const promptKakaoLogin = (redirect, isSignUp) => {
    // if (!router.isReady) {
    //     return;
    // }
    const state = uuidv4();
    window.localStorage.setItem(AUTH_TYPE_KAKAO, state);
    window.localStorage.setItem(REDIRECT_URI, redirect);
    const continueURL = encodeURIComponent(`https://kauth.kakao.com/oauth/authorize?prompt=login&client_id=${KAKAO_CLIENT_ID}&redirect_uri=${getRedirectUri(isSignUp)}&response_type=code&state=${state}`);
    window.location.href = `https://accounts.kakao.com/login?continue=${continueURL}`
};


const KakaoLoginButton = (props) => {
    const router = useRouter();
    const {disabled, signUp, full, lastLoginType, text} = props;

    const {t} = useTranslation('auth');
    return (
        <>
            <KakaoLoader />
            <a
                // onClick={!disabled ? () => {!!signUp ? promptKakaoLogin(router.query.redirect, true) : promptKakaoLogin(router.query.redirect)} : () => {}}
                onClick={() => {
                    const state = uuidv4();
                    window.localStorage.setItem(AUTH_TYPE_KAKAO, state);
                    window.localStorage.setItem(REDIRECT_URI, router.query.redirect);
                    Kakao.Auth.authorize({
                        redirectUri: getRedirectUri(!!signUp),
                        state: state,
                        // prompts: 'login',
                        throughTalk: true,
                    });
                }}
            >
                {
                    full ?
                        <div className={authStyles.wideSNSLoginButton} style={{backgroundColor: '#FEE500'}}>
                            {
                                (lastLoginType === AUTH_TYPE_KAKAO || text) &&
                                <div className={authStyles.lastLoginBubble} style={{top: -38}}>
                                    <SpeechBubble
                                        sidePadding={12} verticalPadding={6}
                                        pointLength={5} borderRadius={9999}
                                        bottom
                                        backgroundColor={utilStyles.brand}
                                    >
                                        <span className={authStyles.lastLoginBubbleText}>
                                            {text ? text : t('sign-in.lastLogin')}
                                        </span>
                                    </SpeechBubble>
                                </div>
                            }
                            <div
                                className={classNames(utilStyles.fullFlex, utilStyles.flexRow, utilStyles.justifyContentCenter, utilStyles.alignItemsCenter, utilStyles.textAlignCenter, authStyles.wideButtonText)}
                                style={{color: 'black'}}
                            >
                                <div className={utilStyles.socialIcon}>
                                    <KakaoLogoSmall />
                                </div>
                                {t('sign-in.loginButton.sns', {sns: t('common:sns.kakao')})}
                            </div>
                        </div>
                        :
                        <KakaoLogo
                            className={utilStyles.cursorPointer}
                            width={48}
                            height={48} />
                }
            </a>
        </>
    );
};

export default KakaoLoginButton;
