import StarRating from "components/review/StarRating";
import commonStyles from "components/sections/product/index.module.scss";
import styles from "components/sections/product/productReviewSection.module.scss";
import utilStyles from "styles/utils.module.scss";
import ProductInfoTitle from "components/sections/product/ProductInfoTitle";
import classNames from "classnames";
import CheckBoxChecked from "images/icons/checkbox_checked.svg";
import CheckBoxUnchecked from "images/icons/checkbox_unchecked.svg";
import SpeechBubbleIcon from "images/icons/speech_bubble.svg";
import {forwardRef, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import Image from "next/image";
import PipeIcon from "images/icons/pipe.svg";
import Paginator from "components/Paginator";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Modal from "components/Modal";
import ReviewModal, {StarSelector} from "components/modal/ReviewModal";
import {customAlert, customConfirm, formatDate, numberWithComma} from "common/utils";
import Link from "next/link";
import {getCreatorRoute, getProductRoute, getReservationProductRoute, reviewRoute} from "common/const";
import TruncateMarkup from 'react-truncate-markup';
import {Axios} from "api";
import Loading from "components/Loading";
import UpvoteIcon from "images/icons/heart_fill.svg";
import NotUpvotedIcon from "images/icons/heart_shortcut.svg";
import {ErrorMessage} from "pages/500";
import {removeLocalReferralInfo} from "common/referral";
import * as Sentry from "@sentry/nextjs";
import UserContext from "context/AuthContext";
import {redirectToLogin} from "common/redirect";
import {useRouter} from "next/router";
import {captureException, captureMessage} from "@sentry/nextjs";
import ScoreStatistics from "components/review/ScoreStatistics";
import StarRatingLong from "components/review/StarRatingLong";
import FullButton from "components/buttons/FullButton";
import DefaultProfileIcon from "images/icons/default_profile.svg";
import Nickname from "components/Nickname";
import RefreshIcon from "images/icons/refresh.svg";
import CreatorBadgeIcon from "images/icons/creator_badge.svg";
import ReviewScrapButton from "components/buttons/ReviewScrapButton";
import reviewScrapContext, {reviewScrapContextRef} from "context/ReviewScrapContext";
import ArrowRightIcon from "images/icons/arrow_right_757575_16.svg";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import CloseIcon from "images/icons/close_new.svg";
import LoadingDot from "components/LoadingDot";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";


const PAGE_SIZE = 10;


export const ReviewCard = (props) => {
    const {review, myReview, isExpanded, hideImages, isBottomBorderEmpty} = props;
    const {id, score, content, images, created_at, option_name, product, is_photo_required} = review;

    const rootId = "review";

    const onUpvoteClick = props.onUpvoteClick || function () {
    };
    const setIsExpanded = props.setIsExpanded || function () {
    };

    const onPatchSuccess = props.onPatchSuccess || function () {
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);

    const dateString = formatDate(created_at);
    const dateStringDetail = formatDate(created_at, true);

    const openImageDetailModal = props.openImageDetailModal || function () {
    };
    const {t} = useTranslation('components-sections-product-ProductReviewSection')

    const onProductClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReviewCardProductNameClick',
        );
        if (product) {
            removeLocalReferralInfo(product.id);
        }
    };

    const router = useRouter();

    const brandName = product && product.brand_name;
    const productName = `[${brandName}] ${product?.name}`;

    const editReview = (e) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReviewCardEditReviewClick',
        );
        e.stopPropagation();
        setIsModalOpen(true);
    };

    return (
        <div
            className={classNames(
                styles.cardContainer, myReview ? utilStyles.cursorPointer : undefined,
                myReview && isExpanded ? styles.lightBackground : undefined,
                isBottomBorderEmpty && styles.emptyBottomBorder,
            )}
            style={{cursor: isExpanded ? 'default' : undefined}}
            onClick={() => setIsExpanded(id)}
            id={rootId}
        >
            {
                myReview &&
                <ReviewModal
                    isOpen={isModalOpen}
                    imageUrl={product.image_url} brandName={brandName} optionName={option_name}
                    productName={product.name}
                    close={() => setIsModalOpen(false)}
                    review={review}
                    resetVariable={isModalOpen} onSuccess={() => {
                    setIsModalOpen(false);
                    onPatchSuccess();
                }}
                    isPhotoRequired={is_photo_required}
                />
            }
            {
                myReview ?
                    <>
                        <div className={styles.myReviewHeading}>
                            <div className={styles.noLineHeight}>
                                <span className={styles.product}>{t('productName')}</span>
                                <PipeIcon style={{verticalAlign: 'middle', margin: '0 4px'}}/>
                                <Link href={product.is_shop_reservation ? getReservationProductRoute(product.id) : getProductRoute(product.id)}>
                                    <a onClick={e => {
                                        onProductClick();
                                        e.stopPropagation();
                                    }}
                                       className={classNames(styles.product, styles.productName)}
                                    >
                                        <TruncateMarkup lines={1} ellipsis={<span>...</span>}>
                                            <span className={styles.productNameContent}>
                                                {productName}
                                            </span>
                                        </TruncateMarkup>
                                    </a>
                                </Link>
                            </div>
                            {
                                isExpanded &&
                                <span onClick={editReview} className={styles.editButton}>{t('update')}</span>
                            }
                        </div>
                        <span className={styles.detailDate}>{isLarge ? dateStringDetail : dateString}</span>
                    </>
                    :
                    <div className={styles.heading}>
                        <div className={styles.nicknameWrapper}>
                            {
                                review.user.profile_image_url
                                    ?
                                    <div onClick={() => router.push(getCreatorRoute(review.user.code))} className={classNames(styles.profileImage)}>
                                        <Image unoptimized src={review.user.profile_image_url} width={32} height={32} />
                                    </div>
                                    : <DefaultProfileIcon onClick={() => router.push(getCreatorRoute(review.user.code))} className={styles.profileImage} viewBox="0 0 32 32" />
                            }
                            <div className={styles.userInfoContainer}>
                                <div className={utilStyles.noLineHeight}>
                                    <Nickname value={`${review.user.nickname}`} creatorCode={review.user.code} className={styles.userNickname} />
                                    {
                                        review.user.is_verified &&
                                        <CreatorBadgeIcon className={classNames(utilStyles.verticalMiddle, styles.creatorBadge)} viewBox="0 0 16 16" />
                                    }
                                </div>
                            </div>
                        </div>
                        <span className={styles.date}>{dateString}</span>
                    </div>
            }

            <StarSelector withoutText value={score} size={16} margin={0} brandStar/>
            <div style={{height: 4}}/>
            <span className={styles.option}>{t('option')} : {option_name}</span>
            {
                images.length > 0 && isExpanded && !hideImages &&
                <>
                    <div className={styles.imageContainer}>
                        {
                            images.map(e => {
                                return (
                                    <div className={styles.horizontalScrollElement} key={e.id} onClick={() => {
                                        openImageDetailModal(e.image_url)
                                    }}>
                                        <div className={styles.imageWrapper}>
                                            <Image unoptimized layout="fill" objectFit="cover" src={e.thumbnail_url}/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
            }
            {
                !myReview && review.is_sponsored &&
                <div className={styles.sponsoredIcon}>
                    <span>{t('reviewer')}</span>
                </div>
            }
            <div className={styles.content}>
                {
                    !isExpanded ?
                        <TruncateMarkup lines={1}>
                            <span className={styles.text}>
                              {content}
                            </span>
                        </TruncateMarkup>
                        :
                        <span className={styles.text}>
                          {content}
                        </span>
                }
            </div>
            {
                isExpanded &&
                <ReviewScrapButton review={review} onUpvoteClick={onUpvoteClick} />
            }
        </div>
    );
}

const KEY_REVIEW_ORDER_NEWEST = 'recent';
const KEY_REVIEW_ORDER_UPVOTE = 'upvote';

const KEY_REVIEW_TYPE_HEMEKO = 'hemeko';
const KEY_REVIEW_TYPE_MALL = 'mall';


const MallReview = ({review, product}) => {
    const images = review.image_urls;
    const [modalImageUrl, setModalImageUrl] = useState(null);
    const {t} = useTranslation('components-sections-product-ProductReviewSection')
    const onImageClick = (image) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReviewCardImageModalOpen',
            {
                isMallReview: true,
            }
        );
        setModalImageUrl(image);
    };

    const onImageDetailModalClose = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReviewCardImageModalClose',
            {
                isMallReview: true,
            }
        );
        setModalImageUrl(null);
    };

    return (
        <div className={styles.mallReviewContainer}>
            <Modal isOpen={modalImageUrl !== null} maxWidth={'calc(100% - 60px)'} close={onImageDetailModalClose}>
                {
                    !!modalImageUrl &&
                    <div className={styles.imageModal}>
                        <img src={modalImageUrl} alt={'image'} style={{}}/>
                    </div>
                }
            </Modal>
            <div className={styles.reviewMetaContainer}>
                <div className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter)}>
                    {
                        !!review.score &&
                        <div style={{marginRight: 9}}>
                            <StarRatingLong rate={review.score} size={15} margin={1} brandStar/>
                        </div>
                    }
                    <span className={styles.mallReviewWriter}>{product.brand.name} {t('customer')}</span>
                </div>
                {
                    !!review.registered_at &&
                    <span className={styles.mallReviewDate}>{formatDate(review.registered_at)}</span>
                }
            </div>
            {
                images.length > 0 &&
                <>
                    <div className={styles.mallReviewImageContainer}>
                        {
                            images.map((e, idx) => {
                                return (
                                    <div className={styles.mallReviewHorizontalScrollElement} key={idx}>
                                        <div className={styles.mallImageWrapper}>
                                            <Image unoptimized layout="fill" objectFit="cover" src={e}
                                                   onClick={() => onImageClick(e)}/>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
            }
            <span className={styles.mallReviewContent}>
                 {review.content}
            </span>
        </div>
    )
}

const MallReviews = (props) => {
    const {product, sectionRef} = props;

    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [reviews, setReviews] = useState([]);
    const [reviewCount, setReviewCount] = useState(product.mall_review_count);
    const {t} = useTranslation('components-sections-product-ProductReviewSection')
    const initialized = useRef(false);

    const fetchReview = async () => {
        setReviews([]);
        try {
            setIsLoading(true);
            const res = await Axios.get(`v1/store/products/${product.id}/mall-reviews/`, {
                params: {
                    page: page,
                    size: PAGE_SIZE
                }
            });
            initialized.current = true;
            if (res.status < 400) {
                setReviews(res.data.reviews);
                setReviewCount(res.data.total_count);
            } else {
                captureMessage(JSON.stringify(res.data));
            }
        } catch (e) {
            captureException(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchReview();
        if (initialized.current) {
            if (!!sectionRef.current) {
                sectionRef.current.scrollIntoView();
            }
        }
    }, [page]);

    const onPageChange = (page) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailReviewSectionMallReviewsPageChange', {
            productId: product.id,
            productName: product.name,
            page: page,
        });
        setPage(page);
    };

    return (
        <div>
            <div className={styles.brandInfoSection}>
                <div className={styles.brandImageWrapper}>
                    {
                        product.brand.logo_image_url &&
                        <Image src={product.brand.logo_image_url} unoptimized layout="fill" objectFit="cover"/>
                    }
                </div>
                <span className={styles.brandName}>{product.brand.name}</span>
                <span className={styles.mallReviewCount}>{t('review')} {product.mall_review_count}</span>
            </div>
            <div className={styles.reviewBorder}/>
            {
                isLoading ?
                    <Loading style={{marginTop: 60, marginBottom: 80}}/>
                    :
                    (
                        reviews.length > 0 ?
                            <>
                                <div className={styles.reviewListContainer}>
                                    {
                                        reviews.map(review => {
                                            return (
                                                <MallReview product={product} review={review} key={review.unique_key}/>
                                            );
                                        })
                                    }
                                </div>
                                <div className={styles.paginator} style={{marginTop: 4}}>
                                    <Paginator currentPage={page} totalCount={reviewCount} countPerPage={PAGE_SIZE}
                                               size={5}
                                               onChangeCurrentPage={onPageChange}
                                    />
                                </div>
                            </>
                            :
                            <div className={classNames(styles.emptyText)}>
                                {t('message.emptyReview')}
                            </div>
                    )
            }
        </div>
    );
}

const NoProductReviewSectionContent = (props) => {
    const {product, openReviewModal} = props;
    const router = useRouter();
    const user = useContext(UserContext).user;
    const {t} = useTranslation('components-sections-product-ProductReviewSection')
    const handleWriteFirstReviewButtonClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailReviewSectionWriteFirstReviewButtonClick', {
            productId: product.id,
            productName: product.name,
        });
        if (!user) {
            redirectToLogin(router, true);
            return;
        }
        openReviewModal();
    };

    const totalReviews = product.review_count;

    return (
        <>
            <div className={styles.noReviewContainer}>
                <div className={styles.noReviewText}>
                    {
                        totalReviews === 0 ?
                            <>
                                <Trans
                                    i18nKey={"components-sections-product-ProductReviewSection:message.noReview"}
                                    values={{productName: product.name}}
                                    components={{ sp: <span className={styles.noReviewTextHighlight} />, br: <br />}}
                                />
                            </>
                            :
                            <Trans
                                i18nKey={"components-sections-product-ProductReviewSection:message.noFilteredReview"}
                                components={{ sp: <span className={styles.noReviewFiltered} />, br: <br />}}
                            />
                    }
                </div>
                {
                    totalReviews === 0 &&
                    <div className={styles.writeFirstReviewButton}>
                        <FullButton onClick={handleWriteFirstReviewButtonClick} useDiv>
                            <span className={styles.writeFirstReviewButtonText}>{t('message.firstReview')}</span>
                        </FullButton>
                    </div>
                }
            </div>
        </>
    )
}


const ReviewFilter = (props) => {
    const {product, isPhotoReviewOnly, filterOptions, setFilterOptions, isHemekoReviewerOnly, setIsHemekoReviewerOnly} = props;
    const {t} = useTranslation('components-sections-product-ProductReviewSection');

    const [isOptionModalOpen, setIsOptionModalOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(filterOptions || []);
    const handleOptionModalOpen = () => {
        setIsOptionModalOpen(true);
    }

    const handleClickOption = (option) => {
        setSelectedOptions(val => {
        if (val.map(e => e.id).includes(option.id)) {
            return val.filter(e => e.id !== option.id);
        }
            return [...val, option];
        });
    }

    const handleClickOptionPreviewButton = (option) => {
        setSelectedOptions(val => {
            return val.filter(e => e.id !== option.id);
        });
    }

    const handleSelectAll = () => {
        const validOptions = product.options.filter(e => !!optionIdToCount[e.id] && optionIdToCount[e.id] > 0);
        if (selectedOptions.length === validOptions.length) {
            setSelectedOptions([]);
            return;
        }
        setSelectedOptions(validOptions.map(e => e));
    }

    const handleRefresh = () => {
        setSelectedOptions([]);
    }

    const onSubmit = () => {
        setFilterOptions(selectedOptions);
        setIsOptionModalOpen(false);
    }

    useEffect(() => {
        if (isOptionModalOpen) {
            setSelectedOptions(filterOptions);
        }
    }, [isOptionModalOpen]);

    const [optionIdToCount, setOptionIdToCount] = useState(null);

    const fetchReviewCountPerOption = async () => {
        setOptionIdToCount(null);
        try {
            const res = await Axios.get(
                `v1/store/products/${product.id}/review-filter-options/`,
                {
                    params: {
                        hemeko_reviewer_only: isHemekoReviewerOnly,
                        photo_only: isPhotoReviewOnly,
                    }
                }
            );
            if (res.status < 400) {
                setOptionIdToCount(res.data.option_id_to_count);
            }
        } catch (e) {
            captureException(e);
        }
    }

    useEffect(() => {
        if (!isOptionModalOpen) return;
        fetchReviewCountPerOption();
    }, [isOptionModalOpen]);

    const reviewCountSum = selectedOptions.reduce((acc, cur) => {
        const count = !!optionIdToCount ? (optionIdToCount[cur.id] || 0) : 0;
        return acc + count;
    }, 0);

    const leafOptions = product.leaf_options;

    return (
        <div className={styles.reviewFilterContainer}>
            <div className={classNames(styles.reviewFilterButton, filterOptions.length > 0 ? styles.reviewFilterButtonActive : undefined)} onClick={handleOptionModalOpen}>
                <Modal width={'100%'} bottom isOpen={isOptionModalOpen} backgroundColor={'white'} borderRadius={10} close={() => setIsOptionModalOpen(false)}>
                    <div className={styles.filterModalTitle}>
                        {t('productOption')}
                    </div>
                    <div className={styles.filterModalSubtitle}>
                        <span>{t('option')}</span>
                        <div className={styles.filterModalSelectAll} onClick={handleSelectAll}>{t('selectAll')}</div>
                    </div>
                    {
                        !!optionIdToCount ?
                            <div className={styles.reviewFilterContentContainer}>
                                <div className={styles.reviewOptionListContainer}>
                                    {
                                        !!leafOptions && leafOptions.map(option => {
                                            const isSelected = selectedOptions.map(e => e.id).includes(option.id);
                                            const reviewCount = optionIdToCount[option.id] || 0;

                                            return (
                                                <div key={`filter-${product.id}-${option.id}`} className={styles.filterModalRow} onClick={() => {
                                                    handleClickOption(option);
                                                }}>
                                                    {
                                                        isSelected ?
                                                            <CheckBoxChecked className={styles.modalFilterCheckBox} width={24} height={24} viewBox="0 0 24 24" />
                                                            :
                                                            <CheckBoxUnchecked className={styles.modalFilterCheckBox} width={24} height={24} viewBox="0 0 24 24" />
                                                    }
                                                    {
                                                        product.options.filter(e => !e.color_code).length === 0 &&
                                                        <div className={styles.filterModalColor} style={{backgroundColor: option.color_code}} />
                                                    }
                                                    <span className={styles.filterModalOptionName}>
                                        {option.name}
                                                        {
                                                            !!optionIdToCount &&
                                                            <span className={styles.filterModalOptionCount}>
                                                ({reviewCount})
                                            </span>
                                                        }
                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    selectedOptions.length > 0 &&
                                    <div className={styles.filterOptionsPreview}>
                                        <Swiper
                                            slidesPerView={'auto'}
                                            spaceBetween={8}
                                            slidesOffsetBefore={16}
                                            slidesOffsetAfter={16}
                                            threshold={5}
                                        >
                                            {
                                                selectedOptions.map(option => {
                                                    return (
                                                        <SwiperSlide key={`swiper-${option.id}`} className={styles.optionPreviewSwiperSlide}>
                                                            <div className={styles.optionsPreviewCard}>
                                                                <span>{option.name}</span>
                                                                <CloseIcon viewBox="0 0 17 17" className={styles.optionPreviewCloseIcon} onClick={() => {
                                                                    handleClickOptionPreviewButton(option);
                                                                }} />
                                                            </div>
                                                        </SwiperSlide>
                                                    )
                                                })
                                            }
                                        </Swiper>
                                    </div>
                                }
                            </div>
                            :
                            <div className={classNames(styles.reviewFilterContentContainer, utilStyles.flexRow, utilStyles.justifyContentCenter, utilStyles.alignItemsCenter)}>
                                <LoadingDot width={80} height={80} />
                            </div>
                    }
                    <div className={styles.reviewFilterSubmitContainer}>
                        <div className={styles.reviewFilterSubmit}>
                            <div className={styles.reviewFilterRefreshControl} onClick={() => {
                                handleRefresh();
                            }}>
                                <RefreshIcon width="17" height="18" viewBox="0 0 17 18" />
                                <div className={styles.reviewFilterRefreshText}>{t('clear')}</div>
                            </div>
                            <div className={styles.reviewFilterSubmitButtonWrapper}>
                                <FullButton height={48} fontSize={16} onClick={onSubmit}>
                                    {(selectedOptions.length > 0 ? t('getReviews', {count: reviewCountSum}) : t('viewReviews'))}
                                </FullButton>
                            </div>
                        </div>
                    </div>
                </Modal>
                {
                    filterOptions.length === 0 ?
                        <>
                            <span>{t('option')}</span>
                            <ArrowRightIcon className={styles.reviewFilterOptionArrow} viewBox="0 0 16 16" />
                        </>
                        :
                        `${t('option')} ${filterOptions.length}`
                }
            </div>
            <div className={classNames(styles.reviewFilterButton, isHemekoReviewerOnly ? styles.reviewFilterButtonActive : undefined)} onClick={() => {
                setIsHemekoReviewerOnly(val => !val);
            }}>
                {t('reviewer')}
            </div>
        </div>
    );
}


const ProductReviewSection = forwardRef((props, ref) => {
    const {product, fetchReviewSignal, openReviewModal} = props;
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);

    const [isPhotoReviewOnly, setIsPhotoReviewOnly] = useState(false);
    const [sortOrder, setSortOrder] = useState(KEY_REVIEW_ORDER_UPVOTE);
    const [isLoading, setIsLoading] = useState(false);
    const pageRef = useRef(1);
    const [reviews, setReviews] = useState([]);
    const [reviewCount, setReviewCount] = useState(0);
    const [photoCount, setPhotoCount] = useState(null);
    const [reviewPhotos, setReviewPhotos] = useState([]);
    const [modalImageUrl, setModalImageUrl] = useState(null);

    const [reviewType, setReviewType] = useState(
        product && product.mall_review_count > 0 && product.review_count === 0
            ? KEY_REVIEW_TYPE_MALL
            : KEY_REVIEW_TYPE_HEMEKO
    );

    const [error, setError] = useState(null);
    const rootId = "review";

    const initialized = useRef(false);

    const fetchReview = async () => {
        setIsLoading(true);
        setReviews([]);
        try {
            const res = await Axios.get(`v1/store/products/${product.id}/reviews/`, {
                params: {
                    page: pageRef.current,
                    photo_only: isPhotoReviewOnly,
                    size: PAGE_SIZE,
                    sort_order: sortOrder,
                    hemeko_reviewer_only: isHemekoReviewerOnly,
                    option_ids: filterOptions.map(e => e.id).join(','),
                }
            });
            if (res.status < 400) {
                setReviews(res.data.reviews);
                setReviewCount(res.data.total_count);
                setPhotoCount(res.data.photo_count);
                initialized.current = true;
                reviewScrapContextRef.current.setScrapInfo(res.data.reviews);
            } else {
                captureMessage(JSON.stringify(res.data));
            }
        } catch (e) {
            captureException(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (fetchReviewSignal) fetchReview();
    }, [fetchReviewSignal]);

    const [filterOptions, setFilterOptions] = useState([]);
    const [isHemekoReviewerOnly, setIsHemekoReviewerOnly] = useState(false);

    const fetchReviewAndScroll = () => {
        fetchReview();
        if (initialized.current) {
            if (!!ref.current) {
                ref.current.scrollIntoView();
            }
        }
    }

    useEffect(() => {
        pageRef.current = 1;
        fetchReviewAndScroll();
    }, [isPhotoReviewOnly, sortOrder, isHemekoReviewerOnly, filterOptions]);

    const closeModal = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReviewCardImageModalClose',
            {
                isMallReview: false,
            }
        )
        setModalImageUrl(null);
    };

    const openModal = (img) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ReviewCardImageModalOpen',
            {
                isMallReview: false,
            }
        )
        setModalImageUrl(img);
    };

    const onPhotoReviewOnlyClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailReviewSectionPhotoReviewOnlyClick', {
            productId: product.id,
            productName: product.name,
            photoReviewOnly: !isPhotoReviewOnly,
        });
        pageRef.current = 1;
        setIsPhotoReviewOnly(val => !val);
    };

    const onSortOrderClick = (targetOrder) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailReviewSectionSortOrderClick', {
            productId: product.id,
            productName: product.name,
            targetOrder: targetOrder,
        });
        pageRef.current = 1;
        setSortOrder(targetOrder);
    };

    const onUpvoteClick = (review, isUpvote) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailReviewSectionUpvoteClick', {
            productId: product.id,
            productName: product.name,
            reviewId: review.id,
            upvote: isUpvote,
            sortOrder,
            isPhotoReviewOnly,
        });
    };

    const onPageChange = (page) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailReviewSectionPageChange', {
            productId: product.id,
            productName: product.name,
            page: page,
            sortOrder,
            isPhotoReviewOnly,
        });
        pageRef.current = page;
        fetchReviewAndScroll();
    };
    const {t} = useTranslation('components-sections-product-ProductReviewSection');
    const onSetReviewType = (type) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailReviewSectionSetReviewType', {
            productId: product.id,
            productName: product.name,
            type: type,
        });
        setReviewType(type);
    };

    return (
        <>
            <Modal isOpen={modalImageUrl !== null} maxWidth={'calc(100% - 60px)'} close={closeModal}>
                {
                    modalImageUrl &&
                    <div className={styles.imageModal}>
                        <img src={modalImageUrl} alt={'image'} style={{}}/>
                    </div>
                }
            </Modal>
            <div ref={ref} className={commonStyles.infoSection} id={rootId}>
                <ProductInfoTitle title={t('review')} subTitle={`(${numberWithComma(product.review_count + product.mall_review_count)})`}/>
                {
                    !!product.mall_review_count &&
                    <div className={styles.reviewTypeSelectorContainer}>
                        <div className={classNames(
                            styles.reviewTypeSelector, reviewType === KEY_REVIEW_TYPE_HEMEKO ? styles.reviewTypeSelectorActive : undefined
                        )} onClick={() => onSetReviewType(KEY_REVIEW_TYPE_HEMEKO)}>{t('hemekoReview')}
                        </div>
                        <div className={classNames(
                            styles.reviewTypeSelector, reviewType === KEY_REVIEW_TYPE_MALL ? styles.reviewTypeSelectorActive : undefined
                        )} onClick={() => onSetReviewType(KEY_REVIEW_TYPE_MALL)}>{product.brand.name} {t('review')}
                        </div>
                    </div>
                }
                {
                    reviewType === KEY_REVIEW_TYPE_HEMEKO ?
                        <>
                            {/*{*/}
                            {/*    (!isLoading && reviews.length === 0) ?*/}
                            {/*        <NoProductReviewSectionContent product={product} openReviewModal={openReviewModal}/> :*/}
                            {/*        <>*/}
                                        {
                                            product.review_count > 0 &&
                                            <div className={styles.statisticsContainer}>
                                                <div className={styles.statisticsWrapper}>
                                                    <StarRating rate={product.review_score} brandStar/>
                                                    <ScoreStatistics pid={product.id}/>
                                                </div>
                                            </div>
                                        }

                                        <div className={classNames(styles.filterWrapper, product.review_count === 0 ? styles.filterWrapperEmpty : undefined)}>
                                            <ReviewFilter
                                                isPhotoReviewOnly={isPhotoReviewOnly}
                                                product={product} filterOptions={filterOptions} setFilterOptions={setFilterOptions}
                                                isHemekoReviewerOnly={isHemekoReviewerOnly} setIsHemekoReviewerOnly={setIsHemekoReviewerOnly}
                                            />
                                        </div>
                                        <div
                                            className={classNames(utilStyles.flexRow, utilStyles.justifyContentSpaceBetween, styles.filterBar)}>
                                            <div className={utilStyles.noLineHeight}>
                                                <div className={styles.reviewCheckbox} onClick={onPhotoReviewOnlyClick}>
                                                    {
                                                        isPhotoReviewOnly ?
                                                            <CheckBoxChecked viewBox="0 0 24 24" width={24} height={24}
                                                                             className={utilStyles.verticalMiddle}/>
                                                            :
                                                            <CheckBoxUnchecked viewBox="0 0 24 24" width={24}
                                                                               height={24}
                                                                               className={utilStyles.verticalMiddle}/>
                                                    }
                                                    <span className={styles.photoReview}>{t('photoReviews')}{(photoCount && photoCount > 0) ? ` (${photoCount})` : ''}</span>
                                                </div>
                                            </div>
                                            <div className={styles.sortOrderContainer}>
                                                <span
                                                    className={sortOrder === KEY_REVIEW_ORDER_UPVOTE ? styles.sortOrderSelected : undefined}
                                                    onClick={() => onSortOrderClick(KEY_REVIEW_ORDER_UPVOTE)}
                                                >
                                                    {t('helpful')}
                                                </span>
                                                <PipeIcon style={{verticalAlign: 'middle', margin: '0 2px'}}/>
                                                <span
                                                    className={sortOrder === KEY_REVIEW_ORDER_NEWEST ? styles.sortOrderSelected : undefined}
                                                    onClick={() => onSortOrderClick(KEY_REVIEW_ORDER_NEWEST)}
                                                >
                                                    {t('newest')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className={styles.container}>
                                            {
                                                isLoading ?
                                                    <Loading style={{marginTop: 60, marginBottom: 80}}/>
                                                    :
                                                    reviews.length > 0 ?
                                                        (
                                                            <>
                                                                {
                                                                    reviews.map(e => {
                                                                        return (
                                                                            <ReviewCard
                                                                                isExpanded={true}
                                                                                key={e.id}
                                                                                review={e}
                                                                                openImageDetailModal={openModal}
                                                                                onUpvoteClick={onUpvoteClick}
                                                                            />
                                                                        )
                                                                    })
                                                                }
                                                                <div className={styles.paginator}>
                                                                    <Paginator currentPage={pageRef.current}
                                                                               totalCount={reviewCount}
                                                                               countPerPage={PAGE_SIZE}
                                                                               size={isLarge ? 10 : 5}
                                                                               onChangeCurrentPage={onPageChange}
                                                                    />
                                                                </div>
                                                            </>
                                                        )
                                                        :
                                                        error ?
                                                            <div>
                                                                <ErrorMessage padding={60} titleFontSize={18}
                                                                              descriptionFontSize={15} iconSize={48}
                                                                              titleMargin={20} descriptionMargin={8} />
                                                            </div>
                                                            :
                                                            <NoProductReviewSectionContent product={product} openReviewModal={openReviewModal}/>

                                            }
                                        </div>
                            {/*        </>*/}
                            {/*}*/}
                        </>
                        :
                        <MallReviews product={product} sectionRef={ref}/>
                }
                {/*<div className={styles.photoListContainer}>*/}
                {/*    {*/}
                {/*        reviewPhotos.slice(0, 7).map((e, idx) => {*/}
                {/*            return (*/}
                {/*                <div className={styles.photoListElement} key={e.id}>*/}
                {/*                    <div className={styles.photoWrapper}>*/}
                {/*                        {*/}
                {/*                            reviewPhotos.length > 7 && idx === 6 &&*/}
                {/*                            <div className={styles.seeMoreOverlay}>*/}
                {/*                                <PlusIcon viewBox="0 0 20 20" height={16} width={16} />*/}
                {/*                                <span style={{marginLeft: 2}}>더보기</span>*/}
                {/*                            </div>*/}
                {/*                        }*/}
                {/*                        <Image unoptimized layout="fill" objectFit="cover" src={e.thumbnail_url} />*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            )*/}
                {/*        })*/}
                {/*    }*/}
                {/*</div>*/}

            </div>
        </>
    );
});

export default ProductReviewSection;
