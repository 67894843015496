import styles from "components/layout/titleBar.module.scss";
import ArrowBackIcon from "images/icons/arrow_back.svg";
import utilStyles from "styles/utils.module.scss";
import BlackCloseIcon from "images/icons/close_191919.svg";
import classNames from "classnames";
import TruncateMarkup from "react-truncate-markup";
import Link from "next/link";
import {getHomeRoute, getSearchRoute, searchRoute} from "common/const";
import HomeIcon from "images/icons/home_black.svg";
import SearchIcon from "../../images/icons/search.svg";
import {useState} from "react";


export default function TitleBar(props) {
    const {dark, renderRightButton, renderLeftButton, home, search, noTruncate} = props;
    const [isSearchFocused, setIsSearchFocused] = useState(false);
    const RightComponent = renderRightButton;
    const LeftComponent = renderLeftButton;
    const close = props.close || function () {
    };
    const {fontSize} = props;

    const onHomeIconClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track("TitleBarHomeIconClick");
    };
    const onSearchIconClick = () => {
        setIsSearchFocused(true);
        typeof mixpanel !== 'undefined' && mixpanel.track("TitleBarSearchIconClick");
    };

    return (
        <div className={classNames(styles.titleContainer, dark ? styles.darkContainer : undefined)}>
            <div className={classNames(utilStyles.fullFlex, utilStyles.flexRow, utilStyles.alignItemsCenter)}>
                {
                    renderLeftButton ?
                        <LeftComponent/>
                        :
                        props.isBack &&
                        <ArrowBackIcon style={{visibility: !props.isBack ? 'hidden' : null}}
                                       className={utilStyles.cursorPointer} onClick={close}/>
                }
            </div>
            {
                noTruncate ?
                    <span className={classNames(styles.modalTitle, dark ? styles.darkTitle : undefined)}
                          style={{fontSize: fontSize}}>{props.title || props.children}</span> :
                    <TruncateMarkup lines={1} ellipsis={<span>...</span>}>
                        <span className={classNames(styles.modalTitle, dark ? styles.darkTitle : undefined)}
                              style={{fontSize: fontSize}}>{props.title || props.children}</span>
                    </TruncateMarkup>
            }
            <div
                className={classNames(utilStyles.fullFlex, utilStyles.flexRow, utilStyles.justifyContentEnd, utilStyles.alignItemsCenter)}>
                {
                    renderRightButton
                        ?
                        <RightComponent/>
                        :
                        props.isClose &&
                        <BlackCloseIcon style={{visibility: !props.isClose ? 'hidden' : null}}
                                        className={utilStyles.cursorPointer} onClick={close}/>
                }
                {
                    home &&
                    <Link href={getHomeRoute()}>
                        <a className={styles.element} onClick={onHomeIconClick}>
                            <HomeIcon viewBox="0 0 24 24" className={classNames(styles.icon,)}/>
                        </a>
                    </Link>
                }
                {
                    search &&
                    <Link href={searchRoute}>
                        <a className={styles.element} onClick={onSearchIconClick}>
                            <SearchIcon
                                className={classNames(styles.icon)}/>
                        </a>
                    </Link>
                }
            </div>
        </div>
    )
}
